import React, { useEffect, useState } from "react";
import PriceProductForm from "../../components/forms/products/PriceProductForm";
import DetailsProductForm from "../../components/forms/products/DetailsProductForm";
import TileTypeForm from "../../components/forms/products/TileTypeForm";
import "./styles/AddProduct.css";
import MediaProductForm from "../../components/forms/products/MediaProductForm";
import TileVariationForm from "../../components/forms/products/TileVariationForm";
import DocumentsProductForm from "../../components/forms/products/DocumentsProductForm";
import ColourProductForm from "../../components/forms/products/ColourProductForm";
import InventoryProductForm from "../../components/forms/products/InventoryProductForm";
import FeaturesProductForm from "../../components/forms/products/FeaturesProductForm";
import MenusProductForm from "../../components/forms/products/MenusProductForm";
import SamplesProductForm from "../../components/forms/products/SamplesProductForm";
import ARInfo from "../../components/forms/products/ARInfo";
import ARInfoWallpaper from "../../components/forms/products/ARInfoWallpaper";
import { saveProduct } from "../../helpers/product_utils";
import { useSelector } from "react-redux";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { firestore, getDocument } from "../../firebase";
import ProductIconForm from "../../components/forms/products/ProductIconForm";
import ProductCompany from "../../components/forms/products/ProductCompany";
import ShippingForm from "../../components/forms/products/ShippingForm";
import QRCodeForm from "../../components/forms/products/QRCodeForm";
import CreateProductAccordion from "./createProduct/CreateProductAccordion";
import SwatchAndTestpotForm from "../../components/forms/products/SwatchAndTestpotForm";
import VanitySpecs from "../../components/forms/products/VanitySpecs";
import BasinTypes from "../../components/forms/products/BasinTypes";
import VanityMaterials from "../../components/forms/products/VanityMaterials";
import { toast } from "react-toastify";
import VanityRelatedProducts from "../../components/forms/products/VanityRelatedProducts";
import { doc, getDoc } from "firebase/firestore";
import BasinSpecs from "../../components/forms/products/BasinSpecs";
import BasinMaterials from "../../components/forms/products/BasinMaterials";
import BasinRelatedProducts from "../../components/forms/products/BasinRelatedProducts";
import PaverSpecs from "../../components/forms/products/PaverSpecs";
import PaverVariation from "../../components/forms/products/PaverVariation";

/**
 * A list of all product types and their names
 * 
 * p.s, if you're editing this, you'll also need to update enabledProductTypes in
 * CompanyStepper, createPublicInfo & add it to CompanyEnabledProductsForm
 */
const AVAILABLE_PRODUCTS = [
  {id: "3D", name: "Default 3D"}, 
  {id: "wallpaper", name: "Default Flat (Wallpaper)"}, 
  {id: "tile", name: "Tile"}, 
  {id: "paint", name: "Paint"}, 
  {id: "vanity", name: "Vanity"},
  {id: "basin", name: "Basin"},
  {id: "paver", name: "Paver"},
]

/**
 * a form to be used to save product drafts and submit products for publishing
 * @returns the add product form as a react component
 */
function AddProduct(props) {
  const [formType, setFormType] = useState(props.formType);
  const [initialValues, setInitialValues] = useState({ loading: true });
  const [submitting, setSubmit] = useState(false); // whether the form is being submitted
  const [status, setStatus] = useState({ allSubmitted: false, valid: true }); // whether the form has completed submission and the result
  const [productType, setType] = useState("3D"); // the type of product being added
  const [ initialProductType, setInitialProductType ] = useState("3D"); // The initial type of the product being edited
  const [productId, setProductId] = useState(false); // the id of the product if it has been generated
  const company = useSelector((state) => state.company);
  const [loading, setLoading] = useState(false);
  const [companyColours, setCompanyColours] = useState([]);
  const [selectedCompanyID, setSelectedCompanyID] = useState(null);
  const [enabledProductTypes, setEnabledProductTypes] = useState(null);
  const [initialProductTypeSet, setInitialProductTypeSet] = useState(false);
  const [productTypesLoaded, setProductTypesLoaded] = useState(false);

  const [allFormValues, setFormValues] = useState({
    company: company.id,
    id: productId,
    productType: productType,
  }); // all form values collated together after form submit

  const params = useParams();
  const user = useSelector((state) => state.user);
  const nav = useNavigate()

  // create a temporary object to track form values during submission
  let formValues = {
    company: company.id,
    id: productId,
    productType: productType,
  };
  let formValid = true; // temporary object to track if there are any invalid entries during form submission
  // temporary object to track which components have been submitted
  let componentsSubmitted = {
    ar_info: productType !== "3D",
    ar_wallpaper: productType !== "wallpaper",
    colours: false,
    details: false,
    documents: false,
    features: false,
    inventory: false,
    icon: false,
    media: false,
    menus: false,
    price: false,
    samples: false,
    vanity_specs: productType !== "vanity",
    vanity_related_products: productType !== "vanity",
    basin_types: productType !== "vanity",
    vanity_materials: productType !== "vanity",
    basin_specs: productType !== "basin",
    basin_materials: productType !== "basin",
    basin_related_products: productType !== "basin",
    paver_specs: productType !== "paver",
    paver_variation: productType !== "paver",
    paint_info: productType !== "paint",
    tile_type: productType !== "tile",
    tile_variation: productType !== "tile",
  };

  /**
   * Return a list of all product types available for this company.
   * 
   * The two default product types will always be returned. If enabledProductTypes
   * is null, we'll return everything. We'll also ensure that the currently selected, and 
   * initial product types are returned
   */
  const getEnabledProductTypes = () => {
    var _product_types = AVAILABLE_PRODUCTS.filter((_productType) => {
      // No enabled product types have been set, return everything
      if (!enabledProductTypes) return _productType;

      // 3D & Wallpaper are defaults and should always be shown
      if (_productType.id === "3D" || _productType.id === "wallpaper") return _productType;

      // This is the productType we're editing, or it's the initial, we should return it
      if (_productType.id === productType || _productType.id === initialProductType) {
        return _productType;
      }

      // This product type is enabled, return it
      if (enabledProductTypes.hasOwnProperty(_productType.id) && enabledProductTypes[_productType.id] === true) {
        return _productType;
      }

      // Product type wasn't enabled 
      return;
    })

    return _product_types
  }

  useEffect(() => {
    if (props.formType !== "add" || initialProductTypeSet) return;

  }, [enabledProductTypes])

  useEffect(() => {
    if (props.formType === "add") {
      setInitialValues({});
    } else if (props.formType === "edit") {
      const path = "Products/" + params.productId;

      getDocument(path).then((result) => {
        if (result.success) {
          setType(result.data.productType); //@Action
          setInitialProductType(result.data.productType)
          setInitialValues(result.data);
          setFormValues(result.data);
          setProductId(params.productId);

          if (result.data.company && result.data.company !== "") {
            setSelectedCompanyID(result.data.company);
          }
        } else {
          console.log(result.message);
          setInitialValues({});
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log(productType)
  })

  useEffect(() => {
    if (!company.id) return; // No company, abort

    const docRef = doc(firestore, "Companies/" + company.id + "/info/public");

    getDoc(docRef)
    .then(docSnapshot => {
      // Either the company or public info doesn't exist
      // If we ensure enabledProductTypes is null then all
      // product types will be shown.  
      if (!docSnapshot.exists) {
        setEnabledProductTypes(null);
        console.error("Error: Wasn't able to find the public data for " + company.id + ", all products will be enabled for this user.")
        return;
      }

      const publicData = docSnapshot.data()

      // Enabled products weren't found for this user, probably means they
      // onboarded before this feature was enabled. By setting enabledProductTypes
      // to null we'll show them all product types.
      if (!publicData || !publicData.enabledProductTypes) {
        setEnabledProductTypes(null);
        return
      }

      var _enabledProductTypes = publicData.enabledProductTypes;
      
      // If we're editing a product & the user selects a new product type
      // the existing product type might become unavailable due to not being
      // selected in settings. Fix this by adding the initialProductType to 
      // the list of enabled product types
      if (initialProductType && initialProductType !== '') {
        _enabledProductTypes[initialProductType] = true
      }

      setEnabledProductTypes(_enabledProductTypes);
    })
    .catch(error => {
      setEnabledProductTypes(null);
      console.error("Error: Wasn't able to fetch the public data, all products will be enabled for this user.")
      console.error(error)
    })
  }, [company.id, productType, initialProductType]);

  /**
   * submit the values of an individual form component
   * @param {boolean} success whether the component had any errors on submission
   * @param {string} key the name of the componenet being submitted
   * @param {object} values submitted values
   */
  function submitComponent(success, key, values) {
    componentsSubmitted[key] = true; // record that component has submitted
    formValues[key] = values; // save submitted values
    if (!success) {
      formValid = false;
    }
    checkAllSubmitted(formValid); // check if all components have been submitted
  }

  /**
   * submits form if all components have been submitted
   */
  function checkAllSubmitted(valid) {
    if (Object.values(componentsSubmitted).every((item) => item === true)) {
      formValues.productType = productType; // record product type
      setFormValues(formValues); // save form values in state
      setStatus({ allSubmitted: true, valid: valid }); // set form status as submitted
    }
  }

  /**
   * detects status changes and submits or cancels accordingly
   */
  useEffect(() => {
    // check if all form components have completed submission
    if (status.allSubmitted) {
      setLoading(true);
      // check if form is valid before sending to publish
      if (allFormValues.details.productname !== "") {
        toast.promise(saveProduct(allFormValues, status.valid), {
          pending: "Saving",
          success: "Saved",
          error: "Failed to save product"
        }).then(id => {
          if (id !== undefined) setProductId(id);
          setLoading(false);
          setInitialValues(allFormValues)
          nav("/user/products/edit/" + id)
          setFormType("edit")
        }).catch(err => {
          setLoading(false)
        })
      } else {
        window.alert("A product must have a name to be saved."); // cancel submit if there are invalid values
        setLoading(false);
      }
      // reset status and submitting variables
      setSubmit(false);
      setStatus({ allSubmitted: false, valid: true });
    }
  }, [status, submitting]);

  console.log("jhklshfdkhsafd", initialValues)
  return (
    <PageTemplate navigate={props.navigate}>
      <PageTitle
        navigate={props.navigate}
        text={
          formType === "edit"
            ? initialValues.details
              ? "Edit " + initialValues.details.productname + " "
              : "Edit Product "
            : "Add Product "
        }
        confirmNav={true}
        links={
          user.role === "actuality_admin"
            ? [
                { url: "/", text: "Home | " },
                { url: "/products", text: "Products | " },
                {
                  url: window.location.pathname,
                  text: formType === "edit" ? "Edit Product " : "Add Product ",
                },
              ]
            : [
                { url: "/", text: "Home | " },
                { url: "/user/products", text: "Products | " },
                {
                  url: window.location.pathname,
                  text: formType === "edit" ? "Edit Product " : "Add Product ",
                },
              ]
        }
      />
      <div className="">
        <fieldset disabled={submitting || loading}>
          <div className="save-button-flex">
            <div className="form-header-flex">
              <div className="select-title-flex">
                {/* TODO change the ux here to prevent user from changing product form and losing inputted data */}

                <select
                  name="productType"
                  style={{ display: "block" }}
                  type="name"
                  className="hero-select-style"
                  onChange={(e) => setType(e.target.value)}
                  value={productType}
                >
                  { getEnabledProductTypes().map((_type, key) => {
                    return <option key={key} value={_type.id}>{_type.name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="button-flex" style={{ paddingBottom: "30px" }}>
              <button
                type="submit"
                className="add-company-button-right"
                onClick={() => setSubmit(true)}
              >
                Save
              </button>
              <button
                type="submit"
                className="add-company-button-right"
                onClick={() =>
                  props.navigate(
                    user.role === "actuality_admin"
                      ? "/products"
                      : "/user/products"
                  )
                }
              >
                Cancel
              </button>
              {formType === "edit" && (
                <button
                  className="add-company-button-right"
                  onClick={() => {
                    setFormType("add");
                    nav(user.role == "actuality_admin" ? "/products/add-product" : "/user/products/add-product")
                    
                    if (initialValues.inventory.sku) {
                      let ivCopy = JSON.parse(
                        JSON.stringify({
                          ...initialValues,
                          inventory: { ...initialValues.inventory, sku: "" },
                        })
                      );

                      setInitialValues(ivCopy);
                      setFormValues(ivCopy);
                      setProductId(false);
                      
                    }
                  }}
                >
                  Duplicate
                </button>
              )}
            </div>
          </div>
          {initialValues.loading ? (
            <h5>Loading...</h5>
          ) : (
            <>
              <div className="">
                {/** Product Information Accordion */}
                <CreateProductAccordion
                  title="Product Information"
                  defaultExpanded={true}
                >
                  <div className="form-horizontal-flex">
                    <div className="form-left-flex">
                      {user.role === "actuality_admin" && (
                        <ProductCompany
                          submitting={submitting}
                          setStatus={submitComponent}
                          initialValues={initialValues.company}
                          companies={props.companies}
                          setCompanyID={setSelectedCompanyID}
                        />
                      )}
                      <DetailsProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.details}
                      />
                      <FeaturesProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.features}
                      />
                    </div>

                    <div className="form-right-flex">
                      <InventoryProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.inventory}
                      />
                      <ShippingForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.shipping}
                        company={company}
                      />
                      <QRCodeForm productId={productId} />
                    </div>
                  </div>
                </CreateProductAccordion>
                <CreateProductAccordion title="Menus & Colour Search">
                  <div className="form-horizontal-flex">
                    <div className="form-left-flex">
                      <MenusProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.menus}
                      />
                    </div>

                    <div className="form-right-flex">
                      <ColourProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.colours}
                      />
                    </div>
                  </div>
                </CreateProductAccordion>
                {/** Tile Product Type */}
                {productType === "tile" && (
                  <CreateProductAccordion title="Tile Options">
                    <TileTypeForm
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.tile_type}
                      company={company}
                      editingCompanyID={selectedCompanyID}
                    />

                    <TileVariationForm
                      submitting={submitting}
                      setStatus={submitComponent}
                      filePaths={
                        initialValues.tile_variation
                          ? initialValues.tile_variation.images
                          : null
                      }
                    />
                  </CreateProductAccordion>
                )}
                {/** 3D Product Type */}
                {productType === "3D" && (
                  <CreateProductAccordion title={"3D & Ar Information"}>
                    <ARInfo
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.ar_info}
                      isAdmin={user.role === "actuality_admin"}
                    />
                  </CreateProductAccordion>
                )}
                {/** Wallpaper Product Type */}
                {productType === "wallpaper" && (
                  <CreateProductAccordion title={"Wallpaper & Ar Information"}>
                    <ARInfoWallpaper
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.ar_wallpaper}
                    />
                  </CreateProductAccordion>
                )}
                {/** Paint Product Type */}
                {productType === "paint" && (
                  <CreateProductAccordion title={"Paint Information"}>
                    <SwatchAndTestpotForm
                      companyId={selectedCompanyID || company.id}
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.paint_info}
                      colours={companyColours}
                      isAdmin={user.role === "actuality_admin"}
                    />
                  </CreateProductAccordion>
                )}
                {/* Vanity Product Type */}
                {productType === "vanity" && (
                  <CreateProductAccordion title="Vanity Information">
                    <VanitySpecs
                      submitting={submitting}
                      setStatus={submitComponent}
                      productId={productId}
                      initialValues={initialValues.vanity_specs}
                    />
                    <VanityRelatedProducts
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.vanity_related_products}
                      products={props.products}
                    />
                    <BasinTypes
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.basin_types}
                      company={company}
                    />
                    <VanityMaterials
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.vanity_materials}
                      company={company}
                    />
                  </CreateProductAccordion>
                )}
                {/* Basin Product Type */}
                {productType === "basin" && (
                  <CreateProductAccordion title={"Basin Information"}>
                    <BasinSpecs
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.basin_specs}
                    />
                    <BasinRelatedProducts
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.basin_related_products}
                      products={props.products}
                    />
                    <BasinMaterials
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.basin_materials}
                      company={company}
                    />
                  </CreateProductAccordion>
                )}

                {/* Paver Product Type */}
                {productType === "paver" && (
                  <CreateProductAccordion title={"Paver Information"}>
                    <PaverSpecs
                      company={company}
                      submitting={submitting}
                      setStatus={submitComponent}
                      initialValues={initialValues.paver_specs}
                    />
                    <PaverVariation
                      submitting={submitting}
                      setStatus={submitComponent}
                      filePaths={
                        initialValues.paver_variation
                          ? initialValues.paver_variation.images
                          : null
                      }
                    />
                  </CreateProductAccordion>
                )}

                <CreateProductAccordion title={"Pricing Data"}>
                  <div className="form-horizontal-flex">
                    <div className="form-left-flex">
                      <PriceProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.price}
                        company={company}
                      />
                    </div>
                    <div className="form-right-flex">
                      <SamplesProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.samples}
                        company={company}
                      />
                    </div>
                  </div>
                </CreateProductAccordion>
                <CreateProductAccordion title="Product Media">
                  <div className="form-horizontal-flex">
                    <div className="form-left-flex">
                      <ProductIconForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.icon}
                      />
                      <MediaProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        initialValues={initialValues.media}
                      />
                    </div>
                    <div className="form-right-flex">
                      <DocumentsProductForm
                        submitting={submitting}
                        setStatus={submitComponent}
                        filePaths={initialValues.documents}
                        productID={productId}
                      />
                    </div>
                  </div>
                </CreateProductAccordion>
              </div>
            </>
          )}

          <div className="button-bottom-flex" style={{ paddingTop: "30px" }}>
            <div className="form-header-flex"></div>
            <div className="button-flex">
              <button
                type="submit"
                className="add-company-button-right"
                onClick={() => {
                  setSubmit(true);
                }}
              >
                Save
              </button>
              <button
                type="submit"
                className="add-company-button"
                onClick={() =>
                  props.navigate(
                    user.role === "actuality_admin"
                      ? "/products"
                      : "/user/products"
                  )
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </PageTemplate>
  );
}

export default AddProduct;
