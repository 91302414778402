import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { deleteDocument, firestore } from "../../firebase";
import "./ProductTable.css";
import { TableHead, Tooltip } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import LinkIcon from "@mui/icons-material/Link";
import { DeleteOutlineRounded, UnarchiveRounded } from "@mui/icons-material";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function LeadsTable({ companyId }) {
  const [page, setPage] = React.useState(0);
  const [showArchivedLeads, setShowArchivedLeads] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [leads, setLeads] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedLead, setSelectedLead] = React.useState(false);
  const [lastVisible, setLastVisible] = React.useState(null);
  const [numLeads, setNumLeads] = React.useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leads.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const markAsDone = (id, complete) => {
    const docRef = doc(firestore, "Leads", id);

    updateDoc(docRef, {
      marked_complete: complete,
    }).then(() => {
      loadData();
    });
    setShowModal(false)
  };

  const deleteLead = async (id) => {
    await deleteDocument("/Leads/" + id)
      .then((result) => {
        loadData();
        console.log(result);
      })
      .catch((error) => console.error(error));
      setShowModal(false)
  };

  React.useEffect(() => {
    const leadsRef = collection(firestore, "Leads");
    const q = query(
      leadsRef,
      where("company_id", "==", companyId),
      where("marked_complete", "==", showArchivedLeads)
    );

    getDocs(q).then((snapshot) => {
      setNumLeads(snapshot.size);
    });
  }, [showArchivedLeads, leads]);

  const loadData = () => {
    const leadsRef = collection(firestore, "Leads");
    const q = query(
      leadsRef,
      where("company_id", "==", companyId),
      where("marked_complete", "==", showArchivedLeads),
      orderBy("date", "desc"),
      limit(page * rowsPerPage + rowsPerPage)
    );

    getDocs(q)
      .then((snapshot) => {
        var i = 0;
        setLeads([]);

        setLastVisible(snapshot.docs[-1]);

        snapshot.forEach((doc) => {
          const data = doc.data();

          setLeads((leads) => [
            ...leads,
            {
              id: doc.id,
              message: data.message,
              name: data.name,
              email: data.email,
              date: data.date,
              phone: data.phone,
              product: data.products[0] || null,
              marked_complete: data.marked_complete,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error("Leads table getDocs error - " + err.message);
      });
  };

  React.useEffect(loadData, [page, rowsPerPage, showArchivedLeads]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper className="product-table" sx={{ width: "100%" }}>
          {numLeads ? (
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableCell className="table-subheading-border">
                    Date
                  </TableCell>
                  <TableCell className="table-subheading-border">
                    Name
                  </TableCell>
                  <TableCell className="table-subheading-border">
                    Email
                  </TableCell>
                  <TableCell className="table-subheading-border">
                    Phone
                  </TableCell>
                  <TableCell className="table-subheading-border">
                    Product
                  </TableCell>
                  <TableCell className="table-subheading-border">
                    Message
                  </TableCell>
                  {!showArchivedLeads ? (
                    <TableCell
                      align="right"
                      className="table-subheading-border"
                    >
                      Archive
                    </TableCell>
                  ) : (
                    <>
                      <TableCell
                        align="right"
                        className="table-subheading-border"
                      >
                        Un-Archive
                      </TableCell>
                      <TableCell
                        align="right"
                        className="table-subheading-border"
                      >
                        Delete
                      </TableCell>
                    </>
                  )}
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? leads.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : leads
                  ).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        scope="row"
                        sx={{ color: "#01365B", width: "100px" }}
                      >
                        {new Date(row.date)
                          .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })
                          .replace(/ /g, "-")}
                      </TableCell>
                      <TableCell
                        scope="row"
                        sx={{ color: "#01365B", width: "150px" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        scope="row"
                        sx={{ color: "#01365B", width: "150px" }}
                      >
                        <a href={"mailto:" + row.email}>{row.email}</a>
                      </TableCell>
                      <TableCell
                        scope="row"
                        sx={{ color: "#01365B", width: "130px" }}
                      >
                        <a href={"tel:" + row.phone}>{row.phone}</a>
                      </TableCell>
                      <TableCell
                        scope="row"
                        sx={{ color: "#01365B", width: "50px" }}
                      >
                        {row.product ? (
                          <a
                            href={
                              "https://go.quickview.nz/product/" +
                              row.product
                            }
                            target="_blank"
                          >
                            <LinkIcon />
                          </a>
                        ) : (
                          <a></a>
                        )}
                      </TableCell>
                      <TableCell scope="row" sx={{ color: "#01365B" }}>
                        {row.message}
                      </TableCell>
                      {!row.marked_complete ? (
                        <TableCell
                          align="right"
                          scope="row"
                          sx={{ color: "#01365B" }}
                        >
                          <a
                            style={{ background: "none", border: "none" }}
                            onClick={() => {
                              setShowModal(true);
                              setSelectedLead(row.id);
                            }}
                          >
                            <ArchiveIcon />
                          </a>
                        </TableCell>
                      ) : (
                        <>
                          <TableCell align="right" scope="row">
                            <a style={{ background: "none", border: "none" }}>
                              <Tooltip
                                title="Unarchive"
                                sx={{ color: "#01365B" }}
                              >
                                <IconButton
                                  onClick={() => markAsDone(row.id, false)}
                                >
                                  <UnarchiveRounded />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <a style={{ background: "none", border: "none" }}>
                              <Tooltip title="Delete" sx={{ color: "#01365B" }}>
                                <IconButton
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedLead(row.id);
                                  }}
                                >
                                  <DeleteOutlineRounded />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 10 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={showArchivedLeads ? 8 : 7}
                      count={numLeads}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{
                padding: "30px",
                paddingTop: "70px",
                paddingBottom: "70px",
                color: "#01365B",
              }}
            >
              <h3 style={{ fontSize: "20px" }}>
                You do not have any leads yet
              </h3>
            </div>
          )}
        </Paper>
        <div>
          <a
            style={{ fontSize: "14px", cursor: "pointer" }}
            onClick={() => setShowArchivedLeads(!showArchivedLeads)}
          >
            {showArchivedLeads ? "Hide Archived Leads" : "Show Archived Leads"}
          </a>
        </div>
      </Box>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={showArchivedLeads ? "Delete Product" : "Archive Product"}
        message={
          showArchivedLeads
            ? "Are you sure you would like to delete this lead?"
            : "Are you sure you would like to archive this lead?"
        }
        confirm={
          showArchivedLeads
            ? () => deleteLead(selectedLead)
            : () => markAsDone(selectedLead, true)
        }
      />
    </>
  );
}
