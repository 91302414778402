import React from "react";
import "./SearchBar.css";
import { SearchRounded } from "@mui/icons-material";
import { Input, InputAdornment } from "@mui/material";

function SearchBar(props) {

  function handleSubmit(e) {
    e.preventDefault()
  }
  return (
    <div className="search-container">
      <form onSubmit={handleSubmit} /*action="/database.url"*/>
        <Input
          placeholder={props.text}
          type="search"
          disableUnderline={true}
          className="search-input"
          onChange={props.onChange}
          value={props.value}
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded style={{ fill: "rgba(1, 54, 91, 0.88)" }} />
            </InputAdornment>
          }
        />
      </form>
    </div>
  );
}

export default SearchBar;
