import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { Button, Modal } from "@mui/material";
import "./confirmModal.css";
import { logout } from "../../helpers/auth_utils";
import { useDispatch } from "react-redux";

function DeleteConfirmModal({ oneButton = false, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal open={props.display}>
      <div className="escapeModal">
        <div className="modalHead">
          <div className="modalTitle">{props.title}</div>
          <div className="modalClose">
            <CloseRounded onClick={() => props.closeCallback(false)} />
          </div>
        </div>
        <div className="modalBody">{props.message}</div>
        <div className="modalFoot">
          <div className="modalButtons">
            {!oneButton && (
              <Button
                onClick={() => props.closeCallback(false)}
                className="modalButtonCancel"
                variant="outlined"
              >
                Cancel
              </Button>
            )}
            <Button
              className="modalButtonLeave"
              onClick={props.confirm}
              variant="contained"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteConfirmModal;
