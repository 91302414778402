import { toast } from 'react-toastify';
import { clear_company } from './company/actions';
import { store } from './store'
import { logout } from './user/actions'

export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 

  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
  };
  
  window.addEventListener('storage', e => {
    if(e.key === 'state' && e.newValue && e.oldValue) {
      let oldState = JSON.parse(e.oldValue);
      let newState = JSON.parse(e.newValue);
       if ((newState.user === null) && newState.user !== oldState.user) {
         store.dispatch(logout());
         store.dispatch(clear_company());
         toast.success("You've been logged out")
       }
     }
 
 });