import { useFormik } from "formik";
import React, { useEffect } from "react";
import { formikSubmit, formikValidate } from "../../../helpers/form_utils";

export default function ShippingForm(props) {
  const component_key = "shipping";
  const unit = props.company && props.company.weightSystem ? props.company.weightSystem : "kgs"

  const conditions = {
    // costPerSample : [(value) => {
    //     if (formik.values.allowSamples) {
    //         return notEmpty(value);
    //     } else return true;
    // }],
  };

  useEffect(() => {
    if (props.submitting) {
      formik.handleSubmit();
    }
  }, [props.submitting]);

  const formik = useFormik({
    initialValues: props.initialValues || {
      weight: 0.0,
      requiresShipping: false,
    },

    validate: (values) => {
      return formikValidate(
        values,
        props.submitting,
        component_key,
        props.setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, props.setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Shipping </h6>
            <p className="onboarding-subheading"></p>
            <div className="">
              <div className="">
                <div className="mini-horizontal-flex">
                  <input
                    type="checkbox"
                    name="requiresShipping"
                    className="checkbox"
                    onChange={formik.handleChange}
                    value={formik.values.requiresShipping}
                    onBlur={formik.handleBlur}
                    checked={formik.values.requiresShipping}
                  />
                  <h6 className="midi-heading">Requires Shipping.</h6>
                </div>
              </div>

              <div className="">
                <div className="select-title-flex">
                  <span className="form-mini-title">Weight ({unit})</span>
                  <div className="input-adornment">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      name="weight"
                      style={{width: '200px'}}
                      className={
                        formik.errors.costPerSample
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      onChange={formik.handleChange}
                      value={formik.values.weight}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
