// importing React libraries
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "./firebase";

// importing 'local project' components & pages
import Companies from "./pages/admin/Companies";
import Products from "./pages/admin/Products";
import Analytics from "./pages/admin/Analytics";
import Settings from "./pages/admin/Settings";
import Home from "./pages/admin/Home";
import AddProduct from "./pages/user/AddProduct";
import UserDashboard from "./pages/user/UserDashboard";
import UserSettings from "./pages/user/UserSettings";
import UserProducts from "./pages/user/UserProducts";
import UserTeam from "./pages/user/UserTeam";
import UserLeads from "./pages/user/UserLeads";
import CompanyOnboarding from "./pages/user/CompanyOnboarding";
import ConfirmModal from "./components/modals/NavConfirmModal";

//Importing CSS Style Sheet
import "./App.css";
import ViewProduct from "./pages/admin/ViewProduct";
import SingleCompany from "./pages/admin/SingleCompany";
import UserMaterials from "./pages/user/UserMaterials";
import UserColours from "./pages/user/UserColours";
import { useSelector } from "react-redux";
import UserGroups from "./pages/user/UserGroups";
import EditCompany from "./pages/admin/EditCompany";
import Team from "./pages/admin/Team";
import CompanyUsers from "./pages/admin/Company_Users";

function AdminApp() {
  const company = useSelector((state) => state.company);
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsQuery, setProductQuery] = useState(
    collection(firestore, "Products")
  );
  const resetProductQuery = () => {
    setProductQuery(collection(firestore, "Products"));
  };
  const companiesRef = collection(firestore, "Companies");
  const [team, setTeam] = useState([]);
  const [invitedTeam, setInvitedTeam] = useState([]);
  const [teamQuery, setTeamQuery] = useState(
    query(
      collection(firestore, "Users"),
      where("role", "==", "actuality_admin")
    )
  );
  const [invitedTeamQuery, setInvitedTeamQuery] = useState(
    query(
      collection(firestore, "Pending Users"),
      where("role", "==", "actuality_admin")
    )
  );

  //CONFIRMATION MODAL STUFF
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [modalUrl, setModalUrl] = useState("");

  // Get realtime data
  function getData(collectionRef, setData) {
    return onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setData(items);
      },
      (error) => {
        console.log(collectionRef)
        console.error("Error fetching realtime data for AdminApp - " + error.message)
      }
    );
  }

  useEffect(() => {
    let unsubscribe = getData(companiesRef, setCompanies);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let unsubscribe = getData(productsQuery, setProducts);
    return () => unsubscribe();
  }, [productsQuery]);

  useEffect(() => {
    let unsubscribe = getData(teamQuery, setTeam);
    return () => unsubscribe;
  }, [teamQuery]);

  useEffect(() => {
    let unsubscribe = getData(invitedTeamQuery, setInvitedTeam);
    return () => unsubscribe;
  }, [invitedTeamQuery]);

  //Brings up modal to ask user if they are sure they would like to leave the page
  const confirmNavigation = (url) => {
    setDisplayConfirmModal(true);
    setModalUrl(url);
  };

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            <Home
              companies={companies}
              products={products}
              setQuery={setProductQuery}
              resetQuery={resetProductQuery}
            />
          }
        />
        <Route
          path="/companies"
          element={
            <Companies companies={companies} navigate={confirmNavigation} />
          }
        />
        <Route
          path="/companies/edit/:companyId"
          element={
            <EditCompany companies={companies} navigate={confirmNavigation} />
          }
        />
        <Route
          path="/products"
          element={<Products companies={companies} products={products} />}
        />
        <Route path="/analytics" element={<Analytics />} />
        <Route
          path="/settings"
          element={<Settings navigate={confirmNavigation} />}
        />
        <Route
          path="/team"
          element={
            <Team
              navigate={confirmNavigation}
              teamMembers={team.concat(invitedTeam)}
            />
          }
        />
        <Route
          path="/company/:companyid"
          element={<SingleCompany products={products} companies={companies} />}
        />
        <Route path="/company_users/:companyid" element={<CompanyUsers navigate={confirmNavigation} />} />
        <Route
          path="/user/dashboard"
          element={
            <UserDashboard
              products={products}
              setQuery={setProductQuery}
              resetQuery={resetProductQuery}
            />
          }
        />
        <Route
          path="/user/products"
          element={
            <UserProducts products={products} navigate={confirmNavigation} />
          }
        />
        <Route
          path="/products/add-product"
          element={
            <AddProduct
              products={products}
              companies={companies}
              navigate={confirmNavigation}
              type="company"
              formType="add"
            />
          }
        />
        <Route
          path="/products/view-product"
          element={<ViewProduct navigate={confirmNavigation} />}
        />
        <Route
          path="/user/products/add-product"
          element={
            <AddProduct
              products={products}
              navigate={confirmNavigation}
              type="user"
              formType="add"
            />
          }
        />
        <Route path="/user/leads" element={<UserLeads />} />
        <Route
          path="/user/team"
          element={<UserTeam navigate={confirmNavigation} teamMembers={[]} />}
        />
        <Route
          path="/user/settings"
          element={<UserSettings navigate={confirmNavigation} />}
        />
        <Route
          path="/user/settings/materials"
          element={
            <UserMaterials navigate={confirmNavigation} company={company} />
          }
        />
        <Route
          path="/user/settings/colours"
          element={
            <UserColours navigate={confirmNavigation} company={company} />
          }
        />
        <Route
          path="/user/settings/groups"
          element={
            <UserGroups navigate={confirmNavigation} company={company} />
          }
        />
        <Route
          path="/user/company-onboarding"
          element={<CompanyOnboarding />}
        />
        <Route
          path="/user/products/edit/:productId"
          element={
            <AddProduct
              products={products}
              formType={"edit"}
              navigate={confirmNavigation}
            />
          }
        />
        <Route
          path="/products/view/:productId"
          element={
            <AddProduct
              companies={companies}
              formType={"edit"}
              navigate={confirmNavigation}
              products={products}
            />
          }
        />
        <Route path="/*" element={<Navigate to="/error" />} />
      </Routes>
      <ConfirmModal
        display={displayConfirmModal}
        closeCallback={setDisplayConfirmModal}
        url={modalUrl}
      />
    </React.Fragment>
  );
}

export default AdminApp;
