import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import { firestore } from "../../firebase";
import NewColourForm from "../../components/forms/coloursMaterials/NewColourForm";
import ColoursTable from "../../components/table/ColoursTable";

export default function UserColours(props) {
  const [showAddColour, setShowAddColour] = useState(false);
  const [colours, setColours] = useState([]);
  const [initialVal, setInitialVal] = useState(null);
  const [archived, setArchived] = useState(false);

  const coloursRef = collection(
    firestore,
    "Companies/" + props.company.id + "/colours"
  );

  // Get realtime data
  function getData(collectionRef, setData) {
    return onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setData(items);
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  useEffect(() => {
    let unsubscribe = getData(coloursRef, setColours);
    return () => unsubscribe();
  }, []);

  return (
    <PageTemplate navigate={showAddColour ? props.navigate : null}>
      <div className="quick-buttons">
        <PageTitle
          navigate={showAddColour ? props.navigate : null}
          text="Colours "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/settings", text: "Settings | " },
            { url: "/user/settings/colours", text: "Colours" },
          ]}
        />
        <button
          className="add-company-button"
          onClick={() => setShowAddColour(true)}
        >
          Add New Colour
        </button>
      </div>
      {showAddColour && (
        <NewColourForm
          initialValues={initialVal}
          setInitialValues={setInitialVal}
          company={props.company}
          showElement={setShowAddColour}
        />
      )}
      <ColoursTable
        setValues={setInitialVal}
        colours={colours}
        editColour={setShowAddColour}
        company={props.company}
        archived={archived}
      />
      <div>
        <a style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => setArchived(!archived)}>{archived ? "Hide Archived Colours" : "Show Archived Colours"}</a>
      </div>
    </PageTemplate>
  );
}
