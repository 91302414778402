import { addDocument, setDocument, deleteDocument, firestore } from "../firebase";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";
import { getDoc, doc } from "firebase/firestore";

const sha256 = require('js-sha256')
const storage = getStorage();

export async function saveProduct(values, valid) {
  return new Promise(async (resolve, reject) => {
    values['lastUpdated'] = Math.floor(Date.now() / 1000)

    var hash = sha256.create()
    hash.update(JSON.stringify(values));

    values['hash'] = hash.hex().toString()

    try {
      let id = "placeholder";
      if (!values.id) {
        let result = await addDocument("Products", {
          id: "placeholder",
          company: values.company,
          version: 0
        });
        console.log(result); // TODO put error handling in here
        id = result.docId;
      } else {
        id = values.id;
      }
      
      await createDocument(values, id).then(async (document) => {
        try {
          const productSnapshot = await getDoc(doc(firestore, "Products/" + id))

          if (productSnapshot.exists) {
            const data = productSnapshot.data();
            document.status = data.status || ''
            document.visible = data.visible || false;
          }  
        } catch (err) {
          // Not critical
          console.error(err);
        }

        if (valid) {
          if (document.productType === "3D" && !document.ar_info.approved) {
            document.status = "processing";
            document.visible = false;
          } else if (document.status !== "live") {
            document.status = "live";
            document.visible = true;
          }
        } else {
          document.status = "draft";
          document.visible = false;
        }
        await setDocument("Products/" + id, document, true).then((result) => {
          if (result.success) {
            resolve(id);
          } else {
            resolve(false);
          }
        });
      });
    } catch (error) {
      reject(error);
    }
  });
}

function createDocument(data, id) {
  return new Promise(async (resolve, reject) => {
    try {
      let document = JSON.parse(JSON.stringify(data));
      document.id = id;
      const baseLocation = "products/" + id;
      if (data.productType === "tile") {
        if (data.tile_variation.images) {
          // TODO rework to include error handling / async functionality
          document.tile_variation.images = await uploadFiles(
            baseLocation + "/tile-variation/",
            data.tile_variation.images
          );
        }
      } else if (data.productType === "3D") {
        if (data.ar_info.files3d) {
          // TODO rework to include error handling / async functionality
          document.ar_info.files3d = await uploadFiles(
            baseLocation + "/ar-3d-files/",
            data.ar_info.files3d
          );
        }
        if (data.ar_info.images) {
          // TODO rework to include error handling / async functionality
          document.ar_info.images = await uploadFiles(
            baseLocation + "/ar-images/",
            data.ar_info.images
          );
        }
      } else if (data.productType === "wallpaper") {
        if (data.ar_wallpaper.images) {
          // TODO rework to include error handling / async functionality
          document.ar_wallpaper.images = await uploadFiles(
            baseLocation + "/ar-images/",
            data.ar_wallpaper.images
          );
        }
      } else if (data.productType === "basin") {
        if (data.basin_specs.basinFbx) {
          document.basin_specs.basinFbx = await uploadFiles(
            baseLocation + "/fbx/",
            data.basin_specs.basinFbx
          );
        }
        if (data.basin_specs.basinUsdz) {
          document.basin_specs.basinUsdz = await uploadFiles(
            baseLocation + "/usdz/",
            data.basin_specs.basinUsdz
          );
        }
      } else if (data.productType === "paver") {
        if (data.paver_variation.images) {
          // TODO rework to include error handling / async functionality
          document.paver_variation.images = await uploadFiles(
            baseLocation + "/paver-variation/",
            data.paver_variation.images
          );
        }
      }
      if (data.media.images) {
        // TODO rework to include error handling / async functionality
        document.media.images = await uploadFiles(
          baseLocation + "/media/",
          data.media.images
        );
      }
      if (data.icon.icon) {
        document.icon.icon = await uploadFiles(
          baseLocation + "/icon/",
          data.icon.icon
        );
      }
      if (data.menus.brandLogo) {
        document.menus.brandLogo = await uploadFiles(
          baseLocation + "/brands/",
          data.menus.brandLogo
        );
      }
      resolve(document);
    } catch (error) {
      reject(error);
    }
  });
}

function uploadFiles(location, files) {
  return new Promise((resolve, reject) => {
    try {
      let fileNames = [];
      let filesPromises = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let fileName =
          location +
          "file-" +
          i +
          "." +
          (file.name ? file.name.split(".").pop() : "jpg");

          filesPromises.push(uploadFile(fileName, file))
          fileNames.push(fileName);
      }
      
      Promise.all(filesPromises).then(_ => {
        return resolve(fileNames);
      })
      .catch(err => {
        reject(err)
      })
    } catch (error) {
      reject(error);
    }
  });
}

export function uploadFile(location, file) {
  return new Promise(async (resolve, reject) => {
    try {
      const fileRef = ref(storage, location);
      const uploadResult = await uploadBytes(fileRef, file);
      resolve(location);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteFile(location) {
  return new Promise(async (resolve, reject) => {
    try {
      const fileRef = ref(storage, location);
      await deleteObject(fileRef).then(() => {
        resolve(location);
      });
    } catch {
      reject(location);
    }
  });
}
