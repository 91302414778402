import React from "react";

//Importing Images
import LoginLogo from "../../images/logo/black.svg";
import background from "../../images/outdoor.jpeg";

//Importing CSS
import "../../components/forms/login/LoginForm";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendResetPasswordEmail } from "../../firebase";
// import { sendEmail } from "../../helpers/email_utils";
import { toast } from 'react-toastify'

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const functions = getFunctions();

  async function resetHandler(e) {
    e.preventDefault();

    try {
      sendResetPasswordEmail(email).then((result) => {
        if (result && result.success) {
          navigate("/login");
        } else {
          setEmailError("Mail not sent");
        }
      });
    } catch (error) {
      toast.error("Sorry, we couldn't reset your password. Please contact support.")
      console.error(error.message);
    }
  }

  function handleChange(value) {
    setEmail(value);
  }
  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="login-form">
        <div className="form-container">
          <img src={LoginLogo} alt="" className="white-full-login-logo" />
          <form className="form-elements" onSubmit={resetHandler}>
            <h1 className="form-title"> Forgot Password </h1>
            <div className="form-input-style">
              <div className="short-input-style">
                <div className="short-input-group">
                  <label className="form-label" htmlFor="fullname">
                    Email:
                  </label>
                  <input
                    type="name"
                    name="name"
                    className={emailError ? "error short-input" : "short-input"}
                    onChange={(e) => handleChange(e.target.value)}
                    value={email}
                  />
                  <div className="button-group">
                    <Link to={"/login"}>
                      <button
                        className="link-button"
                        type="button"
                        //   onClick={() => registerToggle(false)}
                      >
                        Back to login
                      </button>
                    </Link>
                    <button
                      className="form-button-create-account"
                      type="submit"
                    >
                      Reset
                    </button>
                    <label className="form-label" style={{color: "red"}}>
                      {emailError}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
