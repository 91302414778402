import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import { formikSubmit, formikValidate } from "../../../helpers/form_utils";
import { getDocument, setDocument, updateDocument } from "../../../firebase";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { arrayUnion } from "firebase/firestore";
import { stylesForSelect } from "../../../styles/select";

export default function BasinTypes({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "basin_types";
  const conditions = {};
  const [basinTypeOptions, setBasinTypeOptions] = useState([
    { label: "Default", value: "Default" },
  ]);
  const unit = props.company.currency || "NZD";
  const formik = useFormik({
    initialValues: initialValues || {
      basinTypes: [],
      basinCentreSelected: false,
      basinRightSelected: false,
      basinLeftSelected: false,
      basinDoubleSelected: false,
      basinUndermountSelected: false,
      basinInsetSelected: false,
      basinCentrePrice: 0,
      basinRightPrice: 0,
      basinLeftPrice: 0,
      basinDoublePrice: 0,
      basinUndermountPrice: 0,
      basinInsetPrice: 0,
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleCreateOption(label, type) {
    const newOption = {
      label: label,
      value: label.toLowerCase().replace(/\W/g, ""),
    };

    setDocument(
      "Companies/" + props.company.id + "/configurations/" + type,
      {
        [type]: arrayUnion(newOption),
      },
      true
    ).then((result) => {
      console.log(result);
      getBasinTypes();
      formik.setFieldValue(
        "basinTypes",
        formik.values.basinTypes.concat([newOption])
      );
    });
  }

  function getBasinTypes() {
    getDocument(
      "Companies/" + props.company.id + "/configurations/basinType"
    ).then((result) => {
      if (result.data) {
        setBasinTypeOptions([
          { label: "Default", value: "Default" },
          ...result.data.basinType,
        ]);
      } else {
        setBasinTypeOptions([{ label: "Default", value: "Default" }]);
      }
    });
  }

  useEffect(() => {
    getBasinTypes();
  }, []);

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  function currencyBlur(value, field) {
    if (!value.toString().includes(".")) {
      formik.setFieldValue(field, value + ".00");
    }
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <div className="select-title-flex">
              <span className="form-mini-title">Basin type</span>
              <CreatableSelect
                isClearable
                styles={stylesForSelect(formik.errors.basinTypes)}
                onChange={(value) => {
                  formik.setFieldValue("basinTypes", value);
                }}
                onCreateOption={(value) => {
                  handleCreateOption(value, "basinType");
                }}
                options={basinTypeOptions}
                value={formik.values.basinTypes}
                isMulti={true}
              />
            </div>
            <div className="mini-horizontal-flex">
              <div style={{ width: "200px" }} className="mini-heading-flex">
                <div className="form-mini-title">Basin Position</div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinCentreSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinCentreSelected}
                    checked={formik.values.basinCentreSelected}
                    onBlur={formik.handleBlur}
                  />
                  <div className="basinIconBackground">
                    <div
                      style={{ marginLeft: "25px" }}
                      className="basinIconInner"
                    />
                  </div>
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Centre
                  </span>
                </div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinRightSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinRightSelected}
                    checked={formik.values.basinRightSelected}
                    onBlur={formik.handleBlur}
                  />
                  <div className="basinIconBackground">
                    <div
                      style={{ marginLeft: "45px" }}
                      className="basinIconInner"
                    />
                  </div>
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Right
                  </span>
                </div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinUndermountSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinUndermountSelected}
                    checked={formik.values.basinUndermountSelected}
                    onBlur={formik.handleBlur}
                  />
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Undermount
                  </span>
                </div>
              </div>

              <div className="mini-heading-flex">
                <div className="form-mini-title">Price</div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinCentrePrice"
                    className={
                      formik.errors.basinCentrePrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinCentrePrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinCentrePrice")
                    }
                    step="0.01"
                  />
                </div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinRightPrice"
                    className={
                      formik.errors.basinRightPrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinRightPrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinRightPrice")
                    }
                    step="0.01"
                  />
                </div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinUndermountPrice"
                    className={
                      formik.errors.basinUndermountPrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinUndermountPrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinUndermountPrice")
                    }
                    step="0.01"
                  />
                </div>
              </div>

              <div
                style={{ paddingLeft: "10px", width: "200px" }}
                className="mini-heading-flex"
              >
                <div
                  style={{ height: "31px" }}
                  className="form-mini-title"
                ></div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinLeftSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinLeftSelected}
                    checked={formik.values.basinLeftSelected}
                    onBlur={formik.handleBlur}
                  />
                  <div className="basinIconBackground">
                    <div
                      style={{ marginLeft: "5px" }}
                      className="basinIconInner"
                    />
                  </div>
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Left
                  </span>
                </div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinDoubleSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinDoubleSelected}
                    checked={formik.values.basinDoubleSelected}
                    onBlur={formik.handleBlur}
                  />
                  <div className="basinIconBackground">
                    <div
                      style={{ marginLeft: "5px" }}
                      className="basinIconInner"
                    />
                    <div
                      style={{ marginLeft: "20px" }}
                      className="basinIconInner"
                    />
                  </div>
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Double
                  </span>
                </div>
                <div style={{ display: "flex", width: "200px" }}>
                  <input
                    type="checkbox"
                    name="basinInsetSelected"
                    className="basinCheckbox"
                    onChange={formik.handleChange}
                    value={formik.values.basinInsetSelected}
                    checked={formik.values.basinInsetSelected}
                    onBlur={formik.handleBlur}
                  />
                  <span
                    style={{ marginTop: "7px", marginLeft: "10px" }}
                    className="onboarding-subheading"
                  >
                    Inset
                  </span>
                </div>
              </div>

              <div className="mini-heading-flex">
                <div className="form-mini-title">Price</div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinLeftPrice"
                    className={
                      formik.errors.basinLeftPrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinLeftPrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinLeftPrice")
                    }
                    step="0.01"
                  />
                </div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinDoublePrice"
                    className={
                      formik.errors.basinDoublePrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinDoublePrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinDoublePrice")
                    }
                    step="0.01"
                  />
                </div>
                <div className="basinPrices">
                  <span className="adornment-text">{unit}$</span>
                  <input
                    type="number"
                    name="basinInsetPrice"
                    className={
                      formik.errors.basinInsetPrice
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    style={{ marginBottom: "7px", width: "150px" }}
                    onChange={formik.handleChange}
                    value={formik.values.basinInsetPrice}
                    onBlur={(value) =>
                      currencyBlur(value.target.value, "basinInsetPrice")
                    }
                    step="0.01"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
