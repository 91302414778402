import React from "react";

//Importing Images
import LoginLogo from "../../images/logo/black.svg";
import background from "../../images/outdoor.jpeg";

//Importing CSS
import "../../components/forms/login/LoginForm";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleResetPassword, sendResetPasswordEmail } from "../../firebase";

export default function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState("");
  // const params = useParams()

  function strengthCheck(myString) {
    return /(?=.*[A-Z])(?=.*[0-9]).{8,}/.test(myString);
  }

  async function resetHandler(e) {
    e.preventDefault();

    if (!strengthCheck(password)) {
      setErrors(
        "Invalid password. Password must be at least 8 characters long, contain a number and a capital letter."
      );
    } else if (password != confirmPassword) {
      setErrors("Passwords do not match");
    } else {
      setErrors("");
      const resetPassword = await handleResetPassword(props.actionCode, password)
      if(resetPassword && resetPassword.result == "success"){
        setMessage("Password successfully reset");
      } else {
        setMessage("")
        setErrors(resetPassword ? resetPassword.message : "Server error")
      }
    }
  }

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="login-form">
        <div className="form-container">
          {/* <img src={LoginLogo} alt="" className="white-full-login-logo" /> */}
          <form className="form-elements" onSubmit={resetHandler}>
            <h1 className="form-title"> Reset Password </h1>
            <div className="form-input-style">
              <div className="short-input-style">
                <div className="short-input-group">
                  <label className="form-label" htmlFor="fullname">
                    Password:
                  </label>
                  <input
                    type="password"
                    name="name"
                    className={errors ? "error short-input" : "short-input"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <label className="form-label" htmlFor="fullname">
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    name="name"
                    className={errors ? "error short-input" : "short-input"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="button-group"
                  >
                    <Link to={"/login"}>
                      <button
                        className="link-button"
                        type="button"
                        //   onClick={() => registerToggle(false)}
                      >
                        To Login
                      </button>
                    </Link>
                    <button
                      className="form-button-create-account"
                      type="submit"
                    >
                      Reset
                    </button>
                    <label className="form-label" style={{ color: "red" }}>
                      {errors}
                    </label>
                    <label className="form-label" style={{ color: "green" }}>
                      {message}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
