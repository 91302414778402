import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { AddToPhotosRounded } from "@mui/icons-material";

// TODO add drag and drop functionality
// TODO add remove file functionality? (check if logo file is required)
// TODO consider whether we want to put some kind of constraint on the size of files being uploaded (may depend on particular file purpose)
function FileUploader({ initialFile, setFile, buttonStyle, iconStyle, showBackground = true }) {
  const styleForButton = {
    width: "8.8rem",
    height: "8.8rem",
    borderRadius: "5px",
    objectFit: 'contain',
    backgroundColor: showBackground ? "#f0f0f0" : ''
  };

  const styleForIcon = {
    width: "7.7rem",
    height: "7.7rem",
    color: "#0C71C38C",
  };

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (initialFile) {
      showPreview(initialFile);
    }
  }, [initialFile]);

  function showPreview(file) {
    if (file.type && file.type.startsWith("image/")) {
      var src = URL.createObjectURL(file);
      setPreview(src);
    } else {
      setPreview(file);
    }
  }

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded && fileUploaded.type.startsWith("image/")) {
      showPreview(fileUploaded);
      setFile(fileUploaded);
    } else {
      console.error("User uploaded invalid file");
      // toast.error("The file you uploaded wasn't valid, please try again.")
      setFile(false);
    }
  };

  return (
    <>
      <IconButton style={buttonStyle || styleForButton} onClick={handleClick}>
        {preview ? (
          <img
            src={preview}
            className="image"
            style={buttonStyle || styleForButton}
            alt="the file uploaded"
          ></img>
        ) : (
          <AddToPhotosRounded style={iconStyle || styleForIcon}></AddToPhotosRounded>
        )}
      </IconButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
}

export default FileUploader;
