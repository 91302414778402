import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
    notEmpty,
    formikSubmit,
    formikValidate,
} from "../../../helpers/form_utils";
import { firestore } from "../../../firebase";
import { getDocs, collection, onSnapshot } from "firebase/firestore";
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { color } from "@mui/system";

function SwatchAndTestpotForm({ submitting, setStatus, initialValues, companyId, isAdmin = false }) {
    const [colours, setColours] = useState([]);
    const [filteredColours, setFilteredColours] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const component_key = "paint_info";

    const getData = () => {
        const coloursRef = collection(firestore, "Companies/" + companyId + "/colours");

        return onSnapshot(
            coloursRef,
            (querySnapshot) => {
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push(doc.data());
                });
                setColours(items);
                setFilteredColours(items);
            },
            (error) => {
                console.log(error.message);
            }
        );
    }

    const conditions = {

    };

    useEffect(() => {
        if (!companyId || companyId === "") return;

        return getData()
    }, [companyId])

    useEffect(() => {
        if (submitting) {
            formik.handleSubmit();
        }
    }, [submitting]);

    const formik = useFormik({
        initialValues: initialValues || {
            swatchUrl: "",
            testpotUrl: "",
            paintColour: ""
        },

        validate: (values) => {
            return formikValidate(
                values,
                submitting,
                component_key,
                setStatus,
                conditions,
                formik
            );
        },

        onSubmit: (values) => {
            formikSubmit(values, formik, setStatus, component_key, conditions);
        },
    });

    function handleSubmit(e) {
        e.preventDefault();
    }

    useEffect(() => {
        if (searchValue === '') {
            setFilteredColours(colours);
            return;
        }

        const q = searchValue.toLowerCase().replace("#", "")

        const filtered = colours.filter(colour => {
            if (colour.hex && colour.hex.toLowerCase().includes(q)) {
                return true;
            }

            if (colour.name && colour.name.toLowerCase().includes(q)) {
                return true;
            }

            if (colour.code && colour.code.toLowerCase().includes(q)) {
                return true;
            }

            return false;
        })
        setFilteredColours(filtered)

    }, [searchValue])

    return (
        <div className="company-onboarding-container form-horizontal-flex">
            <div className="form-left-flex">
                <form onSubmit={handleSubmit}>
                    <div className="form-block">
                        <div className="form-items">
                            <h6 className="section-heading"> Colour </h6>
                            <p className="onboarding-subheading">{isAdmin ? "Make sure you've set up the standard colours for this company first. We'll automatically update this list once they've been added." : "Please ensure you've created your colours first, if you need to do this click 'Edit' below, and we'll update this list automatically when you're done."}</p>

                            <div className="select-title-flex">
                                <div>
                                    <p style={{fontSize: '14px', fontWeight: '600', padding: 0, margin: 0, paddingBottom: '3px'}}>Search your colours</p>
                                    <input type="text" className="form-element-style" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />

                                    {filteredColours.length > 0 ? <>
                                        <p style={{fontSize: '14px', fontWeight: '600', padding: 0, margin: 0, paddingBottom: '3px', marginTop: '10px'}}>Select your colour</p>
                                        <select
                                            name="paintColour"
                                            className="form-element-style"
                                            onChange={formik.handleChange}
                                            value={formik.values.paintColour}
                                            onBlur={formik.handleBlur}
                                            style={{ padding: 0 }}
                                        >
                                            <option value={""}>Select a paint colour</option>
                                            {filteredColours.map((colour, key) => {
                                                return <option key={key} value={colour.id}>{colour.code} - {colour.name}</option>
                                            })}
                                        </select> </> : <p className="onboarding-subheading" style={{ color: "#ff0000" }}>{searchValue === '' ? "We couldn't find any colours for your account." : "We couldn't find any colours for that search query."}</p>}
                                </div>

                                {!isAdmin &&
                                    <>
                                        <h6 className="section-heading" style={{ marginTop: '25px' }}> Edit Colours </h6>
                                        <p style={{ fontSize: '14px' }}>Click the button below to edit your colors, we'll automatically add them back here when you're done. </p>
                                        <div style={{ marginBottom: '10px' }}>
                                            <a href="/user/settings/colours" target={"_blank"} style={{ textDecoration: 'none', color: "#fff", fontSize: '13px', backgroundColor: "#01365B", display: 'inline', paddingLeft: '30px', paddingRight: '30px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '20px' }}>Edit Colours <ModeEditOutlineRoundedIcon /></a>
                                        </div>
                                    </>}

                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="form-right-flex">

                <form onSubmit={handleSubmit}>
                    <div className="form-block">
                        <div className="form-items">
                            <h6 className="section-heading"> Testpot and Swatch Links </h6>
                            <p className="onboarding-subheading"></p>

                            <div className="select-title-flex">
                                <span className="form-mini-title">URL for testpot</span>
                                <input
                                    type="text"
                                    name="testpotUrl"
                                    className="form-element-style"
                                    onChange={formik.handleChange}
                                    value={formik.values.testpotUrl}
                                    onBlur={formik.handleBlur}
                                    placeholder="https://"
                                    pattern="/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)
                                    (?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/"
                                />
                            </div>

                            <div className="select-title-flex">
                                <span className="form-mini-title">URL for swatch</span>
                                <input
                                    type="text"
                                    name="swatchUrl"
                                    className="form-element-style"
                                    onChange={formik.handleChange}
                                    value={formik.values.swatchUrl}
                                    onBlur={formik.handleBlur}
                                    placeholder="https://"
                                    pattern="/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)
                                    (?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SwatchAndTestpotForm;
