import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
  Modal,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  FilterListRounded,
  VisibilityRounded,
  EditRounded,
  Today,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./TeamTable.css";
import Image from "../../images/Sample_User_Icon_grey.png";
import BaseTable from "./BaseTable";
import FirebaseImage from "../media/FirebaseImage";
import TableSearch from "../filters/TableSearch";
import { deleteDocument, setDocument } from "../../firebase";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";

const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

export default function ColoursTable({
  colours,
  editColour,
  setValues,
  company,
  archived,
}) {
  const [search, setSearch] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedColours, setSelectedColours] = React.useState([]);
  const user = useSelector((state) => state.user);

  const cells = [
    {
      id: "icon",
      numeric: false,
      disablePadding: true,
      label: "icon",
      cell: (member) => (
        <TableCell
          key={member.id + "_icon"}
          align="center"
          className="table-cell"
        >
          <div
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "#" + member.hex,
              marginLeft: "calc(50% - 25px)",
              borderRadius: "5px",
            }}
          />
        </TableCell>
      ),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      cell: (member) => (
        <TableCell
          key={member.id + "_name"}
          align="center"
          className="table-cell"
        >
          {member.name || ""}
        </TableCell>
      ),
    },
    {
      id: "code",
      numeric: false,
      disablePadding: true,
      label: "Code",
      cell: (member) => (
        <TableCell
          key={member.id + "_code"}
          align="center"
          className="table-cell"
        >
          {member.code || ""}
        </TableCell>
      ),
    },
    {
      id: "hex",
      numeric: false,
      disablePadding: true,
      label: "HEX",
      cell: (member) => (
        <TableCell
          key={member.id + "_hex"}
          align="center"
          className="table-cell"
        >
          {"#" + member.hex || ""}
        </TableCell>
      ),
    },
    {
      id: "rgb",
      numeric: true,
      disablePadding: false,
      label: "R G B",
      cell: (member) => (
        <TableCell
          key={member.id + "_rgb"}
          align="center"
          className="table-cell"
        >
          {member.R + " " + member.G + " " + member.B}
        </TableCell>
      ),
    },
    {
      id: "date_added",
      numeric: true,
      disablePadding: false,
      label: "Date Added",
      cell: (member) => (
        <TableCell
          key={member.id + "_date"}
          align="center"
          className="table-cell"
        >
          {member.date_added && member.date_added.seconds ? (
            <Tooltip
              title={new Date(
                member.date_added.seconds * 1000
              ).toLocaleString()}
            >
              <Today />
            </Tooltip>
          ) : (
            ""
          )}
        </TableCell>
      ),
    },
    {
      id: "products",
      numeric: true,
      disablePadding: false,
      label: "# Products",
      cell: (member) => (
        <TableCell
          key={member.id + "_products"}
          align="center"
          className="table-cell"
        >
          0
        </TableCell>
      ),
    },

    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (member) => (
        <TableCell
          key={member.email + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            onClick={() => {
              setValues(member);
              editColour(true);
            }}
            style={styleForIconButton}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
  ];

  async function deleteColour() {
    if (["company_admin", "actuality_admin"].includes(user.role)) {
      for (let index = 0; index < selectedColours.length; index++) {
        const colour = selectedColours[index];
        if (archived) {
          await deleteDocument(
            "Companies/" + company.id + "/colours/" + colour
          ).then((result) => {
            console.log(result);
          });
        } else {
          let colourDoc = colours.find((col) => col.id == colour);
          colourDoc.archived = true;
          await setDocument(
            "Companies/" + company.id + "/colours/" + colour,
            colourDoc
          ).then((result) => {
            console.log(result);
          });
        }
      }
      setShowModal(false);
      // setSelectedColours([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  async function unarchiveFunc(colourList) {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < colourList.length; index++) {
        const colour = colourList[index];
        let colourDoc = colours.find((col) => col.id == colour);
        colourDoc.archived = false;
        await setDocument(
          "Companies/" + company.id + "/colours/" + colour,
          colourDoc
        ).then((result) => {
          console.log(result);
        });
      }
      setSelectedColours([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  return (
    <div>
      {/* TABLE DISPLAYS NON-ARCHIVED COLOURS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Colours"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Colour"}
            />
          }
          rows={colours.filter(
            (row) =>
              row.name.toLowerCase().includes(search.toLowerCase()) &&
              !row.archived
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedColours(value);
          }}
          archived={archived}
          selectedItems={selectedColours}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS ARCHIVED COLOURS */}
      <div style={{ display: archived ? "block" : "none" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Colours"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Colour"}
            />
          }
          rows={colours.filter(
            (row) =>
              row.name.toLowerCase().includes(search.toLowerCase()) &&
              row.archived
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedColours(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedColours}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Colour" : "Archive Colour"}
        message={
          archived
            ? "Are you sure you would like to delete this colour?"
            : "Are you sure you would like to archive this colour?"
        }
        confirm={deleteColour}
      />
      {/* <Modal open={showModal} onClose={() => setShowModal(false)} >
        <div>dskajhflkdsahjd</div>
      </Modal> */}
    </div>
  );
}
