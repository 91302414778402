export const set_company = (company) => {
    return {
        type: 'SET_COMPANY',
        payload: company
    };
}

export const clear_company = () => {
    return {
        type: 'CLEAR_COMPANY'
    };
}