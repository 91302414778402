import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import DocumentUploader from '../files/DocumentUploader';

function DocumentsProductForm({ submitting, setStatus, filePaths, productID }) {
  const component_key = "documents";

  const [documents, setDocuments] = useState({
    manual: "",
    warranty: "",
    installation: "",
    returns: "",
  });

  const conditions = {};

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: {
      manual: "",
      warranty: "",
      installation: "",
      returns: "",
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  const updateFile = (fileKey, path) => {
    formik.values[fileKey] = path;
    console.log(formik.values)

  }

  console.log(documents)
  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items" style={{  }}>
            <h6 className="section-heading"> Documents </h6>
            {!productID && <p className="onboarding-subheading">
                Once you've saved your product you'll be able to upload your documents here.
              </p>}

            {productID && <div className="">
              <p className="onboarding-subheading">
                Upload .PDF files to support the display and promotion of your
                product.
              </p>
              
              <DocumentUploader 
                fileID={"manual"} 
                fileName={"Manual"} 
                productID={productID} 
                onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}></DocumentUploader>

              <DocumentUploader 
                fileID={"warranty"} 
                fileName={"Warranty"} 
                productID={productID}
                onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}></DocumentUploader>

              <DocumentUploader 
                fileID={"installation"} 
                fileName={"Installation Guide"} 
                productID={productID} 
                onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}></DocumentUploader>

              <DocumentUploader 
                fileID={"returns"} 
                fileName={"Returns Policy"} 
                productID={productID} 
                onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}></DocumentUploader>
            </div> }
          </div>
        </div>
      </form>
    </div>
  );
}

export default DocumentsProductForm;
