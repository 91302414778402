import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import FilterBar from "../../components/filters/FilterBar";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav from "../../components/topnav/TopNav";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useSelector } from "react-redux";
import UserSidebar from "../sidebar/UserSidebar";
import ErrorBoundary from "../ErrorBoundary";

function PageTemplate(props) {
  const user = useSelector((state) => state.user);
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );
  return (
    <div className="main-container">
      <TopNav navigate={props.navigate} sidebarClass={sidebarClass} setSidebar={setSidebarClass} />
      <div className="home-page">
        <div
          style={sidebarClass == "" ? { width: "130px" } : { width: "0px" }}
          className="sidebar-container"
        >
          {user && user.role === "actuality_admin" ? (
            <Sidebar
              navigate={props.navigate}
              sidebarClass={sidebarClass}
              setSidebar={setSidebarClass}
            />
          ) : (
            <UserSidebar
              navigate={props.navigate}
              sidebarClass={sidebarClass}
              setSidebar={setSidebarClass}
            />
          )}
        </div>
        <div className="app-body">
          <ErrorBoundary>
            {props.children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default PageTemplate;
