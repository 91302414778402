import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import FilterBar from "../../components/filters/FilterBar";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav from "../../components/topnav/TopNav";
import ProductTable from "../../components/table/ProductTable";
import "./styles/Products.css";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";

function Products(props) {
  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text="Products "
          links={[
            { url: "/", text: "Home | " },
            { url: "/products", text: "Products" },
          ]}
        />
        {/* TODO remove this button and fix routing */}
        <Link to={"/products/add-product"}>
          <button className="add-company-button"> Add Product</button>
        </Link>
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      <ProductTable products={props.products} companies={props.companies}  />
      <div className="home-widgets"></div>
    </PageTemplate>
  );
}

export default Products;
