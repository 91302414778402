import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../helpers/auth_utils";
import { useDispatch, useSelector } from "react-redux";
import "./TopNav.css";
import Logo from "../../images/logo/mobile.svg";

// Icon importing
import {
  // NotificationsNone,
  AccountCircleRounded,
  // ExpandCircleDown,
  ExitToAppRounded,
  Menu,
  SearchRounded,
  HelpRounded,
  Close,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import SearchBar from "../filters/SearchBar";
import HamburgerMenu from "../hamburgerMenu/hamburgerMenu";

function TopNav(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [displayHamburgerMenu, setDisplayHamburgerMenu] = useState(false);

  // function to handle user logout
  async function onLogoutClicked() {
    if (props.navigate) {
      props.navigate("/login")
    } else {
      let result = await logout(dispatch);
      window.alert(result.message);
      navigate("/login");
    }
  }

  function clickHandler (url) {
    if(props.navigate){
      props.navigate(url)
    } else {
      navigate(url)
    }
  }


  const styleIconButtons = {
    width: "2.3rem",
    height: "2.3rem",
    color: "#01365B",
  };

  function minimizeSidebar() {
    if (window.innerWidth >= 1024) {
      setDisplayHamburgerMenu(false);
      if (props.sidebarClass === "") {
        props.setSidebar("sidebar-minimize");
      } else {
        props.setSidebar("");
      }
    } else {
      setDisplayHamburgerMenu(!displayHamburgerMenu);
    }
  }

  function clickHandler(url) {
    if (props.navigate) {
      props.navigate(url);
    } else {
      navigate(url);
    }
  }

  return (
    <div className="top-nav">
      <div className="top-nav-wrapper">
        <div className="top-left">
          <div className="nav-hamburger">
            <Tooltip title="Minimize side menu">
              <IconButton
                onClick={() => minimizeSidebar()}
                style={styleIconButtons}
              >
                <Menu
                  sx={window.innerWidth >= 1024 ? {} : { fontSize: 26 }}
                ></Menu>
              </IconButton>
            </Tooltip>
          </div>
          <div className="searchbar-container">
            {/* <SearchBar /> */}
          </div>
        </div>
        <div className="top-middle">
          <div className="logo-container">
            <div onClick={() => clickHandler("/")} className="links">
              <img src={(company && user.role !== 'actuality_admin') ? company.logo || Logo : Logo} alt="" className="mobile-logo"  />
            </div>
          </div>
        </div>
        <div className="top-right">
          <div className="right-icon-container">
          {/* <Tooltip className="account-icon" title="Need help?">
              <IconButton style={styleIconButtons} onClick={() => clickHandler("/user/settings/support")}>
                <HelpRounded></HelpRounded>
              </IconButton>
            </Tooltip> */}
            <Tooltip className="account-icon" title={"You are logged in, " + user.first_name}>
              <IconButton style={styleIconButtons}>
                <AccountCircleRounded></AccountCircleRounded>
              </IconButton>
            </Tooltip>
            <Tooltip className="logout-button" title="Logout">
              <IconButton onClick={onLogoutClicked} style={styleIconButtons}>
                <ExitToAppRounded></ExitToAppRounded>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <HamburgerMenu
        width="100%"
        display={displayHamburgerMenu}
        logoutButton={onLogoutClicked}
        navigate={props.navigate}
      />
    </div>
  );
}
export default TopNav;

/* HIDDEN ICONS:  DO NOT DELETE
<div className="icon-container">    
<IconButton style={styleIconButtons}><NotificationsNone></NotificationsNone></IconButton>
<span className='top-icon-badge'>2</span> 
</div>  
<IconButton style={styleIconButtons}><ExpandCircleDown></ExpandCircleDown></IconButton>*/
