import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import { getDocument, setDocument, updateDocument } from "../../../firebase";
import { arrayUnion } from "firebase/firestore";
import { useSelector } from "react-redux";
import FileUploader from "../files/FileUploader";
import ListFiles from "../files/ListFiles";
import { getBlob, getStorage, ref } from "firebase/storage";
import { stylesForSelect } from "../../../styles/select";

const acceptedFileSizeMB = 20;
const acceptedFileSize = acceptedFileSizeMB * 1024 * 1024;

function MenusProductForm({ submitting, setStatus, initialValues }) {
  const component_key = "menus";
  const [categoryOptions, setCategoryOptions] = useState([
    { label: "Default", value: "Default" },
  ]);
  const [brandOptions, setBrandOptions] = useState([
    { label: "Default", value: "Default" },
  ]);
  const [seriesOptions, setSeriesOptions] = useState([
    { label: "Default", value: "Default" },
  ]);
  const [images, setImages] = useState([]);
  const company = useSelector((state) => state.company);

  const conditions = {
    categories: [notEmpty],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      series: "",
      categories: [],
      brand: "",
      brandLogo: [],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function getCategories() {
    getDocument("Configuration/product-categories").then(
      (actuality_categories) => {
        if (actuality_categories.success) {
          getDocument(
            "Companies/" + company.id + "/configurations/categories"
          ).then((company_categories) => {
            if (
              company_categories.success &&
              company_categories.data.categories
            ) {
              setCategoryOptions(
                actuality_categories.data.Finishes.Tiles.concat(
                  company_categories.data.categories
                )
              );
            } else {
              if (
                company_categories.message ===
                "No data retrieved. Document may not exist."
              ) {
                setDocument(
                  "Companies/" + company.id + "/configurations/categories",
                  {}
                );
              }
              setCategoryOptions(actuality_categories.data.Finishes.Tiles);
              console.log(company_categories.message);
            }
          });
        } else {
          console.log(actuality_categories.message);
        }
      }
    );
  }

  function getOptions(type) {
    // console.log(company.id);
    getDocument("Companies/" + company.id + "/configurations/" + type).then(
      (result) => {
        if (result.success && result.data[type]) {
          if (type === "brands") {
            setBrandOptions(result.data[type]);
          }
          if (type === "series") {
            setSeriesOptions(result.data[type]);
          }
        } else {
          if (result.message === "No data retrieved. Document may not exist.") {
            setDocument(
              "Companies/" + company.id + "/configurations/" + type,
              {}
            );
          }
          console.log(result.message);
        }
      }
    );
  }

  useEffect(() => {
    getCategories();
    getOptions("brands");
    getOptions("series");
    if (
      initialValues &&
      initialValues.brandLogo &&
      initialValues.brandLogo.length > 0
    ) {
      const storage = getStorage();
      let files = [];
      initialValues.brandLogo.map((path, index) => {
        console.log(path);
        const fileRef = ref(storage, path);
        getBlob(fileRef)
          .then((file) => {
            files[index] = file;
            updateFiles("images", files);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
    }
  }, []);

  function handleCreateOption(label, type, name) {
    const newOption = {
      label: label,
      value: label.toLowerCase().replace(/\W/g, ""),
    };
    // TODO add a way for users to remove categories?
    updateDocument("Companies/" + company.id + "/configurations/" + type, {
      [type]: arrayUnion(newOption),
    }).then((result) => {
      if (type == "categories") {
        getCategories();
      } else {
        getOptions(type);
      }
    });
    if (type == "categories") {
      formik.setFieldValue(name, formik.values[name].concat([newOption]));
    } else {
      formik.setFieldValue(name, newOption);
    }
  }

  function uploadImages(files) {
    const file = files.item(0);
    console.log(file);
    if (
      ["image/png", "image/jpeg", "application/zip"].includes(file.type) &&
      file.size <= acceptedFileSize
    ) {
      formik.setFieldValue("brandLogo", [file]);
      setImages([file]);
    } else {
      console.log("file rejected");
    }
  }

  function updateFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    console.log(data);
    setImages([...data]);
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Menus </h6>
            <p className="onboarding-subheading"></p>
            <div className="select-title-flex">
              <span className="form-mini-title">Category *</span>
              <CreatableSelect
                isClearable
                styles={stylesForSelect(formik.errors.categories)}
                onChange={(value) => {
                  formik.setFieldValue("categories", value);
                }}
                onCreateOption={(value) => {
                  handleCreateOption(value, "categories", "categories");
                }}
                options={categoryOptions}
                value={formik.values.categories}
                isMulti={true}
              />
            </div>
            <div className="select-title-flex">
              <span className="form-mini-title">Brand</span>
              <CreatableSelect
                isClearable
                styles={stylesForSelect(formik.errors.brand)}
                onChange={(value) => {
                  formik.setFieldValue("brand", value);
                }}
                onCreateOption={(value) => {
                  handleCreateOption(value, "brands", "brand");
                }}
                options={brandOptions}
                value={formik.values.brand}
              />
            </div>
            <div className="">
              <p className="form-mini-title">
                Upload brand logo (50 x 250px).
              </p>

              <div className="">
                <div
                  className={
                    formik.errors.images
                      ? "error mini-horizontal-flex brandIcon"
                      : "mini-horizontal-flex brandIcon"
                  }
                >
                  <FileUploader setFiles={uploadImages}></FileUploader>
                  <ListFiles
                    files={images}
                    setFiles={(data) => updateFiles("images", data)}
                  ></ListFiles>
                </div>
              </div>
            </div>

            <div className="select-title-flex">
              <span className="form-mini-title">Series</span>
              <CreatableSelect
                isClearable
                styles={stylesForSelect(formik.errors.series)}
                onChange={(value) => {
                  formik.setFieldValue("series", value);
                }}
                onCreateOption={(value) => {
                  handleCreateOption(value, "series", "series");
                }}
                options={seriesOptions}
                value={formik.values.series}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default MenusProductForm;
