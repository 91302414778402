import React from 'react';
import "./FeaturedInfo.css";
import {ArrowDownwardRounded, ArrowUpwardRounded} from '@mui/icons-material';

function FeaturedInfo() {
    return (
        <div className='featured-container' style={{marginBottom: '30px'}}>
            <div className='featured-item'>
            <span className="featured-title"> Revenue < ArrowUpwardRounded className='featured-arrow'></ArrowUpwardRounded></span>
            <div className="featured-money-container">
                <span className="featured-money"> $ 23,858</span>
                <span className="featured-money-rate"> -$1,100 </span>
            </div>
            <span className="featured-subtitle"> Compared to last month</span>
            </div>
            <div className='featured-item'>
            <span className="featured-title"> Sales < ArrowDownwardRounded className='featured-arrow'></ArrowDownwardRounded></span>
            <div className="featured-money-container">
                <span className="featured-money"> $ 23,858</span>
                <span className="featured-money-rate"> -$1,100 </span>
            </div>
            <span className="featured-subtitle"> Compared to last month</span>
            </div>
            <div className='featured-item'>
            <span className="featured-title"> Companies < ArrowDownwardRounded className='featured-arrow'></ArrowDownwardRounded></span>
            <div className="featured-money-container">
                <span className="featured-money"> $ 23,858</span>
                <span className="featured-money-rate"> -$1,100 </span>
            </div>
            <span className="featured-subtitle"> Compared to last month</span>
            </div>
        </div>
    )
}

export default FeaturedInfo;
