// importing React libraries
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

// importing 'local project' components & pages
import AdminApp from "./AdminApp";
import UserApp from "./UserApp";

//Importing CSS Style Sheet
import "./App.css";
import 'react-toastify/dist/ReactToastify.css'

import { useSelector, useDispatch } from "react-redux";
import { set_company } from "./redux/company/actions";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestore, getUserDetails } from "./firebase";
import { ToastContainer } from 'react-toastify'
import { getAuth } from "firebase/auth";
import { set_user } from "./redux/user/actions";
import { HourglassTop } from "@mui/icons-material";

// functions to check user role
function isActualityAdmin(userRole) {
  return userRole === "actuality_admin";
}
function isUser(userRole) {
  return [
    "company_admin",
    "company_user",
    "company_editor",
    "company_sales",
  ].includes(userRole);
}

function App() {
  // get current user
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [uid, setUid] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const authStateListener = getAuth().onAuthStateChanged(_user => {    
    if (_user) {
      setUid(_user.uid)
    } else {
      setUid(null)
    }
  })

  // Get realtime company data
  function getCompany(companyId) {
    const companyRef = doc(firestore, "Companies/" + companyId);

    onSnapshot(
      companyRef,
      (querySnapshot) => {
        dispatch(set_company(querySnapshot.data()));
      },
      (error) => {
        console.error(error)
      }
    );
  }

  useEffect(async () => {
    if (uid && uid !== '') {
      const userResponse = await getUserDetails(uid);

      if (userResponse && userResponse.success) {
        const user = userResponse.data;
        dispatch(set_user(user));
        setLoaded(true);

        if (user.role !== "actuality_admin") {
          getCompany(user.company);
        } else {
          let company = {
            name: "placeholder",
            id: "placeholder",
            onboarded: false,
          };
          dispatch(set_company(company));
        }
      }
      else {
        setLoaded(true);
      }
    } else {
      setLoaded(true)
    }
  }, [loaded, uid]);

  return (
    <>
      <ToastContainer></ToastContainer>
      {loaded ? <>
        {user ? (
          isActualityAdmin(user.role) ? (
            <AdminApp />
          ) : isUser(user.role) ? (
            <UserApp />
          ) : (
            <Navigate to="error" />
          )
        ) : (
          <Navigate to="/login" />
        )}

      </> : <h1 style={{ margin: '0 auto', textAlign: 'center', paddingTop: '50px'}}><HourglassTop sx={{fontSize: '100px'}}/></h1>
      }

    </>
  )
}

export default App;
