import React from "react";
import { div, useNavigate } from "react-router-dom";

import "./Sidebar.css";
import {
  LineStyleRounded,
  StoreRounded,
  ShoppingCartRounded,
  SettingsApplicationsRounded,
  TimelineRounded,
  GroupRounded,
} from "@mui/icons-material";

function Sidebar(props) {
  const navigate = useNavigate()
  window.addEventListener("resize", () => {
    if (window.innerWidth < 1024) {
      props.setSidebar("sidebar-minimize");
    } else {
      props.setSidebar("");
    }
  });

  function clickHandler (url) {
    if(props.navigate){
      props.navigate(url)
    } else {
      navigate(url)
    }
  }
  return (
    <div className={"sidebar " + props.sidebarClass}>
      <div className="sidebar-wrapper">
        <div className="sidebar-menu">
          <ul className="sidebar-list">
            <div onClick={() => clickHandler("/home")} className="links">
              <li className="sidebar-item">
                <LineStyleRounded className="sidebar-icon"></LineStyleRounded>
                Dashboard <div className="sidebar-underline"> </div>
              </li>
            </div>
            <div onClick={() => clickHandler("/companies")} className="links">
              <li className="sidebar-item">
                <StoreRounded className="sidebar-icon"></StoreRounded>
                Companies
              </li>
            </div>
            <div onClick={() => clickHandler("/products")} className="links">
              <li className="sidebar-item">
                <ShoppingCartRounded className="sidebar-icon"></ShoppingCartRounded>
                Products
              </li>
            </div>
            {/* <div onClick={() => clickHandler("/analytics")} className="links">
              <li className="sidebar-item">
                <TimelineRounded className="sidebar-icon"></TimelineRounded>
                Analytics
              </li>
            </div> */}
            <div onClick={() => clickHandler("/team")} className="links">
              <li className="sidebar-item">
                <GroupRounded className="sidebar-icon"></GroupRounded>
                Team
              </li>
            </div>
            <div onClick={() => clickHandler("/settings")} className="links">
              <li className="sidebar-item">
                <SettingsApplicationsRounded className="sidebar-icon"></SettingsApplicationsRounded>
                Settings
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
