import React, { useEffect, useState } from 'react';
import './AddCompanyForm.css';
import { CloseRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { notEmpty, emailFormat, numberFormat, validateItem, checkForm} from '../../../helpers/form_utils';



function AddCompanyForm({onAddCompany, showElement}) {
    // variable to ensure state updates only occur while component is mounted
    let componentMounted = true;

    // setup state variables
    const [addCompany, setAddCompany] = useState(true); // whether the form is visible or not
    const [details, setDetails] = useState({tName: "", fName: "", lName: "", type: "", email: "", phone: ""}); // user entered data
    const [error, setError] = useState({tName: false, fName: false, lName: false, type: false, email: false, phone: false}); // record of any invalid data
    const [loading, setLoading] = useState(false); // whether the function is loading
    const [firebaseError, setFirebaseError] = useState(''); // any errors from firebase

    // conditions each data value must meet to be valid
    const conditions = {
        tName: [notEmpty,],
        fName: [notEmpty,],
        lName: [],
        type: [notEmpty,],
        email: [notEmpty, emailFormat,],
        phone: [numberFormat,],
    }

    // function to save value and check validity whenever form data is changed
    function handleChange(fieldId, value) {
        if (componentMounted) {
            let isValid = validateItem(conditions[fieldId], value); // validate data
            setError({...error, [fieldId]: !isValid}); // update error object
            setDetails({...details, [fieldId]: value}); // update details object
        }
    }

    // function to process and submit data when form is submitted
    function submitHandler(e) {
        e.preventDefault();
        if (componentMounted) {
            setLoading(true);
        }
        // check if form data is valid
        let formValid = checkForm(details, conditions, setError);
        if (formValid) {
            // add new company with given details
            onAddCompany(details).then((result) => {
                if(componentMounted) {
                    if(result.success){
                        setLoading(false);
                        setAddCompany(false); // hide form
                    } else {
                        setLoading(false);
                        setFirebaseError(result.message);
                    }
                }
            });
        } else {
            if(componentMounted) {
                setLoading(false);
            }
        }
    }

    // function to run when component is unmounted
    function unmount() {
        componentMounted = false;
    }
    
    // run cleanup function when component unmounts
    useEffect(() => {
        return () => unmount();
    }, []);

    return (
        <div className='add-company-form-container'> 
            {
                addCompany ?  <form className='form-elements' onSubmit={submitHandler}>
                    <div className="button-title-flex">
                <h5 className='add-company-form-title'> Add New Company</h5>
                <Button type="button" onClick={() => showElement(false)}><CloseRounded></CloseRounded></Button>
                </div>
                <div className="form-input-style">
                    <div className="short-input-style">
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="tradingname"> Trading Name: </label>
                        <input type="name" name="name" className={error.tName ? "error short-input" : "short-input"} onChange={e => handleChange('tName', e.target.value)} value={details.tName} /></div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> First Name: </label>
                        <input type="name" name="name" className={error.fName ? "error short-input" : "short-input"} onChange={e => handleChange('fName', e.target.value)} value={details.fName} /></div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="lastname"> Last Name: </label>
                        <input type="name" name="name" className={error.lName ? "error short-input" : "short-input"} onChange={e => handleChange('lName', e.target.value)} value={details.lName} /></div>
                    </div>
                    <div className="short-input-style">
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="companytype"> Company Type: </label>
                        {/* TODO change select css */}
                        <select name="type" value={details.type} onChange={e => handleChange('type', e.target.value)} style={{ display: 'block' }} type="name" className={error.type ? "error short-input" : "short-input"}>
                            <option className='placeholder-text' value="" label="Select" />
                            <option value="supplier" label="Supplier" />
                            <option value="retailer" label="Retailer" />
                            <option value="showroom" label="Showroom" />
                        </select>
                        </div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="email"> Email: </label>
                        <input type="email" name="email" className={error.email ? "error short-input" : "short-input"} onChange={e => handleChange('email', e.target.value)} value={details.email} /></div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="contactnumber"> Mobile: </label>
                        <input type="tel" name="number" className={error.phone ? "error short-input" : "short-input"} onChange={e => handleChange('phone', e.target.value)} value={details.phone} /></div>
                    </div>
                    <h5>{firebaseError}</h5>
                    <div className="button-flex">
                        <div className="button-div">
                <button className='form-button-submit' disabled={loading} type="submit"> Submit </button></div>
                </div>
            </div>
        </form> : <div className="company-page-else"> <h5 className='great-message'> Great! Company added :) </h5>  
        <Button onClick={() => showElement(false)}><CloseRounded></CloseRounded></Button>
        </div> 
            }
        </div>
    )
}

export default AddCompanyForm
