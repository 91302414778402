import React from "react";

//Importing Images
import LoginLogo from "../../images/logo/black.svg";
import background from "../../images/outdoor.jpeg";

//Importing CSS
import "../../components/forms/login/LoginForm";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { sendResetPasswordEmail } from "../../firebase";

export default function VerifyEmail(props) {
  const navigate = useNavigate();

  function confirmHandler(e) {
    e.preventDefault();
    navigate("/login");
  }

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="login-form">
        <div className="form-container">
          {/* <img src={LoginLogo} alt="" className="white-full-login-logo" /> */}
          <form className="form-elements" onSubmit={confirmHandler}>
            <h1 className="form-title"> Verify Email </h1>
            <div className="form-input-style">
              <div className="short-input-style">
                <div className="short-input-group">
                  <h3>Your email has been verified</h3>
                  <h5>You can now sign in with your account</h5>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="button-group"
                  >
                    <button
                      className="form-button-create-account"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
