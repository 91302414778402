import { useEffect, useRef, useState } from 'react';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const styleForButton = {
    width: '4rem',
    height: '4rem',
    borderRadius: '10px',
    backgroundColor: "#f0f0f0"
};

const DocumentUploader = ({ productID, fileID, fileName, onFileUpdate, extension = '.pdf', path = '/documents/', accepts = 'application/pdf' }) => {
    const firebaseStorageRef = useRef(getStorage());
    const hiddenFileInput = useRef(null);

    const [documentRef, setDocumentRef] = useState(null);
    const [fileExists, setFileExists] = useState(false);
    const [fileDownloadUrl, setFileDownloadUrl] = useState("");
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploading, setUploading] = useState(false);

    /**
     * Handle clicking the upload / delete button
     */
    const handleClick = event => {
        if (fileExists) {
            deleteFile();
        } else {
            hiddenFileInput.current.click();
        }
    };

    /**
     * Update parent with file status
     */
    const updateParent = () => {
        if (!onFileUpdate) return;
        onFileUpdate(fileID, documentRef.fullPath)
    }

    /**
     * Upload a file to Firebase
     * 
     * @param {*} file 
     */
    const doUpload = (file) => {
        setUploading(true);

        toast.promise(uploadBytes(documentRef, file), {
            pending: 'Uploading...',
            success: 'Uploaded ' + fileName,
            error: 'We had an issue uploading ' + fileName
        })
        .then((_) => {
            setUploadSuccess(true);
            setUploading(false);
            checkFileExists()

            // Reset file input
            hiddenFileInput.current.value = ""

            // Update parent object
            updateParent()
        })
        .catch(err => {
            // Errors at this point will have been displayed to user
            setUploading(false);
        });
    }

    /**
     * Delete the current file from Firebase
     */
    const deleteFile = () => {
        if (!documentRef) return;

        setUploading(true);

        toast.promise(deleteObject(documentRef), {
            pending: 'Deleting ' + fileName,
            success: 'Deleted ' + fileName,
            error: 'We had an issue deleting ' + fileName
        }).then(_ => {
            setFileExists(false);
            setFileDownloadUrl("");
            setUploading(false);
            onFileUpdate(fileID, "")
        }).catch(err => {
            console.error(err);
        })
    }

    /**
     * Files have been changed, upload
     */
    const onChange = (evt) => {
        if (evt.target.files.length === 0) return;

        const file = evt.target.files[0];

        if (!file.name.toLowerCase().includes(extension.toLowerCase())) {
            hiddenFileInput.current.value = ""
            toast.error("Documents must be a " + extension)
            console.log("Document Uploader expected a " + accepts + " got a " + file.type)
            return;
        }

        doUpload(file)
    }

    /**
     * Check that the current file exists in Firebase
     */
    const checkFileExists = () => {
        const storageRef = ref(firebaseStorageRef.current, 'products/' + productID + path + fileID + extension);

        console.log(storageRef)

        getDownloadURL(storageRef).then(url => {
            setFileExists(true);
            setFileDownloadUrl(url);
            onFileUpdate(fileID, storageRef.fullPath)
        }).catch(err => {
            console.error(err)
            setFileExists(false);
            setFileDownloadUrl("")
            onFileUpdate(fileID, "")
        })
    }

    /**
     * Update the current document when either the productID, fileID or uploadSuccess changes
     */
    useEffect(() => {
        const storageRef = ref(firebaseStorageRef.current, 'products/' + productID + path + fileID + extension);
        setDocumentRef(storageRef)

        checkFileExists()
    }, [productID, fileID, uploadSuccess])

    return (!productID ? <ProductNotSavedError /> :
        <>
            <div style={{ border: '1px solid #f0f0f0', padding: '10px', display: 'flex', justifyContent: 'space-between', borderRadius: '10px', verticalAlign: 'middle', marginBottom: '10px', width: '100%', marginRight: '10px'}}>
                <div>
                    <span className="form-mini-title" style={{ paddingTop: 0 }}>Upload {fileName} </span>
                    <div style={{paddingRight: '10px'}}>
                        {!fileExists && <p style={{ fontSize: '13px' }}>No {fileName} Uploaded</p>}
                        {fileExists && <a href={fileDownloadUrl} target="_blank" style={{ fontSize: '13px' }}>Download {fileName}</a>}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <IconButton style={styleForButton} onClick={handleClick} disabled={uploading}>
                        {uploading && <HourglassBottomIcon />}
                        {!uploading && fileExists && <DeleteForever />}
                        {!uploading && !fileExists && <CloudUploadIcon />}
                    </IconButton>

                    <input type="file" name="" id="" onChange={e => onChange(e)} style={{ display: 'none' }} ref={hiddenFileInput} />
                </div>
            </div>
        </>
    )
}

const ProductNotSavedError = () => {
    return (
        <p>
            Product Not Saved
        </p>
    )
}

export default DocumentUploader;