import React from "react";
import "./style/CardStyle.css";
import {
  FacebookRounded,
  Instagram,
  LanguageRounded,
  EditRounded,
  LinkedIn,
  Twitter,
  Pinterest,
} from "@mui/icons-material";
import Image from "../../images/logo/mobile.svg";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getDocument } from "../../firebase";
import { Link } from "react-router-dom";

function CompanyProfileCard() {
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [companyInfo, setCompanyInfo] = useState({});
  const styleForButton = {
    width: "2.23rem",
    height: "2.23rem",
    borderRadius: "2.3rem",
  };

  useEffect(() => {
    getDocument("Companies/" + company.id + "/info/public").then(
      (publicResult) => {
        getDocument("Companies/" + company.id + "/info/private").then(
          (privateResult) => {
            setCompanyInfo(
              Object.assign(publicResult.data, privateResult.data)
            );
          }
        );
      }
    );
  }, []);

  function getSocial(network) {
    if (!companyInfo.socialProfiles) return null;

    if (
      network === "instagram" &&
      companyInfo.socialProfiles.instagramUsername &&
      companyInfo.socialProfiles.instagramUsername !== ""
    ) {
      return (
        "https://instagram.com/" + companyInfo.socialProfiles.instagramUsername
      );
    }

    if (
      network === "facebook" &&
      companyInfo.socialProfiles.facebookUsername &&
      companyInfo.socialProfiles.facebookUsername !== ""
    ) {
      return (
        "https://facebook.com/" + companyInfo.socialProfiles.facebookUsername
      );
    }

    if (
      network === "linkedin" &&
      companyInfo.socialProfiles.linkedinUsername &&
      companyInfo.socialProfiles.linkedinUsername !== ""
    ) {
      return (
        "https://www.linkedin.com/in/" +
        companyInfo.socialProfiles.linkedinUsername
      );
    }

    if (
      network === "twitter" &&
      companyInfo.socialProfiles.twitterUsername &&
      companyInfo.socialProfiles.twitterUsername !== ""
    ) {
      return (
        "https://twitter.com/" + companyInfo.socialProfiles.twitterUsername
      );
    }

    if (
      network === "pinterest" &&
      companyInfo.socialProfiles.pinterestUsername &&
      companyInfo.socialProfiles.pinterestUsername !== ""
    ) {
      return (
        "https://www.pinterest.nz/" +
        companyInfo.socialProfiles.pinterestUsername
      );
    }

    return null;
  }

  return (
    <div className="large-card-container">
      <div className="card-container">
        <div className="edit-button-flex">
          <div className="card-logo-container">
            <img
              src={company.logo ? company.logo : Image}
              alt="Logo"
              className="card-image"
            />
          </div>

          <div className="button-container">
            {user.role !== "company_sales" && (
              <Link to="/user/settings">
                <IconButton style={styleForButton}>
                  <EditRounded />
                </IconButton>
              </Link>
            )}
          </div>
        </div>
        <div className="card-group-flex">
          <h6
            className="card-title"
            style={{ color: "#00365A", paddingTop: "20px", margin: 0 }}
          >
            {companyInfo.trading_name}
          </h6>
          <p className="card-body">{companyInfo.bio ? companyInfo.bio : ""}</p>
        </div>

        <div className="card-group-flex">
          <h6 className="card-title">Location</h6>
          <p className="card-body">
            {companyInfo.address && companyInfo.address.street_address
              ? companyInfo.address.street_address + ", "
              : ""}
            {companyInfo.address && companyInfo.address.building
              ? companyInfo.address.building + ", "
              : ""}
            {companyInfo.address && companyInfo.address.city
              ? companyInfo.address.city + ", "
              : ""}
            {companyInfo.address && companyInfo.address.region
              ? companyInfo.address.region + ", "
              : ""}
            New Zealand,
            {companyInfo.address && companyInfo.address.postcode
              ? companyInfo.address.postcode
              : ""}
          </p>
        </div>

        <div className="card-group-flex">
          <h6 className="card-title">Contact</h6>
          <div className="card-mini-horizontal-flex">
            <span className="card-mini-title">Phone:</span>
            <p className="card-mini-body">{companyInfo.supportPhone} </p>
          </div>
          <div className="card-mini-horizontal-flex">
            <span className="card-mini-title">Email:</span>
            <p className="card-mini-body">{companyInfo.supportEmail} </p>
          </div>
          <div className="card-mini-horizontal-flex">
            <span className="card-mini-title">URL:</span>
            <p className="card-mini-body"> {companyInfo.website} </p>
          </div>
        </div>

        <div className="social-icons-flex">
          {getSocial("facebook") ? (
            <a href={getSocial("facebook")} target="_blank" rel="noreferrer">
              <FacebookRounded />
            </a>
          ) : (
            ""
          )}
          {getSocial("instagram") ? (
            <a href={getSocial("instagram")} target="_blank" rel="noreferrer">
              <Instagram />
            </a>
          ) : (
            ""
          )}
          {getSocial("linkedin") ? (
            <a href={getSocial("linkedin")} target="_blank" rel="noreferrer">
              <LinkedIn />
            </a>
          ) : (
            ""
          )}
          {getSocial("pinterest") ? (
            <a href={getSocial("pinterest")} target="_blank" rel="noreferrer">
              <Pinterest />
            </a>
          ) : (
            ""
          )}
          {getSocial("twitter") ? (
            <a href={getSocial("twitter")} target="_blank" rel="noreferrer">
              <Twitter />
            </a>
          ) : (
            ""
          )}
          {companyInfo.website ? (
            <a
              href={
                companyInfo.website.includes("https://")
                  ? companyInfo.website
                  : "https://" + companyInfo.website
              }
              target="_blank"
              rel="noreferrer"
            >
              <LanguageRounded />
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileCard;
