import React from "react";
import InvalidActionCode from "./InvalidActionCode";
import RecoverEmail from "./RecoverEmail";
import recoverEmail from "./RecoverEmail";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

export default function EmailLanding(props) {
  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  // Get the action to complete.
  var mode = getParameterByName("mode");
  // Get the one-time code from the query parameter.
  var actionCode = getParameterByName("oobCode");
  // (Optional) Get the continue URL from the query parameter if available.
  var continueUrl = getParameterByName("continueUrl");
  // Get the firebase projects api key
  var apiKey = getParameterByName("apiKey");

  // Handle the user management action.
  switch (mode) {
    case "resetPassword":
      // Display reset password handler and UI.
      return <ResetPassword actionCode={actionCode} apiKey={apiKey} />;
    case "recoverEmail":
      // Display email recovery handler and UI.
      //   handleRecoverEmail(auth, actionCode, lang);
      return <RecoverEmail />;
    case "verifyEmail":
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} apiKey={apiKey} />;
    default:
      // Error: invalid mode.
      return <InvalidActionCode />;
  }
}
