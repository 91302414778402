import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import FileUploader from "../files/FileUploader";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import ListFiles from "../files/ListFiles";
import { getStorage, ref, getBlob } from "firebase/storage";

const acceptedFileSizeMB = 20;
const acceptedFileSize = acceptedFileSizeMB * 1024 * 1024;
export default function ProductIconForm({
  submitting,
  setStatus,
  initialValues,
}) {
  const component_key = "icon";
  const [images, setImages] = useState([]);

  useEffect(() => {
    
    if (initialValues && initialValues.icon && initialValues.icon.length > 0) {
      const storage = getStorage();
      let files = [];
      initialValues.icon.map((path, index) => {
        const fileRef = ref(storage, path);
        getBlob(fileRef)
          .then((file) => {
            files[index] = file;
            updateFiles("icon", files);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
    }
  }, []);

  const conditions = {
    images: [notEmpty],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: {
      icon: [],
    },
    // initialValues: {
    //   video: initialValues ? initialValues.video : "",
    //   images: [],
    // },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function uploadImages(files) {
    const file = files.item(0);
    console.log(file);
    if (
      ["image/png", "image/jpeg", "application/zip"].includes(file.type) &&
      file.size <= acceptedFileSize
    ) {
      formik.setFieldValue("icon", [file]);
      setImages([file]);
    } else {
      console.log("file rejected");
    }
  }

  function updateFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    setImages([...data]);
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Icon *</h6>
            <p className="onboarding-subheading"></p>

            <div className="form-elements-flex">
              <div className="left-elements">
                <p className="onboarding-subheading">
                  
                  Upload a file that will represent your product. Images should
                  be as detailed as possible (jpg or png) and should be a
                  square.
                </p>
              </div>

              <div className="right-elements">
                <div
                  className={
                    formik.errors.images
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                  <FileUploader setFiles={uploadImages}></FileUploader>
                  <ListFiles
                    files={images}
                    setFiles={(data) => updateFiles("images", data)}
                  ></ListFiles>
                </div>
              </div>
            </div>

            {/* <div className="select-title-flex">
              <span className="form-mini-title">
                Product Video (Vimeo or Youtube URL)
              </span>
              <div className="input-adornment">
                <input
                  type="name"
                  name="https"
                  className="form-element-style"
                  onChange={formik.handleChange}
                  value={formik.values.https}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div> */}
          </div>
        </div>
      </form>
    </div>
  );
}
