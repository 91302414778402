import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./PageTitle.css";
import { CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

function PageTitle(props) {
  let navigate = useNavigate();
  const [displayModal, setDisplayModal] = useState(false);
  const [linkUrl, setLinkUrl] = useState("")

  function clickHandler(url) {
    if(props.navigate){
      props.navigate(url)
    }else {
      navigate(url)
    }
    
  }

  const closeModal = () => {
    setDisplayModal(false)
    let elements = [document.getElementsByClassName("page-title")[0],document.getElementsByClassName("quick-buttons")[0], document.getElementsByClassName("app-body")[0],document.getElementsByClassName("home-page")[0]]
      elements.map(element => {
        element.style.removeProperty("position")
        element.style.removeProperty("z-index")
      })
  }
  return (
    <div className="page-title">
      <h1 className="display-title">
        {props.text}
        <span className="breadcrumbs">
          {props.links.map((link, index) => {
            return (
              <span
                key={index}
                onClick={() => {
                  clickHandler(link.url);
                }}
                className="links breadcrumb-link"
              >
                {link.text}
              </span>
            );
          })}
        </span>
      </h1>
    </div>
  );
}

export default PageTitle;
