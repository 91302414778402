import React, { useState } from "react";
import TeamTable from "../../components/table/TeamTable";
import AddTeamMemberForm from "../../components/forms/team/AddTeamMemberForm";
import {
  getDocument,
  setDocument,
  checkUserExists,
  updateDocument,
} from "../../firebase";
import PageTitle from "../../components/pageTitle/PageTitle";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import { useSelector } from "react-redux";
import { uploadFile } from "../../helpers/product_utils";
import DeleteConfirmModal from "../../components/modals/DeleteConfirmModal";

function UserTeam(props) {
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [showAddTeamMember, setAddTeamMember] = useState(false);
  const [showEditTeamMember, setEditTeamMember] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false)

  // creates a new company in firestore and invites the associated admin user
  async function NewMember(details) {
    if (company.id === "placeholder") {
      return {
        success: false,
        message: "Admins cannot currently add team members.",
      };
    }
    try {
      const userInvitedResult = await getDocument(
        "Pending Users/" + details.email
      );
      if (userInvitedResult.success) {
        return { success: false, message: "User already invited." };
      } else if (
        userInvitedResult.message !==
        "No data retrieved. Document may not exist."
      ) {
        return userInvitedResult;
      }
      if (user.role !== "company_admin" && details.role == "company_admin") {
        return {
          success: false,
          message: "You do not have the permission to create new admins",
        };
      }
      if (user.role == "company_sales" && details.role == "company_editor") {
        return {
          success: false,
          message: "You do not have the permission to create new editors",
        };
      }
      const userExistsResult = await checkUserExists(details.email);
      if (userExistsResult.success) {
        if (userExistsResult.userExists) {
          return { success: false, message: "User already exists." };
        }
      } else {
        return userExistsResult;
      }
      if (details.profile) {
        uploadFile("user_profiles/" + details.email, details.profile);
      }
      // add associated admin user to list of pending users
      const data = {
        first_name: details.first_name,
        last_name: details.last_name,
        email: details.email,
        role: details.role,
        phone: details.phone,
        companyId: company.id,
        companyName: company.trading_name,
        profile: "user_profiles/" + details.email,
        status: "invited",
        id: details.email,
      };
      const inviteUserResult = await setDocument(
        "Pending Users/" + details.email,
        data
      );
      if (inviteUserResult.success) {
        return { success: true, message: "User added." };
      } else {
        return inviteUserResult;
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  async function editMember(member) {
    if (user.role !== "company_admin" && member.role == "company_admin") {
      return {
        success: false,
        message: "You do not have the permission to create new admins",
      };
    }
    if (user.role == "company_sales" && member.role == "company_editor") {
      return {
        success: false,
        message: "You do not have the permission to create new editors",
      };
    }
    const userData = {
      id: member.id,
      company: member.company || member.companyId,
      email: member.email,
      first_name: member.first_name,
      last_name: member.last_name,
      phone: member.phone,
      role: member.role,
      profile: "user_profiles/" + member.email || "",
    };
    const pendingUserData = {
      id: member.email,
      companyId: member.company || member.companyId,
      email: member.email,
      first_name: member.first_name,
      last_name: member.last_name,
      phone: member.phone,
      role: member.role,
      profile: "user_profiles/" + member.email || "",
    };
    if (member.profile && typeof member.profile === "object") {
      uploadFile("user_profiles/" + member.email, member.profile);
    }
    let updateUserResult
    await updateDocument(
      "Users/" + member.id,
      userData
    ).then(result => {
      updateUserResult = result
    }).catch(error => console.error(error));
    let updatePendingUserResult
    await updateDocument(
      "Pending Users/" + member.id,
      pendingUserData
    ).then(result => {
      updatePendingUserResult = result
    }).catch(error => console.error(error));
    if (updateUserResult.success || updatePendingUserResult.success) {
      return { success: true, message: "User added." };
    } else {
      return updateUserResult;
    }
  }

  function showEditMember(member) {
    if(user.role !== "company_admin" && member.role == "company_admin"){
      setShowModal(true)
    }else {
      setMemberToEdit(member);
      setEditTeamMember(true);
    }
    
  }

  return (
    <PageTemplate
      navigate={showAddTeamMember || showEditTeamMember ? props.navigate : null}
    >
      <div className="quick-buttons">
        <PageTitle
          navigate={
            showAddTeamMember || showEditTeamMember ? props.navigate : null
          }
          text="Team "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/team", text: "Team" },
          ]}
        />
        <button
          className="add-company-button"
          onClick={() => setAddTeamMember(true)}
        >
          
          Add Team Member
        </button>
      </div>

      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      {showAddTeamMember ? (
        <AddTeamMemberForm
          onAddTeamMember={NewMember}
          showElement={setAddTeamMember}
        />
      ) : (
        ""
      )}
      {showEditTeamMember ? (
        <AddTeamMemberForm
          onAddTeamMember={editMember}
          showElement={setEditTeamMember}
          member={memberToEdit}
        />
      ) : (
        ""
      )}
      <TeamTable teamMembers={props.teamMembers} editMember={showEditMember} />

      <div className="home-widgets"></div> 
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        oneButton={true}
        title={"You do not have permission to edit admins"}
        message={"Company Sales and Company Editor members cannot edit Company Admins"
        }
        confirm={() => setShowModal(false)}
      />
    </PageTemplate>
  );
}

export default UserTeam;
