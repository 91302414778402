import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useFormik } from "formik";
import CompanyOnboardingForm from "../forms/companies/CompanyOnboardingForm";
import CompanyBrandingForm from "../forms/companies/CompanyBrandingForm";
import CompanyColoursForm from "../forms/companies/CompanyColoursForm";
import Logo from "../../images/logo/mobile.svg";
import { useSelector, useDispatch } from "react-redux";
import { onboardCompany } from "../../helpers/company_onboard_utils";
import { set_company } from "../../redux/company/actions";
import { useNavigate } from "react-router-dom";
import {
  notEmpty,
  emailFormat,
  numberFormat,
  withMinLength,
  withMaxLength,
  checkForm,
} from "../../helpers/form_utils";
import { getDocument } from "../../firebase";
import { useEffect } from "react";
import CreateProductAccordion from "../../pages/user/createProduct/CreateProductAccordion";
import CompanyAppForm from "../forms/companies/CompanyAppForm";
import CompanySocialMarketingBrandingForm from "../forms/companies/CompanySocialMarketingBrandingForm";
import TaxAndContactForm from "../forms/companies/TaxAndContactForm";
import CompanyEnabledProductsForm from "../forms/companies/CompanyEnabledProductsForm";

function getSteps() {
  return [""];
}

const CompanyStepper = (props) => {
  const originalCompany = useSelector((state) => state.company);
  const [company, setCompany] = useState(props.company ? props.company : originalCompany)
  const [saving, setSaving] = useState(false)

  const onboarding_conditions = {
    tradingname: [notEmpty],
    city: [notEmpty],
    unitSystem: [notEmpty],
    weightSystem: [notEmpty],
    postcode: [
      notEmpty,
      numberFormat,
      (value) => {
        return withMinLength(value, 4);
      },
      (value) => {
        return withMaxLength(value, 4);
      },
    ],
  };

  const branding_conditions = {
    supportEmail: [notEmpty, emailFormat],
    leadsEmail: [notEmpty, emailFormat],
    supportPhone: [notEmpty, numberFormat],
    profile: [notEmpty],
    website: [notEmpty],
  };

  const colour_conditions = {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  let initialValues = {
    tradingname: company.trading_name || "",
    address: "",
    building: "",
    timeZone: "12 Auckland, Wellington",
    unitSystem: "",
    weightSystem: "",
    currency: "NZD",
    nzbn: "",
    gst: "",
    orderPrefix: "",
    supportEmail: "",
    supportPhone: "",
    leadsEmail: "",
    logo: company.logo || false,
    banner: company.banner || false,
    profile: "",
    bio: "",
    website: "",
    social: "",
    social2: "",
    colour1: "#FFFFFF",
    colour2: "#FFFFFF",
    colour3: "#FFFFFF",
    iosAppEnabled: false,
    iosBundleID: "",
    iosAppName: "",
    androidAppEnabled: false,
    androidBundleId: "",
    androidAppName: "",
    socialProfiles: {
      twitterUsername: "",
      instagramUsername: "",
      facebookUsername: "",
      linkedinUsername: "",
      pinterestUsername: ""
    },
    enabledProductTypes: {
      tile: true,
      paint: true,
      vanity: true,
      basin: true,
      paver: true,
    },
  };

  const [dataGot, setDataGot] = useState(false);

  useEffect(() => {
    getDocument("Companies/" + company.id + "/info/public")
      .then((publicResult) => {
        if (publicResult.data.address.street_address)
          initialValues.address = publicResult.data.address.street_address;
        if (publicResult.data.address.building)
          initialValues.building = publicResult.data.address.building;
        if (publicResult.data.bio) initialValues.bio = publicResult.data.bio;
        if (publicResult.data.colour1)
          initialValues.colour1 = publicResult.data.colour1;
        if (publicResult.data.colour2)
          initialValues.colour2 = publicResult.data.colour2;
        if (publicResult.data.colour3)
          initialValues.colour3 = publicResult.data.colour3;
        if (publicResult.data.profile)
          initialValues.profile = publicResult.data.profile;
        if (publicResult.data.social1)
          initialValues.social = publicResult.data.social1;
        if (publicResult.data.social2)
          initialValues.social2 = publicResult.data.social2;
        if (publicResult.data.website)
          initialValues.website = publicResult.data.website;
        if (publicResult.data.weightSystem)
          initialValues.weightSystem = publicResult.data.weightSystem;
        if (publicResult.data.unitSystem)
          initialValues.unitSystem = publicResult.data.unitSystem;
        if (publicResult.data.currency)
          initialValues.currency = publicResult.data.currency;
        if (publicResult.data.timeZone)
          initialValues.timeZone = publicResult.data.timeZone;
        if (publicResult.data.iosAppEnabled)
          initialValues.iosAppEnabled = publicResult.data.iosAppEnabled;
        if (publicResult.data.iosAppName)
          initialValues.iosAppName = publicResult.data.iosAppName;
        if (publicResult.data.iosBundleID)
          initialValues.iosBundleID = publicResult.data.iosBundleID;
        if (publicResult.data.androidAppEnabled)
          initialValues.androidAppEnabled = publicResult.data.androidAppEnabled;
        if (publicResult.data.androidAppName)
          initialValues.androidAppName = publicResult.data.androidAppName;
        if (publicResult.data.androidBundleId)
          initialValues.androidBundleId = publicResult.data.androidBundleId;

        if (publicResult.data.enabledProductTypes)
          initialValues.enabledProductTypes =
            publicResult.data.enabledProductTypes;
        if (publicResult.data.socialProfiles)
          initialValues.socialProfiles = publicResult.data.socialProfiles;

        getDocument("Companies/" + company.id + "/info/private")
          .then((privateResult) => {
            if (privateResult.data.gst)
              initialValues.gst = privateResult.data.gst;
            if (privateResult.data.leadsEmail)
              initialValues.leadsEmail = privateResult.data.leadsEmail;
            if (privateResult.data.nzbn)
              initialValues.nzbn = privateResult.data.nzbn;
            if (privateResult.data.orderPrefix)
              initialValues.orderPrefix = privateResult.data.orderPrefix;
            if (privateResult.data.supportEmail)
              initialValues.supportEmail = privateResult.data.supportEmail;
            if (privateResult.data.supportPhone)
              initialValues.supportPhone = privateResult.data.supportPhone;

            setDataGot(true);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formik = useFormik({
    initialValues: initialValues,

    validate: (values) => {
      const result = validate(values);
      if (result) {
        return result;
      } else {
        return {};
      }
    },

    onSubmit: (values) => {
      setSaving(true);

      const result = validate(values);
      if (result) {
        formik.setErrors(validate(values));
        // setSaving(false);
      } else {
        if (activeStep === steps.length - 1) {
          if (company.id !== "placeholder") {
            waitForOnboarding();
            onboardCompany(formik.values, company.id, completeOnboarding).finally();
          } else {
            console.log("admin user. nothing updated");
            completeOnboarding({ success: true });
          }
        } else {
          setActiveStep(activeStep + 1);
          formik.setTouched({});
        }
      }
    },
  });

  function validate(values) {
    let errors = {};
    if (
      checkForm(
        values,
        getStepConditions(activeStep, formik.touched),
        (result) => {
          errors = result;
        }
      )
    ) {
      return false;
    }
    return errors;
  }

  function waitForOnboarding() {
    setLoading(true);
    console.log("onboarding company...");
  }

  function completeOnboarding(result) {
    if (result.success) {
      console.log("Company onboarded!");
      setActiveStep(activeStep + 1);
      setLoading(false);
    } else {
      // TODO display errors to user
      window.alert(result.message);
      setLoading(false);
    }
  }

  function getStepConditions(step, touchedValues) {
    let toCheck = {};
    switch (step) {
      case 0:
        for (const key of Object.keys(touchedValues)) {
          toCheck[key] = onboarding_conditions[key] || [];
        }
        return toCheck;
      case 1:
        for (const key of Object.keys(touchedValues)) {
          toCheck[key] = branding_conditions[key] || [];
        }
        return toCheck;
      case 2:
        for (const key of Object.keys(touchedValues)) {
          toCheck[key] = colour_conditions[key] || [];
        }
        return toCheck;
      default:
        return "unknown step";
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function completeForm() {
    dispatch(set_company({ ...company, onboarded: true }));
    navigate("/");
  }

  return (
    <div className="">
      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          <div className="thanks-flex">
            <img src={Logo} alt="" className="business-logo" />
            Thank You! Your profile has been saved.
            <button
              variant="contained"
              color="primary"
              onClick={completeForm}
              style={{
                backgroundColor: "#00365A",
                color: "white",
                border: "none",
                borderRadius: "20px",
                fontSize: "20px",
                padding: "10px 50px",
                fontWeight: "bold",
              }}
            >
              Back to dashboard
            </button>
          </div>
        </Typography>
      ) : (
        <>
          {/* <FormProvider {...methods}> */}
          <form onSubmit={formik.handleSubmit}>
            <fieldset disabled={loading}>
              <div>
                <CreateProductAccordion
                  title={"Business Details"}
                  defaultExpanded={true}
                >
                  <CompanyOnboardingForm formik={formik} />
                </CreateProductAccordion>

                <CreateProductAccordion
                  title={"Branding, Marketing & Social Media"}
                >
                  <CompanySocialMarketingBrandingForm formik={formik} />
                </CreateProductAccordion>

                <CreateProductAccordion title={"Tax & Contact Information"}>
                  <TaxAndContactForm formik={formik} />
                </CreateProductAccordion>

                <CreateProductAccordion title={"Enabled Products"}>
                  <CompanyEnabledProductsForm formik={formik} />
                </CreateProductAccordion>

                {/* Commented out as we've decided to only enable for admins later
                <CreateProductAccordion title={"Mobile Apps"}>
                  <CompanyAppForm formik={formik} />
                </CreateProductAccordion> */}
              </div>
              <div className="stepper-button-flex">
                <div></div>
                <button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={saving}
                  style={{
                    backgroundColor: "#00365A",
                    color: "white",
                    border: "none",
                    borderRadius: "20px",
                    fontSize: "20px",
                    padding: "10px 50px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  {saving ? "Saving..." : "Save"}
                </button>
              </div>
            </fieldset>
          </form>
          {/* </FormProvider>  */}
        </>
      )}
    </div>
  );
};

export default CompanyStepper;
