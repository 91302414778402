import React, { useState } from 'react';
import TopNav from '../components/topnav/TopNav';
import UserSidebar from '../components/sidebar/UserSidebar';



function SetupError() {
    const [sidebarClass, setSidebarClass] = useState(window.innerWidth > 1023 ? "" : "sidebar-minimize");
    return (
        <div className="main-container">
        <TopNav sidebarClass={sidebarClass} setSidebar={setSidebarClass} />
   <div className='home-page'>
       <div style={sidebarClass == "" ? {width: "130px"} : {width: "0px"}} className="sidebar-container"> <UserSidebar sidebarClass={sidebarClass} setSidebar={setSidebarClass} />
      </div>
       <div className="app-body">
           <div className="quick-buttons">
           <h1 className='display-title'>Error</h1></div>
       <div className="home-widgets">
           <h5>The company you are trying to access has not been set up, and you don't have permission to set it up.</h5>
       </div>
       </div>
   </div>
   </div>
    )
}

export default SetupError
