import { createStore } from 'redux'
import { loadState, saveState } from './localStorage'
import reducer from './index'
import throttle from 'lodash/throttle';

const persistedState = loadState();
export const store = createStore(
  reducer,
  persistedState
);

store.subscribe(throttle(() => {
    saveState({
      user: store.getState().user,
      company: store.getState().company
    });
  }, 1000));