import * as React from "react";
import { TableCell, Tooltip } from "@mui/material";
import {
  VisibilityRounded,
  ContentCopyRounded,
  AndroidRounded,
  Apple,
  FactCheck,
  Key,
  Paid,
  SendRounded,
  ShoppingBasketRounded,
  UploadFile,
  Visibility,
  WarningRounded,
} from "@mui/icons-material";
import "./OnboardingTable.css";
import BaseTable from "./BaseTable";
import Image from "../../images/logo/mobile.svg";
import TableSearch from "../filters/TableSearch";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { deleteDocument, setDocument } from "../../firebase";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "5px",
  objectFit: "contain"
};

const cells = [
  {
    id: "logo",
    numeric: false,
    disablePadding: true,
    label: "Company",
    cell: (company) => (
      <TableCell
        key={company.id + "_logo"}
        align="center"
        className="table-cell"
      >
        <Tooltip title={company.trading_name}>
          <img
            src={company.logo || Image}
            alt={company.trading_name}
            style={styleForImg}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "access",
    numeric: false,
    disablePadding: true,
    label: "Access",
    cell: (company) => (
      <TableCell
        key={company.id + "_access"}
        align="center"
        className="table-cell"
      >
        <Tooltip title="Access accepted">
          <Key
            className={company.num_users ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "profile",
    numeric: true,
    disablePadding: false,
    label: "Profile",
    cell: (company) => (
      <TableCell
        key={company.id + "_profile"}
        align="center"
        className="table-cell"
      >
        <Tooltip title="Profile set up">
          <FactCheck
            className={company.onboarded ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "num_products",
    numeric: true,
    disablePadding: false,
    label: "Products",
    cell: (company) => (
      <TableCell
        key={company.id + "_products"}
        className="table-cell"
        component="th"
        id={company.products}
        scope="row"
        padding="none"
        align="center"
      >
        <Tooltip title="Products added">
          <UploadFile
            className={company.num_products ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "ios_ready",
    numeric: true,
    disablePadding: false,
    label: "iOS",
    cell: (company) => (
      <TableCell
        key={company.id + "_ios"}
        align="center"
        className="table-cell"
      >
        <Tooltip title="iOS app info supplied">
          <Apple
            className={company.ios_ready ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "android_ready",
    numeric: true,
    disablePadding: false,
    label: "Android",
    cell: (company) => (
      <TableCell
        key={company.id + "_android"}
        align="center"
        className="table-cell"
      >
        <Tooltip title="Android app info supplied">
          <AndroidRounded
            className={company.android_ready ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "analytics.totalLeadsGenerated",
    numeric: true,
    disablePadding: false,
    label: "Leads",
    cell: (company) => (
      <TableCell
        key={company.id + "_leads"}
        className="table-cell"
        component="th"
        id={company.leads}
        scope="row"
        padding="none"
        align="center"
      >
        <Tooltip title="Leads being delivered">
          <SendRounded
            className={company.analytics && company.analytics.totalLeadsGenerated && company.analytics.totalLeadsGenerated > 0 ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },
  {
    id: "analytics.totalProductViewCounter",
    numeric: true,
    disablePadding: false,
    label: "Views",
    cell: (company) => (
      <TableCell
        key={company.id + "_views"}
        align="center"
        className="table-cell"
      >
        <Tooltip title="Products being viewed">
          <Visibility
            className={company.analytics && company.analytics.totalProductViewCounter && company.analytics.totalProductViewCounter > 0 ? "complete-icon" : "waiting-icon"}
          />
        </Tooltip>
      </TableCell>
    ),
  },

  // {
  //   id: "purchased",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Purchased",
  //   cell: (company) => (
  //     <TableCell
  //       key={company.id + "_purchased"}
  //       align="center"
  //       className="table-cell"
  //     >
  //       <Tooltip title="Products being purchased">
  //         <Paid
  //           className={
  //             company.items_purchased ? "complete-icon" : "waiting-icon"
  //           }
  //         />
  //       </Tooltip>
  //     </TableCell>
  //   ),
  // },
];

function OnboardingTable({ companies }) {
  const [search, setSearch] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);

  async function deleteCompany() {
    if(["actuality_admin", "company_admin"].includes(user.role)){
      for (let index = 0; index < selectedCompanies.length; index++) {
        const company = selectedCompanies[index];
        if (archived) {
          await deleteDocument("/Companies/" + company).then(async (result) => {
            let companyDoc = companies.find((comp) => comp.id == company);
            await deleteFile(companyDoc.logo).then(result2 => {
              console.log(result, result2);
            })
            
          });
        } else {
          let companyDoc = companies.find((comp) => comp.id == company);
          companyDoc.archived = true;
          await setDocument("/Companies/" + company, companyDoc).then(
            (result) => {
              console.log(result);
            }
          );
        }
      }
      setShowModal(false);
      setSelectedCompanies([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  async function unarchiveFunc(companyList) {
    if(["actuality_admin", "company_admin"].includes(user.role)){
      for (let index = 0; index < companyList.length; index++) {
        const company = companyList[index];
        let companyDoc = companies.find((comp) => comp.id == company);
        companyDoc.archived = false;
        await setDocument("/Companies/" + company, companyDoc).then((result) => {
          console.log(result);
        });
      }
      setSelectedCompanies([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  return (
    <div className="table-restrict">
      {/* TABLE DISPLAYS ARCHIVED COMPANIES */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Pending Companies"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Companies"}
            />
          }
          rows={companies.filter(
            (row) =>
              row.trading_name.toLowerCase().includes(search.toLowerCase()) &&
              row.archived
          )}
          columns={cells}
          defaultOrder={"onboarding"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedCompanies(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedCompanies}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS NON-ARCHIVED COMPANIES */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Companies"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Companies"}
            />
          }
          rows={companies.filter(
            (row) =>
              row.trading_name.toLowerCase().includes(search.toLowerCase()) &&
              !row.archived
          )}
          columns={cells}
          defaultOrder={"onboarding"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedCompanies(value);
          }}
          archived={archived}
          selectedItems={selectedCompanies}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Company" : "Archive Company"}
        message={
          archived
            ? "Are you sure you would like to delete this company?"
            : "Are you sure you would like to archive this company?"
        }
        confirm={deleteCompany}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Companies" : "Show Archived Companies"}
        </a>
      </div>
    </div>
  );
}

export default OnboardingTable;
