import React, { useEffect, useState } from "react";
import "./CompanyOnboardingForm.css";
import "./CompanyColoursForm.css";
import ColourPicker from "../../colour/ToggleableColourPicker";

function CompanyColoursForm({ formik }) {
  const [colour1, changeColour1] = useState("");
  const [colour2, changeColour2] = useState("");
  const [colour3, changeColour3] = useState("");

  useEffect(() => {
    if(!formik) return;

    setColour1(formik.values.colour1);
    setColour2(formik.values.colour2);
    setColour3(formik.values.colour3);
  }, [formik])

  function setColour1(value) {
    changeColour1(value);
    formik.values.colour1 = value;
  }

  function setColour2(value) {
    changeColour2(value);
    formik.values.colour2 = value;
  }

  function setColour3(value) {
    changeColour3(value);
    formik.values.colour3 = value;
  }

  function removeColour(colour) {
    switch (colour) {
      case "colour1":
        setColour1(colour2);
      case "colour2":
        setColour2(colour3);
      case "colour3":
        setColour3(false);
      default:
        break;
    }
  }

  function addColour() {
    if (!colour1) {
      setColour1("#FFFFFF");
    } else if (!colour2) {
      setColour2("#FFFFFF");
    } else if (!colour3) {
      setColour3("#FFFFFF");
    }
  }

  return (
    <div style={{paddingTop: '25px'}}>
      <div className="">
        <div className="">
          <h6 className="section-heading"> Company Colours </h6>
          <p className="onboarding-subheading" style={{marginBottom: '5px'}}>
            Your primary business colours will be displayed on your profile.{" "}
          </p>
          <div className="colour-pickers-flex">
            <ColourPicker
              name="colour1"
              removeColour={() => removeColour("colour1")}
              colour={colour1}
              changeColour={setColour1}
              addColour={addColour}
              colorName="Primary"
            />
            <ColourPicker
              name="colour2"
              removeColour={() => removeColour("colour2")}
              colour={colour2}
              changeColour={setColour2}
              addColour={addColour}
              colorName="Accent"
            />
            {/* <ColourPicker
              name="colour3"
              removeColour={() => removeColour("colour3")}
              colour={colour3}
              changeColour={setColour3}
              addColour={addColour}
              colorName="Background"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyColoursForm;

// changeColour={(colour) => {setColours({...colours, colour1 : colour})}
