import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

const CreateProductAccordion = ({
  title,
  defaultExpanded = false,
  children,
}) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        borderRadius: "5px !important",
        marginBottom: "20px",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        position: "static",
        padding: '0',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>
          <span
            style={{
              fontWeight: 700,
              marginTop: "0.23rem",
              color: "#00355B",
              fontSize: "20px",
              marginBottom: "0.5rem",
            }}
          >
            {title}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CreateProductAccordion;
