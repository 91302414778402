import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  FilterListRounded,
  VisibilityRounded,
  EditRounded,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./TeamTable.css";
import Image from "../../images/Sample_User_Icon_grey.png";
import BaseTable from "./BaseTable";
import FirebaseImage from "../media/FirebaseImage";
import TableSearch from "../filters/TableSearch";
import { deleteDocument, getDocument, setDocument } from "../../firebase";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

const styleForButton = {
  width: "auto",
  height: "1.88rem",
  color: "#01365B",
  backgroundColor: "rgba(12, 113, 195, 0.23)",
  border: "1.23px solid rgba(1, 54, 91, 0.44)",
  borderRadius: "23px",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForButtonPending = {
  width: "auto",
  height: "1.88rem",
  color: "#FF9900",
  backgroundColor: "rgba(255, 153, 0, 0.23)",
  borderRadius: "23px",
  border: "1.23px solid rgba(255, 153, 0, 0.55)",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "100%",
  objectFit: "contain",
  backgroundColor: "lightgrey",
};

function TeamTable({ teamMembers, editMember }) {
  const [search, setSearch] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);

  const cells = [
    {
      id: "profile",
      numeric: false,
      disablePadding: true,
      label: "Profile Image",
      cell: (member) => (
        <TableCell
          key={member.email + "_profile"}
          align="center"
          className="table-cell"
        >
          <FirebaseImage
            path={member.profile}
            styleForImg={styleForImg}
            defaultImage={Image}
          />
        </TableCell>
      ),
    },
    {
      id: "first_name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      cell: (member) => (
        <TableCell
          key={member.email + "_name"}
          align="center"
          className="table-cell"
        >
          {member.first_name + " " + member.last_name}
        </TableCell>
      ),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      cell: (member) => (
        <TableCell
          key={member.email + "_email"}
          align="center"
          className="table-cell"
        >
          {member.email}
        </TableCell>
      ),
    },
    {
      id: "role",
      numeric: true,
      disablePadding: false,
      label: "Role",
      cell: (member) => (
        <TableCell
          key={member.email + "_role"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButton}>{member.role}</Button>
        </TableCell>
      ),
    },
    {
      id: "invite",
      numeric: true,
      disablePadding: false,
      label: "Invitation Status",
      cell: (member) => (
        <TableCell
          key={member.email + "invite"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButtonPending}>{member.status}</Button>
        </TableCell>
      ),
    },
    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (member) => (
        <TableCell
          key={member.email + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            onClick={() => editMember(member)}
            style={styleForIconButton}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
  ];

  async function deleteMember() {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < selectedMembers.length; index++) {
        const member = selectedMembers[index];
        if (archived) {
          await deleteDocument("/Users/" + member).then(async (result) => {
            await deleteDocument("Pending Users/" + member).then(
              async (result2) => {
                let membersDoc = teamMembers.find((mem) => mem.id == member);
                if(membersDoc.profile){
                  await deleteFile(membersDoc.profile).then((result3) => {
                    console.log(result, result2, result3);
                  }).catch(error => console.log(error));
                }
                
              }
            );
          });
        } else {
          let membersDoc = teamMembers.find((mem) => mem.id == member);
          membersDoc.archived = true;
          await getDocument("/Users/"+member).then(async (result) => {
            if(result.success){
              await setDocument("/Users/" + member, membersDoc, true).then(result => {
                console.log(result)
              })
            }
          })
          await getDocument("/Pending Users/"+member).then(async (result) => {
            if(result.success){
              await setDocument("/Pending Users/" + member, membersDoc, true).then(result => {
                console.log(result)
              })
            }
          })
          // await setDocument("/Users/" + member, membersDoc, true).then(
          //   async (result) => {
          //     await setDocument("Pending Users/" + member, membersDoc, true).then(
          //       (result2) => {
          //         console.log(result, result2);
          //       }
          //     );
          //   }
          // );
        }
      }
      setShowModal(false);
      setSelectedMembers([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  async function unarchiveFunc(memberList) {
    if (("actuality_admin", "company_admin")) {
      for (let index = 0; index < memberList.length; index++) {
        const member = memberList[index];
        let membersDoc = teamMembers.find((mem) => mem.id == member);
        membersDoc.archived = false;
        await setDocument("/Users/" + member, membersDoc).then(
          async (result) => {
            await setDocument("Pending Users/" + member, membersDoc).then(
              (result2) => {
                console.log(result, result2);
              }
            );
          }
        );
      }
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  return (
    <div>
      {/* TABLE DISPLAYS NON-ARCHIVED MEMBERS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Current Team Members"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Team"}
            />
          }
          rows={teamMembers.filter(
            (row) =>
              row.first_name
                .concat(" ", row.last_name, " ", row.email)
                .toLowerCase()
                .includes(search.toLowerCase()) && !row.archived
          )}
          columns={cells}
          defaultOrder={"email"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedMembers(value);
          }}
          archived={archived}
          selectedItems={selectedMembers}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS ARCHIVED MEMBERS */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Current Archived Members"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Team"}
            />
          }
          rows={teamMembers.filter(
            (row) =>
              row.first_name
                .concat(" ", row.last_name, " ", row.email)
                .toLowerCase()
                .includes(search.toLowerCase()) && row.archived
          )}
          columns={cells}
          defaultOrder={"email"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedMembers(value);
          }}
          archived={archived}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          selectedItems={selectedMembers}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Member" : "Archive Member"}
        message={
          archived
            ? "Are you sure you would like to delete this team member?"
            : "Are you sure you would like to archive this team member?"
        }
        confirm={deleteMember}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Members" : "Show Archived Members"}
        </a>
      </div>
    </div>
  );
}

export default TeamTable;
