import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";

function FeaturesProductForm({ submitting, setStatus, initialValues }) {
  const component_key = "features";

  const conditions = {};

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      warranty: 0,
      url: "",
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Features & Benefits </h6>
            <p className="onboarding-subheading"></p>

            <div className="select-title-flex">
              <span className="form-mini-title">Years Warranty</span>
              <input
                type="number"
                name="warranty"
                className="form-element-style"
                onChange={formik.handleChange}
                value={formik.values.warranty}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FeaturesProductForm;
