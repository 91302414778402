import React from "react";
import { useState } from "react";

// Importing Line Chart
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";

// Importing CSS
import "./LineChart.css";

function MyLineChart({ title, dataKey, grid, data }) {
  const [width, setWidth] = useState(window.innerWidth)
    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })
  return (
    <div className="line-chart">
      <h2 className="chart-title"> {title}</h2>
      <LineChart width={window.innerWidth > 1024 ? width - 700 : width - 106} height={175} data={data}>
        {grid && <CartesianGrid stroke="#EEF3F9" />}
        <XAxis dataKey="name" stroke="#0C71C3" />
        <YAxis dataKey={"views"} stroke={"#0c71c3"}/>
        <Line type="monotone" dataKey={dataKey} stroke="#0C71C3" />
        <Tooltip />
      </LineChart>
    </div>
  );
}

export default MyLineChart;
