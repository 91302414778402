import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import UserProductTable from "../../components/table/UserProductTable";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";

function UserProducts(props) {
  const [ products, setProducts ] = useState(props.products);

  useEffect(() => {
    var newProducts = []

    for (const id in props.products) {
      const product = props.products[id]

      if (!product.lastUpdated) {
        product['lastUpdatedText'] = "unknown"
      } else {
        const lastUpdated = product.lastUpdated
        var delta = Math.floor(Date.now() / 1000) - lastUpdated

        if (delta < 300) {
          // less than 5 minutes
          product['lastUpdatedText'] = "just now"
        } else if (delta < 86400) { 
          // Less than 24h
          product['lastUpdatedText'] = "today"
        } else if (delta < 604800) {
          // Less than 7d
          product['lastUpdatedText'] = "this week"
        } else {
          const daysAgo = Math.floor(delta / 86400)
          product['lastUpdatedText'] = daysAgo + ' days ago'
        }
      }

      newProducts.push(product)
    }

    setProducts(newProducts)
    
  }, [props.products])

  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text="Products "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/products", text: "Products" },
          ]}
        />
        <Link to={"/user/products/add-product"}>
          <button className="add-company-button"> Add Product</button>
        </Link>
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      <UserProductTable
        products={props.products}
        editProduct={() => console.log("edit")}
      />
      <div className="home-widgets"></div>
    </PageTemplate>
  );
}

export default UserProducts;
