import React, { useState } from "react";
import LineChart from "../../components/charts/LineChart";
import FilterBar from "../../components/filters/FilterBar";
import FeaturedInfo from "../../components/information/FeaturedInfo";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav from "../../components/topnav/TopNav";

import "./styles/Analytics.css";

//Importoing data
import PageTitle from "../../components/pageTitle/PageTitle";
import { Link } from "react-router-dom";

function Analytics() {
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );
  return (
    <div className="main-container">
      <TopNav sidebarClass={sidebarClass} setSidebar={setSidebarClass} />
      <div className="home-page">
        <div
          style={sidebarClass == "" ? { width: "130px" } : { width: "0px" }}
          className="sidebar-container"
        >
          
          <Sidebar sidebarClass={sidebarClass} setSidebar={setSidebarClass} />
        </div>
        <div className="app-body">
          <PageTitle
            text="Analytics "
            links={[
              { url: "/", text: "Home | " },
              { url: "/analytics", text: "Analytics" },
            ]}
          />
          {/* <div className="filter-bar-container">
            <FilterBar /> <div className="underline-flex"></div>
          </div> */}
          <FeaturedInfo/>
          {/* <LineChart
            data={SalesData}
            title="Sales Analytics"
            dataKey="Active Sales"
            grid
          /> */}
          <div className="home-widgets"></div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
