import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
  numberFormat,
} from "../../../helpers/form_utils";
import { getDocument } from "../../../firebase";
import { useState } from "react";
import FileUploader from "../files/FileUploader";
import ListFiles from "../files/ListFiles";
import { getBlob, getStorage, ref } from "firebase/storage";
import Select from "react-select";
import { stylesForSelect } from "../../../styles/select";

export default function BasinSpecs({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "basin_specs";
  const conditions = {};
  const [fbxFiles, setFbxFiles] = useState([]);
  const [usdzFiles, setUsdzFiles] = useState([]);
  const [options, setOptions] = useState([]);
  const formik = useFormik({
    initialValues: initialValues || {
      basinType: "",
      basinMaterial: "",
      basinShape: "",
      basinHeight: 0,
      basinWidth: 0,
      basinLength: 0,
      basinFbx: [],
      basinUsdz: [],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function updateFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    if (fileType === "fbx") {
      setFbxFiles(data);
    } else if (fileType === "usdz") {
      setUsdzFiles(data);
    }
  }

  useEffect(() => {
    getOptions();

    if (initialValues) {
      const storage = getStorage();
      let fbx = [];
      initialValues.basinFbx.map((path, index) => {
        const fileRef = ref(storage, path);
        getBlob(fileRef)
          .then((blob) => {
            var file = new File([blob], path.split("/").pop());
            fbx[index] = file;
            updateFiles("fbx", fbx);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
      let usdz = [];
      initialValues.basinUsdz.map((path, index) => {
        const fileRef = ref(storage, path);
        getBlob(fileRef)
          .then((blob) => {
            var file = new File([blob], path.split("/").pop());
            usdz[index] = file;
            updateFiles("usdz", usdz);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
    }
  }, []);

  function getOptions() {
    getDocument("Configuration/basin-options").then((result) => {
      if (result.success) {
        setOptions(result.data);
      } else {
        console.log(result.message);
      }
    });
  }

  function uploadFbxFiles(files) {
    let acceptedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      console.log(file);
      if (file.name.slice(file.name.length - 4) == ".fbx") {
        acceptedFiles.push(file);
      } else {
        console.log("file rejected");
      }
    }
    let allFiles = formik.values.basinFbx.concat(acceptedFiles);
    formik.setFieldValue("basinFbx", allFiles);
    setFbxFiles(allFiles);
  }

  function updateFbxFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    setFbxFiles([...data]);
  }

  function uploadUsdzFiles(files) {
    let acceptedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      console.log(file.type);
      if (file.name.slice(file.name.length - 5) == ".usdz") {
        acceptedFiles.push(file);
      } else {
        console.log("file rejected");
      }
    }
    let allFiles = formik.values.basinUsdz.concat(acceptedFiles);
    formik.setFieldValue("basinUsdz", allFiles);
    setUsdzFiles(allFiles);
  }

  function updateUsdzFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    setUsdzFiles([...data]);
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Specifications </h6>
            <p className="onboarding-subheading">
              Please enter as much information as possible
            </p>

            {/* MOUNTINGS */}
            <div className="form-elements-flex">
              <div className="left-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Type</span>

                  <Select
                    isClearable
                    value={
                      options.types &&
                      options.types.find(
                        (item) => item.value == formik.values.basinType
                      )
                    }
                    onChange={(value) =>
                      formik.setFieldValue("basinType", value.value)
                    }
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.basinType)}
                    options={options.types}
                  />
                </div>
              </div>
              <div className="right-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Material</span>
                  <Select
                    name="basinMaterial"
                    value={
                      options.materials &&
                      options.materials.find(
                        (item) => item.value == formik.values.basinMaterial
                      )
                    }
                    onChange={(value) =>
                      formik.setFieldValue("basinMaterial", value.value)
                    }
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.basinMaterial)}
                    options={options.materials}
                  />
                </div>
              </div>
            </div>

            {/* PHYSICAL SPECS */}
            <span className="form-mini-title">Dimensions (mm)</span>
            <div className="form-elements-flex">
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Length</span>

                <input
                  type="number"
                  name="basinLength"
                  className={
                    formik.errors.basinLength
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.basinLength}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Width</span>

                <input
                  type="number"
                  name="basinWidth"
                  className={
                    formik.errors.basinWidth
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.basinWidth}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Height</span>

                <input
                  type="number"
                  name="basinHeight"
                  className={
                    formik.errors.basinHeight
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.basinHeight}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div style={{ paddingLeft: "5px" }} className="select-title-flex">
                <span
                  style={{ marginBottom: "1.23rem" }}
                  className="form-mini-title"
                >
                  Shape
                </span>
                <select
                  name="basinShape"
                  value={formik.values.basinShape}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ display: "block" }}
                  type="name"
                  className={
                    formik.errors.basinShape
                      ? "error form-select-style"
                      : "form-select-style"
                  }
                >
                  <option value="square" label="Square" />
                  <option value="round" label="Round" />
                  <option value="curved" label="Curved" />
                  <option value="oval" label="Oval" />
                  <option value="rectangle" label="Rectangle" />
                </select>
              </div>
            </div>

            {/* PRODUCT 3D MODELS */}
            <span className="form-mini-title">Product 3D</span>
            <div className="form-elements-flex">
              <div className="right-elements">
                <div className="onboarding-subheading">
                  Add 3D FBX file (mobile app)
                </div>
                <div
                  className={
                    formik.errors.basinFbx
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                  <FileUploader setFiles={uploadFbxFiles}></FileUploader>
                  <ListFiles
                    files={fbxFiles}
                    setFiles={(data) => updateFbxFiles("vanityFbx", data)}
                  ></ListFiles>
                </div>
              </div>
              <div className="left-elements">
                <div className="onboarding-subheading">
                  Add 3D USDZ file (WebAr)
                </div>
                <div
                  className={
                    formik.errors.basinUsdz
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                  <FileUploader setFiles={uploadUsdzFiles}></FileUploader>
                  <ListFiles
                    files={usdzFiles}
                    setFiles={(data) => updateUsdzFiles("vanityUsdz", data)}
                  ></ListFiles>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
