import React, { useState } from "react";
import TeamTable from "../../components/table/TeamTable";
import AddTeamMemberForm from "../../components/forms/team/AddTeamMemberForm";
import {
  getDocument,
  setDocument,
  checkUserExists,
  updateDocument,
  firestore,
} from "../../firebase";
import PageTitle from "../../components/pageTitle/PageTitle";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import { useSelector } from "react-redux";
import { uploadFile } from "../../helpers/product_utils";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export default function Team(props) {
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [showAddTeamMember, setAddTeamMember] = useState(false);
  const [showEditTeamMember, setEditTeamMember] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);

  // creates a new company in firestore and invites the associated admin user
  async function NewMember(details) {
    try {
      const userInvitedResult = await getDocument(
        "Pending Users/" + details.email
      );
      if (userInvitedResult.success) {
        return { success: false, message: "User already invited." };
      } else if (
        userInvitedResult.message !==
        "No data retrieved. Document may not exist."
      ) {
        return userInvitedResult;
      }
      if (user.role !== "actuality_admin") {
        return {
          success: false,
          message: "You do not have the permission to create new admins",
        };
      }

      if (details.profile) {
        uploadFile("user_profiles/" + details.email, details.profile);
      }
      // add associated admin user to list of pending users
      const data = {
        first_name: details.first_name,
        last_name: details.last_name,
        email: details.email,
        role: details.role,
        phone: details.phone,
        companyId: company.id,
        companyName: company.trading_name || company.name,
        profile: "user_profiles/" + details.email,
        status: "invited",
        id: details.email,
      };

      let userUpdateResult = await userRoleUpdate(details, data);
      console.log(userUpdateResult)
      if (userUpdateResult) return userUpdateResult;
      const inviteUserResult = await setDocument(
        "Pending Users/" + details.email,
        data
      );
      if (inviteUserResult.success) {
        return { success: true, message: "User added." };
      } else {

        return inviteUserResult;
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  function userRoleUpdate(details, data) {
    return new Promise(async (resolve, reject) => {
      const userExistsResult = await checkUserExists(details.email);
      if (userExistsResult.success) {
        if (userExistsResult.userExists) {
          const docs = query(
            collection(firestore, "Users"),
            where("email", "==", details.email)
          );
          const querySnapshot = await getDocs(docs);
          querySnapshot.forEach(async (doc) => {
            let document = doc.data();
            document.role = data.role;
            if (!document.first_name) document.first_name = data.first_name;
            if (!document.last_name) document.last_name = data.last_name;
            if (!document.phone) document.phone = data.phone;
            await setDocument("Users/" + document.id, document)
              .then((result) => {
                if (result.success) {
                  resolve({
                    success: true,
                    message: "User has been updated to actuality admin",
                  });
                } else {
                  resolve(result);
                }
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          resolve(userExistsResult.userExists);
        }
      } else {
        resolve(userExistsResult.userExists);
      }
    });
  }

  async function editMember(member) {
    if (user.role !== "actuality_admin" && member.role == "actuality_admin") {
      return {
        success: false,
        message: "You do not have the permission to create new admins",
      };
    }
    const userData = {
      id: member.id,
      company: member.company || member.companyId,
      email: member.email,
      first_name: member.first_name,
      last_name: member.last_name,
      phone: member.phone,
      role: member.role,
      profile: "user_profiles/" + member.email || "",
    };
    const pendingUserData = {
      id: member.email,
      companyId: member.company || member.companyId,
      email: member.email,
      first_name: member.first_name,
      last_name: member.last_name,
      phone: member.phone,
      role: member.role,
      profile: "user_profiles/" + member.email || "",
    };
    if (member.profile && typeof member.profile === "object") {
      uploadFile("user_profiles/" + member.email, member.profile);
    }
    let updateUserResult;
    await updateDocument("Users/" + member.id, userData)
      .then((result) => {
        console.log(result);
        updateUserResult = result;
      })
      .catch((error) => {
        console.log(error);
        updateUserResult = error;
      });

    let updatePendingUserResult;
    await updateDocument("Pending Users/" + member.id, pendingUserData)
      .then((result) => {
        console.log(result);
        updatePendingUserResult = result;
      })
      .catch((error) => {
        console.log(error);
        updatePendingUserResult = error;
      });
    if (updateUserResult.success || updatePendingUserResult) {
      return { success: true, message: "User added." };
    } else {
      return updateUserResult;
    }
  }

  function showEditMember(member) {
    setMemberToEdit(member);
    setEditTeamMember(true);
  }

  return (
    <PageTemplate
      navigate={showAddTeamMember || showEditTeamMember ? props.navigate : null}
    >
      <div className="quick-buttons">
        <PageTitle
          navigate={
            showAddTeamMember || showEditTeamMember ? props.navigate : null
          }
          text="Team "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/team", text: "Team" },
          ]}
        />
        <button
          className="add-company-button"
          onClick={() => setAddTeamMember(true)}
        >
          Add Team Member
        </button>
      </div>

      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      {showAddTeamMember ? (
        <AddTeamMemberForm
          onAddTeamMember={NewMember}
          showElement={setAddTeamMember}
          type="admin"
        />
      ) : (
        ""
      )}
      {showEditTeamMember ? (
        <AddTeamMemberForm
          onAddTeamMember={editMember}
          showElement={setEditTeamMember}
          member={memberToEdit}
          type="admin"
        />
      ) : (
        ""
      )}
      <TeamTable teamMembers={props.teamMembers} editMember={showEditMember} />

      <div className="home-widgets"></div>
    </PageTemplate>
  );
}
