import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import ColourPicker from "../../colour/ColourPicker";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import tinycolor from "tinycolor2";
import { IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { toast } from "react-toastify";

const colourNameStyle = {
  margin: "0 0 1rem 0",
  height: "2.3rem",
  borderStyle: "none",
  backgroundColor: "#f1f1f1",
  borderRadius: "5px",
  fontSize: "18px",
  color: "#0C71C3",
  textAlign: "left",
  maxWidth: '200px',
  padding: '20px 0px !important'
};

function ColourProductForm({ submitting, setStatus, initialValues }) {
  const component_key = "colours";
  const [colour, setColour] = useState("#0C71C3");
  const [colours, setColours] = useState(
    initialValues ? initialValues.colours : []
  );
  const [colourName, setName] = useState("");

  // colour is required
  const conditions = {
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      colours: [],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function addColour() {
    formik.setErrors({});
    if (colourName !== "" && colours.length < 20) {
      formik.values.colours.push({
        hex: colour,
        name: colourName,
        text_colour:
          tinycolor(colour).getBrightness() >= 180 ? "black" : "white",
      });
      setName("");
      setColours(formik.values.colours);
    } else {
      toast.error("You must enter a colour name")
    }
  }

  const styleForButton = {
    marginTop: "1.23rem",
    height: "2.23rem",
    fontSize: "10px",
    borderRadius: "44px",
    backgroundColor: "#01365B",
    color: "white",
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontWeight: "500",
    marginBottom: '20px',
    border: "none",
  };

  function removeColour(colour) {
    const newList = formik.values.colours.filter((item) => item !== colour);

    formik.values.colours = newList;
    setColours(newList);
  }

  function handleSubmit(e) {
    e.preventDefault();
    addColour();
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      addColour();
    }
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Colours *</h6>
            <p className="onboarding-subheading">
              Use colour picker to select closest colour and type name of the
              colour and press Add.
            </p>
            <div className="form-elements-flex">
              <div className="left-elements" style={{marginRight: '40px'}}>
                <div className="colour-button-flex">
                  <h6 className="section-heading" style={{ fontSize: '14px' }}>Colour Name</h6>
                  <input
                    style={colourNameStyle}
                    type="name"
                    placeholder="Colour Name"
                    value={colourName}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />

                  <h6 className="section-heading" style={{ fontSize: '14px' }}> Select Your Colour</h6>
                  <ColourPicker color={colour} setColor={setColour} />
                  <button
                    type="button"
                    onClick={addColour}
                    style={styleForButton}
                  >
                    Add Colour
                  </button>
                </div>
              </div>

              <div style={{
                width: '100%',
              }}>
                {/* TODO make the colours layout and error look better */}
                <h6 className="section-heading" style={{ fontSize: '14px' }}> Your Selected Colours </h6>

                <div
                  className={formik.errors.colours ? "error right-elements" : "right-elements"}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {colours.map((colour, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          background: colour.hex,
                          color: colour.text_colour,
                          marginRight: '5px',
                          marginBottom: '10px'
                        }}
                        className="colour-name"
                      >
                        {colour.name}
                        <IconButton
                          style={{ height: "1.2rem", width: "1.2rem" }}
                          onClick={() => removeColour(colour)}
                        >
                          <CloseRounded
                            style={{
                              height: "1.2rem",
                              width: "1.2rem",
                              color: colour.text_colour,
                            }}
                          />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <p className="important-info">

              Add a colour either by moving the cursor over the colour square or
              enter the Hex Code in manually. You can add up to 20 colours per
              product.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ColourProductForm;
