import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./confirmModal.css";
import { logout } from "../../helpers/auth_utils";
import { useDispatch } from "react-redux";

function NavConfirmModal(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  //When button to leave page is clicked
  const handleClick = async () => {
      if(props.url == "/login"){
        let result = await logout(dispatch);
        window.alert(result.message);
      }
      navigate(props.url);
      props.closeCallback(false)
  }

  return (
    <div
      style={props.display ? { display: "block" } : { display: "none" }}
      className="modalContainer"
    >
      <div className="escapeModal">
        <div className="modalHead">
          <div className="modalTitle">Unsaved Changes</div>
          <div className="modalClose">
            <CloseRounded onClick={() => props.closeCallback(false)} />
          </div>
        </div>
        <div className="modalBody">
          If you leave this page, any unsaved changes will be lost.
        </div>
        <div className="modalFoot">
          <div className="modalButtons">
            <Button
              onClick={() => props.closeCallback(false)}
              className="modalButtonCancel"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button className="modalButtonLeave" onClick={handleClick} variant="contained">
              Leave Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavConfirmModal;
