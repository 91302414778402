import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";

function SamplesProductForm({ submitting, setStatus, initialValues, company }) {
  const component_key = "samples";

  const unit = company.currency || "NZD"

  const conditions = {
    // costPerSample : [(value) => {
    //     if (formik.values.allowSamples) {
    //         return notEmpty(value);
    //     } else return true;
    // }],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      costPerSample: 0.0,
      allowSamples: false,
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Samples </h6>
            <p className="onboarding-subheading"></p>
            <div className="">
              <div className="">
                <div className="mini-horizontal-flex">
                  <input
                    type="checkbox"
                    name="allowSamples"
                    className="checkbox"
                    onChange={formik.handleChange}
                    value={formik.values.allowSamples}
                    onBlur={formik.handleBlur}
                    checked={formik.values.allowSamples}
                  />
                  <h6 className="midi-heading">
                    Allow customers to purchase a sample.
                  </h6>
                </div>
              </div>

              {formik.values.allowSamples &&
                <div className="">
                  <div className="select-title-flex">
                    <span className="form-mini-title">Cost Per Sample</span>
                    <div className="input-adornment">
                      <span className="adornment-text">{unit}</span>
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        name="costPerSample"
                        className={
                          formik.errors.costPerSample
                            ? "error form-select-style-margin"
                            : "form-select-style-margin"
                        }
                        style={{maxWidth: '200px'}}
                        onChange={formik.handleChange}
                        value={formik.values.costPerSample}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SamplesProductForm;
