import React, { useState } from "react";
import { useSelector } from "react-redux";
import PageTemplate from "../../components/pageTemplate/pageTemplate";

function PaymentSuccess() {
  const user = useSelector((state) => state.user);
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );

  return (
    <PageTemplate>
      <div className="quick-buttons">
        <h1 className="display-title">Payment Success</h1>
      </div>
      <div className="">
        <h5>
          We have received your payment and will begin processing your subscription shortly. 
        </h5>

        <a href="/" style={{ fontSize: '16px', marginTop: '10px'}}>Back to home</a>
      </div>
    </PageTemplate>
  );
}

export default PaymentSuccess;
