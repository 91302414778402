import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  VisibilityOffRounded,
  VisibilityRounded,
  EditRounded,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./UserProductTable.css";
import Image from "../../images/exclamation-point.png";
import BaseTable from "./BaseTable";
import FirebaseImage from "../media/FirebaseImage";
import { useNavigate } from "react-router-dom";
import { deleteDocument, setDocument, updateDocument } from "../../firebase";
import TableSearch from "../filters/TableSearch";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

const styleForIconButton = (disabled) => {
  return {
    width: "2.3rem",
    height: "2.3rem",
    borderRadius: "2.3rem",
    color: disabled ? "lightgrey" : "#01365B",
  };
};

const styleForButton = (status) => {
  let backgroundColor = "darkgrey";
  switch (status) {
    case "live":
      backgroundColor = "#01365B";
      break;
    case "processing":
      backgroundColor = "#0C71C3";
      break;
    case "draft":
      backgroundColor = "#70a6cc";
      break;
    case "error":
      backgroundColor = "red";
      break;
  }
  return {
    width: "auto",
    height: "1.88rem",
    color: "#FFFFFF",
    backgroundColor: backgroundColor,
    borderRadius: "23px",
    fontSize: "12px",
  };
};

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "5px",
};

function UserProductTable({ products }) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);

  function editProduct(product) {
    navigate("/user/products/edit/" + product.id);
  }

  function toggleVisibility(product) {
    updateDocument("Products/" + product.id, { visible: !product.visible });
  }

  const cells = [
    {
      id: "image",
      numeric: false,
      disablePadding: true,
      label: "Image",
      cell: (product) => (
        <TableCell
          key={product.id + "_image"}
          align="center"
          className="table-cell"
        >
          {product.productType === "paint" &&
          product.colours.colours &&
          product.colours.colours.length > 0 ? (
            <div
              style={{
                margin: "auto",
                width: "4.23rem",
                height: "4.23rem",
                borderRadius: "5px",
                backgroundColor: product.colours.colours[0].hex,
              }}
            ></div>
          ) : (
            <FirebaseImage
              path={
                product.icon && product.icon.icon ? product.icon.icon[0] : ""
              }
              styleForImg={styleForImg}
              defaultImage={Image}
            />
          )}
        </TableCell>
      ),
    },

    {
      id: "details.productname",
      numeric: false,
      disablePadding: true,
      label: "Product",
      cell: (product) => (
        <TableCell
          key={product.id + "_name"}
          align="center"
          className="table-cell"
        >
          {product.details.productname || "N/A"} <br/>
          {product.lastUpdatedText !== 'unknown' && 
            <small style={{ fontSize: '11px'}}>Last updated {product.lastUpdatedText}</small>
          }
        </TableCell>
      ),
    },
    {
      id: "inventory.sku",
      numeric: false,
      disablePadding: false,
      label: "SKU",
      cell: (product) => (
        <TableCell
          key={product.id + "_sku"}
          align="center"
          className="table-cell"
        >
          {product.inventory ? product.inventory.sku : "N/A"}
        </TableCell>
      ),
    },
    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (product) => (
        <TableCell
          key={product.id + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            onClick={() => editProduct(product)}
            style={styleForIconButton(false)}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
    {
      id: "views",
      numeric: true,
      disablePadding: false,
      label: "Views",
      cell: (product) => (
        <TableCell
          key={product.id + "_views"}
          align="center"
          className="table-cell"
        >
          {product.views !== undefined ? product.views : "N/A"}
        </TableCell>
      ),
    },
    {
      id: "leads",
      numeric: true,
      disablePadding: false,
      label: "Leads",
      cell: (product) => (
        <TableCell
          key={product.id + "_leads"}
          align="center"
          className="table-cell"
        >
          {product.leads_generated !== undefined
            ? product.leads_generated
            : "N/A"}
        </TableCell>
      ),
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      cell: (product) => (
        <TableCell
          key={product.id + "_status"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButton(product.status)}>
            {product.status !== undefined ? product.status : "Unknown"}
          </Button>
        </TableCell>
      ),
    },
    ...(["actuality_admin", "company_admin", "company_editor"].includes(user.role) ? [{
      id: "visible",
      numeric: true,
      disablePadding: false,
      label: "Visible",
      cell: (product) => (
        <TableCell
          key={product.id + "_visible"}
          align="center"
          className="table-cell"
        >
          <IconButton
            disabled={product.status !== "live"}
            style={styleForIconButton(product.status !== "live")}
            onClick={() => toggleVisibility(product)}
          >
            {product.visible ? <VisibilityRounded /> : <VisibilityOffRounded />}
          </IconButton>
        </TableCell>
      ),
    }] : [])
  ];

  console.log(cells)
  async function deleteProduct() {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < selectedProducts.length; index++) {
        const product = selectedProducts[index];
        if (archived) {
          let productDoc = products.find((prod) => prod.id == product);
          await deleteDocument("/Products/" + product).then(async (result) => {
            productDoc.icon.icon
              .concat(
                [
                  ...productDoc.media.images,
                  productDoc.media.video,
                  productDoc.documents.manual,
                  productDoc.documents.installation,
                  productDoc.documents.returns,
                  productDoc.documents.warranty,
                ],
                productDoc.tile_variation && [
                  ...productDoc.tile_variation.images,
                ],
                productDoc.paver_variation && [
                  ...productDoc.paver_variation.images,
                ]
              )
              .map(async (file) => {
                if (file) {
                  await deleteFile(file)
                    .then((result2) => {
                      console.log(result, result2);
                    })
                    .catch((error) => console.error(error));
                }
              });
          });
        } else {
          let productDoc = products.find((prod) => prod.id == product);
          productDoc.archived = true;
          productDoc.visible = false;
          await setDocument("/Products/" + product, productDoc).then(
            (result) => {
              console.log(result);
            }
          );
        }
      }
      setShowModal(false);
      setSelectedProducts([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  async function unarchiveFunc(productList) {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < productList.length; index++) {
        const product = productList[index];
        let productDoc = products.find((prod) => prod.id == product);
        productDoc.archived = false;
        await setDocument("/Products/" + product, productDoc).then((result) => {
          console.log(result);
        });
      }
      setSelectedProducts([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  return (
    <div style={{ marginBottom: "50px" }}>
      {/* TABLE DISPLAYS ARCHIVED MEMBERS */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          useSavedSort={true}
          rows={products.filter(
            (row) =>
              (row.details &&
                row.details.productname
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                row.archived) ||
              (row.inventory &&
                row.inventory.sku
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                row.archived)
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS NON-ARCHIVED MEMBERS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          useSavedSort={true}
          rows={products.filter(
            (row) =>
              (row.details &&
                row.details.productname
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                !row.archived) ||
              (row.inventory &&
                row.inventory.sku
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                !row.archived)
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Product" : "Archive Product"}
        message={
          archived
            ? "Are you sure you would like to delete this product?"
            : "Are you sure you would like to archive this product?"
        }
        confirm={deleteProduct}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Products" : "Show Archived Products"}
        </a>
      </div>
    </div>
  );
}
export default UserProductTable;
