import React, { useState } from "react";
import "./CompanyOnboardingForm.css";
import "./CompanyBrandingForm.css";
import FileUploader from "../files/ImageUploader";
import CompanyColoursForm from "./CompanyColoursForm";

function CompanySocialMarketingBrandingForm({ formik }) {
  console.log(formik.values.banner);
  return (
    <div className="company-onboarding-container">
      <div className="onboarding-form">
        <div className="left-items">
          <div>
            <label className="form-label" htmlFor="profile">
              Profile Name:
            </label>
            <input
              // placeholder="Profile Name"
              type="name"
              name="profile"
              className={
                formik.errors.profile ? "error input-style" : "input-style"
              }
              onChange={formik.handleChange}
              value={formik.values.profile}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            <label className="form-label" htmlFor="bio">
              Enter Your Business Bio:
            </label>
            <textarea
              // placeholder="Enter your business bio"
              type="textarea"
              name="bio"
              className="input-style-para"
              onChange={formik.handleChange}
              value={formik.values.bio}
              onBlur={formik.handleBlur}
            />
          </div>
          <label className="form-label" htmlFor="website">
            Website:
          </label>
          <input
            // placeholder="Website"
            type="name"
            name="website"
            className={
              formik.errors.website
                ? "error small-input-style-left"
                : "small-input-style-left"
            }
            onChange={formik.handleChange}
            value={formik.values.website}
            onBlur={formik.handleBlur}
          />
          <h6 className="section-heading" style={{ marginTop: "5px" }}>
            {" "}
            Social Media Profiles{" "}
          </h6>
          <p className="onboarding-subheading" style={{ marginBottom: 0 }}>
            We'll show customers your social media profiles throughout our apps.
          </p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "15px",
              rowGap: "0",
            }}
          >
            <div>
              <label className="form-label" htmlFor="social">
                Instagram Username
              </label>
              <input
                placeholder=""
                type="text"
                name="socialProfiles.instagramUsername"
                className="input-style"
                onChange={formik.handleChange}
                value={formik.values.socialProfiles.instagramUsername}
                onBlur={formik.handleBlur}
                pattern={"[a-zA-Z-0-9.]+"}
              />
            </div>
            <div>
              <label className="form-label" htmlFor="social2">
                Twitter Username
              </label>
              <input
                placeholder=""
                type="text"
                name="socialProfiles.twitterUsername"
                className="input-style"
                onChange={formik.handleChange}
                value={formik.values.socialProfiles.twitterUsername}
                onBlur={formik.handleBlur}
                pattern={"[a-zA-Z-0-9.]+"}
              />
            </div>
            <div>
              <label className="form-label" htmlFor="social2">
                Facebook Username
              </label>
              <input
                placeholder=""
                type="text"
                name="socialProfiles.facebookUsername"
                className="input-style"
                onChange={formik.handleChange}
                value={formik.values.socialProfiles.facebookUsername}
                onBlur={formik.handleBlur}
                pattern={"[a-zA-Z-0-9.]+"}
              />
            </div>
            <div style={{}}>
              <label className="form-label" htmlFor="social2">
                Linkedin Username
              </label>
              <input
                placeholder=""
                type="text"
                name="socialProfiles.linkedinUsername"
                className="input-style"
                onChange={formik.handleChange}
                value={formik.values.socialProfiles.linkedinUsername}
                onBlur={formik.handleBlur}
                pattern={"[a-zA-Z-0-9.]+"}
              />
            </div>
            <div>
              <label className="form-label" htmlFor="social2">
                Pinterest Username
              </label>
              <input
                placeholder=""
                type="text"
                name="socialProfiles.pinterestUsername"
                className="input-style"
                onChange={formik.handleChange}
                value={formik.values.socialProfiles.pinterestUsername}
                onBlur={formik.handleBlur}
                pattern={"[a-zA-Z-0-9.]+"}
              />
            </div>
          </div>
        </div>

        <div className="right-items">
          <h6 className="section-heading"> Your Company Logo </h6>
          <p className="onboarding-subheading">
            We support PNG and JPEG files.
          </p>

          <FileUploader
            initialFile={formik.values.logo}
            setFile={(value) => formik.setFieldValue("logo", value)}
          ></FileUploader>

          <h6 className="section-heading" style={{marginTop:"1.23rem"}}> Your Company Banner </h6>
          <p className="onboarding-subheading">
            Letterbox banner, at least 1500px wide by 400px.
          </p>

          <FileUploader
            initialFile={formik.values.banner}
            setFile={(value) => formik.setFieldValue("banner", value)}
            buttonStyle={{
              width: "20.6rem",
              height: "8.8rem",
              objectFit: "wrap",
              borderRadius: "5px",
              backgroundColor: "#f0f0f0"
            }}
          ></FileUploader>

          <CompanyColoursForm formik={formik}></CompanyColoursForm>
        </div>
      </div>
    </div>
  );
}

export default CompanySocialMarketingBrandingForm;
