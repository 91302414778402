import { setDocument, updateDocument } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { forkJoin } from "rxjs";

const storage = getStorage();

function getLogoUrl(companyId) {
  return new Promise(async (resolve, reject) => {
    try {
      await getDownloadURL(ref(storage, "logos/" + companyId)).then(url => {
        resolve(url);
      });
    } catch (error) {
      reject(false);
    }
  });
}

function getBannerUrl(companyId) {
  return new Promise(async (resolve, reject) => {
    try {
      await getDownloadURL(ref(storage, "banners/" + companyId)).then(url => {
        resolve(url);
      });
      
    } catch (error) {
      reject(false);
    }
  });
}

export async function onboardCompany(formInput, companyId, completeOnboard) {
  async function updateCompanyData(result) {
    if (
      result.logoUpload.success &&
      result.bannerUpload.success &&
      result.privateInfoUpload.success &&
      result.publicInfoUpload.success
    ) {
      console.log(formInput);
      const logoUrl = formInput.logo ? await getLogoUrl(companyId) : null
      const bannerUrl = formInput.banner ? await getBannerUrl(companyId) : null
      const updateResult = await updateDocument("Companies/" + companyId, {
        trading_name: formInput.tradingname,
        onboarded: true,
        logo: logoUrl,
        banner: bannerUrl,
      });
      completeOnboard(updateResult);
    } else {
      completeOnboard(result);
    }
  }

  forkJoin({
    logoUpload: uploadFile(formInput.logo, companyId, "logos/"),
    bannerUpload: uploadFile(formInput.banner, companyId, "banners/"),
    privateInfoUpload: setDocument(
      "Companies/" + companyId + "/info/public",
      createPublicInfo(formInput, companyId)
    ).then((result) => {
      console.log(result.message);
      return result;
    }),
    publicInfoUpload: setDocument(
      "Companies/" + companyId + "/info/private",
      createPrivateInfo(formInput)
    ).then((result) => {
      console.log(result.message);
      return result;
    }),
  }).subscribe((result) => {
    updateCompanyData(result);
  });
}

function createPublicInfo(companyData, companyId) {
  // const logo = await getLogoUrl(companyId);
  let publicInfo = {
    // logo: logo, TODO figure out what to do here
    trading_name: companyData.tradingname,
    address: {
      street_address: companyData.address,
      building: companyData.building,
    },
    product_types: [],
    profile: companyData.profile,
    bio: companyData.bio,
    website: companyData.website,
    social1: companyData.social,
    social2: companyData.social2,
    weightSystem: companyData.weightSystem,
    unitSystem: companyData.unitSystem,
    currency: companyData.currency,
    timeZone: companyData.timeZone,
    iosAppName: companyData.iosAppName,
    iosBundleID: companyData.iosBundleID,
    iosAppEnabled: companyData.iosAppEnabled,
    androidAppEnabled: companyData.androidAppEnabled,
    androidAppName: companyData.androidAppName,
    androidBundleId: companyData.androidBundleId,
    enabledProductTypes: companyData.enabledProductTypes,
    socialProfiles: companyData.socialProfiles,
  };

  function sortType(name) {
    if (companyData["type_" + name]) {
      publicInfo.product_types.push(name);
    }
  }
  sortType("tiles");
  sortType("roofing");
  sortType("furniture");
  sortType("bathroom");
  sortType("kitchen");
  sortType("home");

  function sortColour(name) {
    if (companyData[name]) {
      publicInfo[name] = companyData[name];
    }
  }
  sortColour("colour1");
  sortColour("colour2");
  sortColour("colour3");

  return publicInfo;
}

function createPrivateInfo(companyData) {
  return {
    nzbn: companyData.nzbn,
    gst: companyData.gst,
    orderPrefix: companyData.orderPrefix,
    supportEmail: companyData.supportEmail,
    supportPhone: companyData.supportPhone,
    leadsEmail: companyData.leadsEmail,
  };
}

async function uploadFile(file, companyId, location) {
  // TODO there is a bug here where sometimes continually get the error : ref._location is undefined - fixed on refresh but seemingly unpredictable
  return new Promise(async (resolve, reject) => {
    try {
      let result = { message: "Operation didn't perform", success: false };
      if (!file || typeof file == "string") {
        result = { message: "No file to upload.", success: true };
      } else if (file.type.startsWith("image/")) {
        try {
          const fileRef = ref(storage, location + companyId);
          await uploadBytes(fileRef, file);
          result = { message: "file successfully uploaded", success: true };
        } catch (error) {
          result = { message: error.message, success: false };
        }
      } else {
        result.message = "Invalid file format.";
      }
      console.log(result.message);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

// TODO create option to save as draft (probably not hugely critical for company onboarding which doesn't have a whole lot in it? but might be important for add product)
