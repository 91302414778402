// import React from "react";
// import "./style/CardStyle.css";
// import { AddCircleRounded, Done } from "@mui/icons-material";
// import { IconButton } from "@mui/material";
// import { useSelector } from "react-redux";
// import { useState } from "react";
// import { useEffect } from "react";
// import { getDocument } from "../../firebase";

// function MiniCounterCard() {
//   const company = useSelector((state) => state.company);
//   const [companyInfo, setCompanyInfo] = useState({});

//   const styleForButton = {
//     width: "4.23rem",
//     height: "4.23rem",
//     borderRadius: "0.23rem",
//     backgroundColor: "rgba(12, 113, 195, 0.23)",
//   };

//   const styleForIcon = {
//     width: "2.23rem",
//     height: "2.23rem",
//     color: "#01365B",
//   };

//   useEffect(() => {
//     getDocument("Companies/" + company.id + "/info/public").then(
//       (publicResult) => {
//         getDocument("Companies/" + company.id + "/info/private").then(
//           (privateResult) => {
//             setCompanyInfo(
//               Object.assign(publicResult.data, privateResult.data)
//             );
//           }
//         );
//       }
//     );
//   }, []);

//   console.log(companyInfo.product_types)

//   return (
//     <div className="mini-cards-flex">
//       {/* <div className="mini-card-container">
//         <IconButton style={styleForButton}>
//           {company.num_products ? (
//             <Done style={styleForIcon} />
//           ) : (
//             <AddCircleRounded style={styleForIcon} />
//           )}
//         </IconButton>
//         <span className="mini-card-title">Products</span>
//         <span className="mini-card-counter">{company.num_products}</span>
//       </div>
//       <div className="mini-card-container">
//         <IconButton style={styleForButton}>
//           {companyInfo.product_types && companyInfo.product_types.length > 0 ? (
//             <Done style={styleForIcon} />
//           ) : (
//             <AddCircleRounded style={styleForIcon} />
//           )}
//         </IconButton>
//         <span className="mini-card-title">Categories</span>
//         <span className="mini-card-counter">
//           {companyInfo.product_types ? companyInfo.product_types.length : 0}
//         </span>
//       </div>
//       <div className="mini-card-container">
//         <IconButton style={styleForButton}>
//           <AddCircleRounded style={styleForIcon} />
//         </IconButton>
//         <span className="mini-card-title">Wishlists</span>
//         <span className="mini-card-counter">0</span>
//       </div>
//       <div className="mini-card-container">
//       <IconButton style={styleForButton}>
//           {company.num_users ? (
//             <Done style={styleForIcon} />
//           ) : (
//             <AddCircleRounded style={styleForIcon} />
//           )}
//         </IconButton>
//         <span className="mini-card-title">Users</span>
//         <span className="mini-card-counter">{company.num_users}</span>
//       </div> */}

//     </div>
//   );
// }

// export default MiniCounterCard;

/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDocument } from "../../firebase";
// import IconCard from 'components/cards/IconCard';
// import data from 'data/iconCards';
import GlideComponent from "../carousel/GlideComponent";
import CompanyDataCard from "./CompanyDataCard";

// const data = [{icon: true, text: "AAAA", num: 0}, {icon: false, text: "BBBB", num: 0},{icon: true, text: "CCCC", num: 1},{icon: true, text: "DDDD", num: 0}]

const IconCardsCarousel = ({ className = "" }) => {
  const [data, setData] = useState([
    { icon: true, text: "", num: 0 },
    { icon: true, text: "", num: 0 },
    { icon: true, text: "", num: 0 },
    { icon: true, text: "", num: 0 },
  ]);
  const company = useSelector((state) => state.company);

  useEffect(() => {
    getDocument("Companies/" + company.id + "/info/public").then(
      (publicResult) => {
        setData([
          {text: "Products", num: company.num_products, icon: !company.num_products },
          {text: "Categories", num: publicResult.data.product_types ? publicResult.data.product_types.length : 0, icon: !publicResult.data.product_types || publicResult.data.product_types.length == 0 },
          {text: "Wishlists", num: 0, icon: true },
          {text: "Users", num: company.num_users, icon: !company.num_users },
        ])
      }
    );
  }, []);

  return (
    <div className={className}>
      <GlideComponent
        settings={{
          gap: 5,
          perView: 4,
          type: "carousel",
          breakpoints: {
            320: { perView: 1 },
            576: { perView: 2 },
            1600: { perView: 3 },
            1800: { perView: 4 },
          },
          hideNav: true,
        }}
      >
        {data.map((item, index) => {
          return (
            <div key={`icon_card_${index}`}>
              <CompanyDataCard
                icon={item.icon}
                text={item.text}
                num={item.num}
              />
            </div>
          );
        })}
      </GlideComponent>
    </div>
  );
};
export default IconCardsCarousel;
