import React from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import './ColourPicker.css';
import { IconButton } from '@mui/material'
import { AddToPhotosRounded, CloseRounded } from '@mui/icons-material';

function ColourPicker({ removeColour, colour, changeColour, addColour, colorName }) {

    const addButtonStyle = {
        width: '7.7rem',
        height: '7.7rem',
        color: '#0C71C360',
    };

    return (
        <div className='colour-container' style={{ marginRight: '20px', marginBottom: '10px' }}>
            <p style={{ fontSize: '15px', fontWeight: 'bold', padding: 0, margin: 0, marginBottom: '12px', color: "#01365B" }}>{colorName}</p>
            {colour ?
                <>
                    <div className="small-colour">
                        <HexColorPicker className="responsive-colour" color={colour} onChange={changeColour} />
                    </div>
                    <div className="colour-input-container">
                        <span className='colour-input'>#</span>
                        <HexColorInput color={colour} onChange={changeColour} placeholder='Type your colour' className="colour-input" />
                    </div>
                </>
                :
                <div className="colour-input-container">
                    <IconButton onClick={addColour}><AddToPhotosRounded style={addButtonStyle} /></IconButton>
                </div>
            }
        </div>
    )
}

export default ColourPicker

