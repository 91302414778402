import React, { useState, useEffect } from "react";
import UserSidebar from "../../components/sidebar/UserSidebar";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import ContactActuality from "../../components/forms/support/ContactActuality";
import { SupportForm } from "../../components/forms/support/SupportForm";

function UserSupportPage() {
  return (
    <PageTemplate>
    <div className="quick-buttons">
      <PageTitle
        text="Support "
        links={[{ url: "/user/settings/support", text: "Home" }]}
      />
    </div>
      <div className="underline-flex"></div>
    <div className="home-widgets">
    
      <div className="mini-home-widgets">
       
       <SupportForm/>
       
      </div>
    </div>
    <div className="home-lower-body">
     
    </div>
  </PageTemplate>
  )
}

export default UserSupportPage;