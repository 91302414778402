import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";

function DetailsProductForm({ submitting, setStatus, initialValues }) {
  const component_key = "details";

  const conditions = {
    productname: [notEmpty],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      productname: "",
      subtitle: "",
      description: "",
      notes: "",
      producturl: "",
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Details </h6>
            <p className="onboarding-subheading"> </p>
            <div className="select-title-flex">
              <span className="form-mini-title">Product Name *</span>
              <input
                type="name"
                name="productname"
                className={
                  formik.errors.productname
                    ? "error form-element-style"
                    : "form-element-style"
                }
                onChange={formik.handleChange}
                value={formik.values.productname}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="select-title-flex">
              <span className="form-mini-title">Subtitle</span>
              <input
                type="name"
                name="subtitle"
                className="form-element-style"
                onChange={formik.handleChange}
                value={formik.values.subtitle}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="select-title-flex">
              <span className="form-mini-title">Description</span>
              <textarea
                type="textarea"
                name="description"
                className="form-element-description"
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="select-title-flex">
              <span className="form-mini-title">Product Notes</span>
              <div className="small-input-flex">
                <textarea
                  type="textarea"
                  name="notes"
                  className="form-element-notes"
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div style={{marginTop: "0.23rem"}} className="select-title-flex">
              <span className="form-mini-title">Product URL</span>
              <div className="small-input-flex">
                <input
                type="name"
                name="producturl"
                className="form-element-style"
                onChange={formik.handleChange}
                value={formik.values.producturl}
                onBlur={formik.handleBlur}
              />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default DetailsProductForm;
