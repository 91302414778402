import {
  Cancel,
  CheckCircle,
  CheckRounded,
  CloseRounded,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { addDocument, updateDocument } from "../../../firebase";
import { uploadFile } from "../../../helpers/product_utils";
import FirebaseImage from "../../media/FirebaseImage";
import FileUploader from "../files/ImageUploader";
import "./NewColourMaterialForm.css";

export default function NewGroupForm(props) {
  const [area, setArea] = useState(
    props.initialValues
      ? props.initialValues.area
      : props.group.type == "vanity"
      ? "sides"
      : props.group.type == "shower"
      ? "joinery"
      : props.group.type == "mirror cabinet"
      ? "sides"
      : ""
  );
  const [code, setCode] = useState(
    props.initialValues ? props.initialValues.code : ""
  );
  const [search, setSearch] = useState("");
  const [colours, setColours] = useState(
    JSON.parse(JSON.stringify(props.colours))
  );
  const [materials, setMaterials] = useState(
    JSON.parse(JSON.stringify(props.materials))
  );
  const [error, setError] = useState({
    code: false,
  });
  const [formComplete, setFormComplete] = useState(false);
  async function submitHandler(e) {
    e.preventDefault();
    const selectedItems = colours.concat(materials).filter((item) => {
      return item.included;
    });
    let selectedItemIds = [];
    selectedItems.map((item) => {
      selectedItemIds.push(item.id);
    });
    const data = {
      area: area,
      code: code,
      items: selectedItemIds,
    };
    if (code.length < 1) {
      setError({ code: true });
    } else {
      if (props.initialValues) {
        await updateDocument(
          "Companies/" +
            props.company.id +
            "/groups/" +
            props.group.id +
            "/codes/" +
            props.initialValues.id,
          data
        ).then((result) => {
          setFormComplete(true)
          console.log(result);
        });
      } else {
        await addDocument(
          "Companies/" +
            props.company.id +
            "/groups/" +
            props.group.id +
            "/codes",
          data
        ).then((result) => {
          setFormComplete(true);
          console.log(result);
        });
      }
    }
  }

  useEffect(() => {
    if (props.initialValues) {
      let colCopy = colours.slice();
      let matCopy = materials.slice();
      colCopy.map(
        (col) => (col.included = props.initialValues.items.includes(col.id))
      );
      matCopy.map(
        (mat) => (mat.included = props.initialValues.items.includes(mat.id))
      );
      setColours(colCopy);
      setMaterials(matCopy);
    }
  }, []);

  return (
    <div className="add-company-form-container">
      {!formComplete ? (
        <form className="form-elements" onSubmit={submitHandler}>
          <div className="button-title-flex">
            <h5 className="add-company-form-title">Colors & Materials Group</h5>
            <Button
              type="button"
              onClick={() => {
                props.setInitialValues(null);
                props.showElement(false);
              }}
            >
              <CloseRounded></CloseRounded>
            </Button>
          </div>
          <div className="group-form-flex">
            <div className="group-form-left">
              <div className="group-form-left-fields">
                <div className="short-input-group ncf-field">
                  <label className="form-label" htmlFor="name">
                    Area:
                  </label>
                  <select
                    name="areas"
                    className="short-input"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                  >
                    {props.group.type == "vanity" &&
                      [
                        { value: "sides", text: "Sides" },
                        { value: "front", text: "Front" },
                        { value: "accents", text: "Accents" },
                        { value: "benchtop", text: "Benchtop" },
                      ].map((option) => {
                        return (
                          <option
                            key={"vanity_area_" + option.value}
                            value={option.value}
                          >
                            {option.text}
                          </option>
                        );
                      })}
                    {props.group.type == "shower" &&
                      [{ value: "joinery", text: "Joinery" }].map((option) => {
                        return (
                          <option
                            key={"vanity_area_" + option.value}
                            value={option.value}
                          >
                            {option.text}
                          </option>
                        );
                      })}
                    {props.group.type == "mirror cabinet" &&
                      [
                        { value: "sides", text: "Sides" },
                        { value: "front", text: "Front (mirror)" },
                        { value: "shelves", text: "Shelves" },
                      ].map((option) => {
                        return (
                          <option
                            key={"vanity_area_" + option.value}
                            value={option.value}
                          >
                            {option.text}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="short-input-group ncf-field">
                  <label className="form-label" htmlFor="name">
                    Code*:
                  </label>
                  <input
                    type="name"
                    name="code"
                    className={error.code ? "error short-input" : "short-input"}
                    value={code}
                    onChange={(e) => {
                      if (e.target.value.length < 1) {
                        setError({ code: true });
                      }
                      setCode(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="group-form-left-items">
                <div className="short-input-group">
                  <label className="form-label" htmlFor="name">
                    Included in group:
                  </label>
                </div>
                <div className="group-form-left-items-selected">
                  {materials.map((material, index) => {
                    return (
                      <div
                        key={"material_included" + material.id}
                        style={{ display: !material.included && "none" }}
                        className="group-material-colour-item"
                        onClick={() => {
                          let matCopy = materials.slice();
                          matCopy[index].included = false;
                          setMaterials(matCopy);
                        }}
                      >
                        <FirebaseImage
                          path={material.imageUrl}
                          styleForImg={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="itemCheck">
                          <Cancel style={{ height: "50px", width: "50px" }} />
                        </div>
                        <label className="form-label" htmlFor="name">
                          {material.name}
                        </label>
                      </div>
                    );
                  })}
                  {colours.map((colour, index) => {
                    return (
                      <div
                        key={"colour_included" + colour.id}
                        style={{ display: !colour.included && "none" }}
                        className="group-material-colour-item"
                        onClick={() => {
                          let colCopy = colours.slice();
                          colCopy[index].included = false;
                          setColours(colCopy);
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#" + colour.hex,
                            height: "100px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="itemCheck">
                          <Cancel style={{ height: "50px", width: "50px" }} />
                        </div>
                        <label className="form-label" htmlFor="name">
                          {colour.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="group-form-right">
              <div className="group-form-right-search">
                <div className="short-input-group ncf-field">
                  <label className="form-label" htmlFor="name">
                    Search:
                  </label>
                  <input
                    type="name"
                    name="search"
                    className="short-input"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    style={{ borderRadius: "50px" }}
                  />
                </div>
                <div className="group-form-right-search-button-container">
                  <button
                    className="add-company-button"
                    type="submit"
                    style={{ width: "150px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="group-form-right-items">
                <div className="short-input-group">
                  <label className="form-label" htmlFor="name">
                    Available:
                  </label>
                </div>
                <div className="group-form-right-items-available">
                  {materials.map((material, index) => {
                    return (
                      <div
                        key={"material_available" + material.id}
                        style={{ display: material.included && "none" }}
                        className="group-material-colour-item"
                        onClick={() => {
                          let matCopy = materials.slice();
                          matCopy[index].included = true;
                          setMaterials(matCopy);
                        }}
                      >
                        <FirebaseImage
                          path={material.imageUrl}
                          styleForImg={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="itemCheck">
                          <CheckCircle
                            style={{ height: "50px", width: "50px" }}
                          />
                        </div>
                        <label className="form-label" htmlFor="name">
                          {material.name}
                        </label>
                      </div>
                    );
                  })}
                  {colours.map((colour, index) => {
                    return (
                      <div
                        key={"colour_available" + colour.id}
                        style={{ display: colour.included && "none" }}
                        className="group-material-colour-item"
                        onClick={() => {
                          let colCopy = colours.slice();
                          colCopy[index].included = true;
                          setColours(colCopy);
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#" + colour.hex,
                            height: "100px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="itemCheck">
                          <CheckCircle
                            style={{ height: "50px", width: "50px" }}
                          />
                        </div>
                        <label className="form-label" htmlFor="name">
                          {colour.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div id="material-added-flex">
          <h5 className="add-company-form-title">Code Added</h5>
          <Button
            type="button"
            onClick={() => {
              let colCopy = colours;
              let matCopy = materials;
              colCopy.map((col) => delete col.included);
              matCopy.map((mat) => delete mat.included);
              setColours(colCopy);
              setMaterials(matCopy);
              props.showElement(false);
            }}
          >
            Complete<CheckRounded></CheckRounded>
          </Button>
        </div>
      )}
    </div>
  );
}
