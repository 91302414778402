import { firestore } from "../../../firebase";
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";

/**
 * Get all products for a given company
 * 
 * @param {*} companyId - The companies ID
 * @returns { products: [], isEmpty: boolean }
 */
const getAllProducts = async (companyId) => {
    const productsCollection = collection(firestore, "Products")
    const productsQuery = query(productsCollection, where("company", "==", companyId))

    var resultSnapshot;

    try {
        resultSnapshot = await getDocs(productsQuery)
    } catch (err) {
        throw err;
    }

    if (resultSnapshot.empty) {
        return {
            isEmpty: true,
            products: []
        }
    }

    var _products = []

    resultSnapshot.docs.forEach(productDoc => {
        const data = productDoc.data()
        _products.push(data)
    });

    return {
        isEmpty: false,
        products: _products
    }
}

const getFeaturedProductIDs = async (companyID) => {
    const companyRef = doc(firestore, "Companies", companyID + "/info/public")
    var companySnapshot;

    try {
        companySnapshot = await getDoc(companyRef);
    } catch (err) {
        throw err
    }

    if (!companySnapshot.exists) {
        return []
    }

    const data = companySnapshot.data()

    if (data.featured_products !== undefined && Array.isArray(data.featured_products)) {
        return data.featured_products
    }

    return []
}

const setFeaturedProductsForCompany = async (companyID, featuredProductIDs) => {
    const companyRef = doc(firestore, "Companies", companyID + "/info/public")
    
    try {
        await updateDoc(companyRef, {
            featured_products: featuredProductIDs
        })
    } catch (err) {
        throw err;
    }
}

/**
 * Filter to products that aren't in the featured products arr
 * 
 * @param {*} products - input array
 * @param {*} featuredIDs - arr of featured IDs
 * @param {*} limit - (optional) max num of results to return
 * @returns 
 */
const filterProductsNotInFeatured = (products, featuredIDs, limit = -1) => {
    const filtered = products.filter(product => !featuredIDs.includes(product.id) && product.visible)

    if (limit > 0) {
        return filtered.slice(0, limit)
    }

    return filtered
}

/**
 * Filter products that match the search query and aren't in exclusion list
 * 
 * @param {*} query - Input query
 * @param {*} products - Input products
 * @param {*} featuredIDs - Exlusion list
 * @param {*} limit - (optional) Limit
 */
const searchProducts = (query, products, featuredIDs, limit = -1) => {
    const normaliseString = (str) => {
        return str.toUpperCase()
    }

    const _query = normaliseString(query)
    const _products = products.filter(product => 
        normaliseString(product.details.productname).includes(_query)
    )

    if (limit > 0) {
        return _products.slice(0, limit)
    } 

    return _products
}


export { getAllProducts, filterProductsNotInFeatured, searchProducts, setFeaturedProductsForCompany, getFeaturedProductIDs }