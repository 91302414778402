import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import {
  ArchiveRounded,
  DeleteOutlineRounded,
  FilterListRounded,
  UnarchiveRounded,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./TeamTable.css";

function descendingComparator(a, b, orderBy) {
  if (!orderBy.includes(".")) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    const splitOrder = orderBy.split(".");
    const aParent = a[splitOrder[0]];
    const bParent = b[splitOrder[0]];


    if (bParent[splitOrder[1]] < aParent[splitOrder[1]]) {
      return -1;
    }
    if (bParent[splitOrder[1]] > aParent[splitOrder[1]]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    selectable = false,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key="headRow">
        {headCells.map((headCell) => (
          <TableCell
            className="table-subheading-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className="table-subheading"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {selectable && (
          <TableCell
            key="headCheckbox"
            className="table-subheading-border"
            padding="checkbox"
          >
            <Checkbox
              className="checkbox"
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className="product-table-heading"
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        props.archived ? (
          <div style={{ display: "flex" }}>
            <Tooltip title="Unarchive">
              <IconButton onClick={() => props.unarchiver()}>
                <UnarchiveRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => props.deleter()}>
                <DeleteOutlineRounded />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip title="Archive">
            <IconButton onClick={() => props.deleter()}>
              <ArchiveRounded />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListRounded />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  title,
  rows,
  columns,
  defaultOrder,
  deleteFunc,
  unarchiveFunc,
  archived,
  selectable = true,
  useSavedSort = false,
  ...props
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrder);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // const updateTimer = React.useRef(null)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    
    if (useSavedSort && ["details.productname", "inventory.sku"].includes(property)) {
      window.localStorage.setItem("product_default_sortby", property)
      window.localStorage.setItem("product_table_sortorder", isAsc ? "desc" : "asc")  
    }
  };

  React.useEffect(() => {
    if (!useSavedSort) return;

    const defaultSortBy = window.localStorage.getItem("product_default_sortby")
    const defaultSortOrder = window.localStorage.getItem("product_table_sortorder")

    if (!["details.productname", "inventory.sku"].includes(defaultSortBy)) return;

    if (defaultSortBy && defaultSortBy !== "") {
      setOrderBy(defaultSortBy)
    }

    if (defaultSortOrder && ["asc", "desc"].includes(defaultSortOrder)) {
      setOrder(defaultSortOrder)
    } 
  }, [])

  const handleSelectAllClick = (event) => {
    if (!selectable) return;

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, id) => {
    if (!selectable) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const stylePagenation = {
    fontSize: 14,
    color: "#70839C",
    textAlign: "center",
    fontFamily: "Montserrat",
  };

  function deleter() {
    deleteFunc(selected);
  }

  function unarchiver() {
    unarchiveFunc(selected);
    // console.log(unarchiveFunc)
  }

  React.useEffect(() => {
    setSelected(props.selectedItems);
  }, [props.selectedItems]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper className="product-table" sx={{ width: "100%" }}>
        <EnhancedTableToolbar
          archived={archived}
          deleter={deleter}
          unarchiver={unarchiver}
          numSelected={selected.length}
          title={title}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
              selectable={selectable}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id + "_row"}
                      selected={isItemSelected}
                    >
                      {columns.map((column) => column.cell(row))}
                      {selectable && (
                        <TableCell
                          key={row.id + "_checkbox"}
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            className="checkbox"
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  key={emptyRows + "_empty"}
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={stylePagenation}
          className="table-pagenation"
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <div className="dense-padding-flex">
          <FormControlLabel
            sx={{ marginBottom: "0.55rem" }}
            className="dense-padding"
            align="left"
            label={
              <Typography className="dense-padding">Squeeze Table</Typography>
            }
            control={<Switch checked={dense} onChange={handleChangeDense} />}
          />
        </div>
      </Paper>
    </Box>
  );
}
