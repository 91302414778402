import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import Select from "react-select";
import { useState } from "react";
import { stylesForSelect } from "../../../styles/select";

export default function ProductCompany({
  submitting,
  setStatus,
  initialValues,
  companies,
  style,
  setCompanyID
}) {
  const [company, setCompany] = useState(initialValues);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (submitting) {
      setStatus(true, "company", company);
    }
  }, [submitting]);
  useEffect(() => {
    let optionList = [];

    if (!companies) return;

    for (let index = 0; index < companies.length; index++) {
      const comp = companies[index];
      optionList.push({ value: comp.id, label: comp.trading_name });
    }
    setOptions(optionList);
  }, []);

  useEffect(() => {
    let optionList = [];

    if (!companies) return;

    for (let index = 0; index < companies.length; index++) {
      const comp = companies[index];
      optionList.push({ value: comp.id, label: comp.trading_name });
    }
    setOptions(optionList);
  }, [companies]);

  function handleSubmit(e) {
    e.preventDefault();
  }
  //   console.log(initialValues);

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Company </h6>
            <div className="select-title-flex">
              <Select
                styles={stylesForSelect()}
                onChange={(value) => {
                  console.log(value.value);
                  setCompany(value.value);

                  if (setCompanyID) setCompanyID(value.value);
                  //   formik.setFieldValue("company", value.value);
                }}
                options={options}
                value={options.find((x) => x.value === company)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
