import React, { useState } from "react";
import TopNav from "../components/topnav/TopNav";
import Sidebar from "../components/sidebar/Sidebar";
import UserSidebar from "../components/sidebar/UserSidebar";
import { useSelector } from "react-redux";
import PageTemplate from "../components/pageTemplate/pageTemplate";

function Error() {
  const user = useSelector((state) => state.user);
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );

  return (
    <PageTemplate>
      <div className="quick-buttons">
        <h1 className="display-title">Error</h1>
      </div>
      <div className="home-widgets">
        <h5>
          You might not have permission to access this page, or the page you are
          looking for may not exist.
        </h5>
      </div>
    </PageTemplate>
  );
}

export default Error;
