import {
    defaultDirection,
    defaultLocale,
    defaultColor,
    localeOptions,
    themeColorStorageKey,
    themeRadiusStorageKey,
  } from '../constants/defaultValues';

export const getDirection = () => {
    let direction = defaultDirection;
  
    try {
      if (localStorage.getItem('direction')) {
        const localValue = localStorage.getItem('direction');
        if (localValue === 'rtl' || localValue === 'ltr') {
          direction = localValue;
        }
      }
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
      direction = defaultDirection;
    }
    return {
      direction,
      isRtl: direction === 'rtl',
    };
  };