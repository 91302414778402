import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { addDocument, updateDocument } from "../../../firebase";
import { uploadFile } from "../../../helpers/product_utils";
import FileUploader from "../files/ImageUploader";
import "./NewColourMaterialForm.css";

export default function NewMaterialForm(props) {
  const [name, setName] = useState(
    props.initialValues ? props.initialValues.name : ""
  );
  const [code, setCode] = useState(
    props.initialValues ? props.initialValues.code : ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    name: false,
    code: false,
    logo: false,
  });
  const [logo, setLogo] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [endMessage, setEndMessage] = useState("Material Added");
  const validate = {
    name: name.length < 1,
    code: code.length < 1,
    logo: !logo,
  };
  function handleChange(name, value) {
    if (name == "name") {
      setName(value);
      if (value.length < 1) {
        setError({ ...error, name: true });
      } else {
        setError({ ...error, name: false });
      }
    } else if (name == "code") {
      setCode(value);
      if (value.length < 1) {
        setError({ ...error, code: true });
      } else {
        setError({ ...error, code: false });
      }
    }
  }

  function checkErrors() {
    return new Promise((resolve, reject) => {
      setLoading(true);
      let allow = true;
      const errorcopy = error;
      for (const key in validate) {
        if (validate[key] == true) {
          switch (key) {
            case "name":
              errorcopy.name = true;
              break;
            case "code":
              errorcopy.code = true;
              break;
            case "logo":
              errorcopy.logo = true;
              break;
          }

          allow = false;
        }
      }
      setError(errorcopy);
      resolve(allow);
    });
  }

  async function submitHandler(e) {
    e.preventDefault();
    const validate = await checkErrors();
    if (validate) {
      const materialUrl =
        "materials/" + code + Math.random().toString().slice(2, 11);
      const image = await uploadFile(materialUrl, logo).then(async (value) => {
        const data = {
          name: name,
          code: code,
          date_added: new Date(),
          imageUrl: materialUrl,
        };
        if (!props.initialValues) {
          await addDocument(
            "Companies/" + props.company.id + "/materials",
            data
          ).then((result) => {
            console.log(result);
          });
        } else {
          data.id = props.initialValues.id;
          await updateDocument(
            "Companies/" + props.company.id + "/materials/" + data.id,
            data
          ).then((result) => {
            console.log(result);
            props.setInitialValues(null);
            setEndMessage("Colour Edited");
          });
        }
      });

      setFormComplete(true);
      setLoading(false);
    } else {
      console.log("Errors");
    }
  }

  useEffect(() => {
    if (props.initialValues) {
      const storage = getStorage();
      getDownloadURL(ref(storage, props.initialValues.imageUrl))
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          setLogo(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    }
  }, []);

  return (
    <div className="add-company-form-container">
      {!formComplete ? (
        <form className="form-elements" onSubmit={submitHandler}>
          <div className="button-title-flex">
            <h5 className="add-company-form-title">Add New Material</h5>
            <Button
              type="button"
              onClick={() => {
                props.showElement(false);
                props.setInitialValues(null);
              }}
            >
              <CloseRounded></CloseRounded>
            </Button>
          </div>
          <div className="form-input-style">
            <div
              className="short-input-style"
              style={{ paddingRight: "2.23rem" }}
            >
              {/* <ImageUploader initialFile={imageUrl} setFile={setImage} /> */}
              <div className="short-input-group" style={{ width: "100px" }}>
                <span className="product-title"> Image: </span>
                <FileUploader
                  initialFile={logo}
                  setFile={(value) => setLogo(value)}
                  buttonStyle={{ height: "80px", width: "80px", objectFit: 'contain' }}
                  iconStyle={{
                    height: "80px",
                    width: "80px",
                    color: error.logo ? "ffddd6" : "rgba(12, 113, 195, 0.55)",
                  }}
                ></FileUploader>
              </div>
              <div className="short-input-group ncf-field">
                <label className="form-label" htmlFor="name">
                  Name:
                </label>
                <input
                  type="name"
                  name="name"
                  className={error.name ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={name}
                />
              </div>
              <div className="short-input-group ncf-field">
                <label className="form-label" htmlFor="code">
                  Code:
                </label>
                <input
                  type="name"
                  name="code"
                  className={error.code ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("code", e.target.value)}
                  value={code}
                />
              </div>
            </div>
            <div className="button-flex">
              <div className="button-div">
                <button
                  className="form-button-submit"
                  disabled={loading}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div id="material-added-flex">
          <h5 className="add-company-form-title">{endMessage}</h5>
          <Button type="button" onClick={() => props.showElement(false)}>
            Complete<CheckRounded></CheckRounded>
          </Button>
        </div>
      )}
    </div>
  );
}
