import React, {useState} from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import './ColourPicker.css';

function ColourPicker({color, setColor}) {

    return (
        <div className='colour-container'>
            <div className="small-colour">
                <HexColorPicker className="responsive-colour" color={color} onChange={setColor} />
            </div>
            <div className="colour-input-container">
                <HexColorInput color={color} onChange={setColor} placeholder='Type your colour' className="colour-input" prefix='#'/>
            </div>
        </div>
    )
}

export default ColourPicker
