import React, { useState } from "react";
import "./CompanyOnboardingForm.css";
import "./CompanyBrandingForm.css";
import FileUploader from "../files/ImageUploader";

function CompanyEnabledProductsForm({ formik }) {

  console.log(formik.values.enabledProductTypes)
  return (
    <div className="company-onboarding-container">
      <div className="onboarding-form">
        <div className="left-items">
          <h6 className="section-heading"> Indoor Product Types </h6>
          <p className="onboarding-subheading">
            Select from our available indoor product types to enable them for your account.
          </p>

          <label className="checkbox-container">Tiles
            <input type="checkbox" name="enabledProductTypes.tile" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.tile} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>

          <label className="checkbox-container">Paints
            <input type="checkbox" name="enabledProductTypes.paint" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.paint} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>

          <label className="checkbox-container">Vanities
            <input type="checkbox" name="enabledProductTypes.vanity" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.vanity} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>

          <label className="checkbox-container">Basins
            <input type="checkbox" name="enabledProductTypes.basin" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.basin} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="right-items">
          <h6 className="section-heading"> Outdoor Product Types </h6>
          <p className="onboarding-subheading">
            Select from our available outdoor product types to enable them for your account.
          </p>

          <label className="checkbox-container">Pavers
            <input type="checkbox" name="enabledProductTypes.paver" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.paver} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>

          <label className="checkbox-container">Paints
            <input type="checkbox" name="enabledProductTypes.paint" onChange={formik.handleChange} checked={formik.values.enabledProductTypes.paint} onBlur={formik.handleBlur}/>
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default CompanyEnabledProductsForm;
