import * as React from "react";
import { TableCell, IconButton, Button } from "@mui/material";
import { VisibilityRounded, EditRounded } from "@mui/icons-material";
import "./ProductTable.css";
import BaseTable from "./BaseTable";
import Image from "../../images/products/tile1.jpg";

const styleForButton = {
  width: "auto",
  height: "1.88rem",
  color: "#01365B",
  backgroundColor: "rgba(12, 113, 195, 0.23)",
  border: "1.23px solid rgba(1, 54, 91, 0.44)",
  borderRadius: "23px",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "5px",
};

function createData(image, product, obj, sizes) {
  return {
    image,
    product,
    obj,
    sizes,
  };
}

const rows = [createData(Image, "Marble Tile", 2300, 5)];

const cells = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
    cell: (product) => (
      <TableCell align="center" className="table-cell">
        <img src={product.image} alt="Logo" style={styleForImg} />
      </TableCell>
    ),
  },
  {
    id: "product",
    numeric: false,
    disablePadding: true,
    label: "Product",
    cell: (product) => (
      <TableCell align="center" className="table-cell">
        {product.product}
      </TableCell>
    ),
  },
  {
    id: "obj",
    numeric: true,
    disablePadding: false,
    label: "Objects",
    cell: (product) => (
      <TableCell align="center" className="table-cell">
        {product.obj}
      </TableCell>
    ),
  },
  {
    id: "sizes",
    numeric: true,
    disablePadding: false,
    label: "Sizes",
    cell: (product) => (
      <TableCell
        className="table-cell"
        component="th"
        id={product.sizes}
        scope="row"
        padding="none"
        align="center"
      >
        {product.sizes}
      </TableCell>
    ),
  },
  {
    id: "visible",
    numeric: true,
    disablePadding: false,
    label: "Visible",
    cell: (product) => (
      <TableCell align="center" className="table-cell">
        <IconButton style={styleForIconButton}>
          <VisibilityRounded />
        </IconButton>
      </TableCell>
    ),
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
    cell: (product) => (
      <TableCell align="center" className="table-cell">
        <IconButton style={styleForIconButton}>
          <EditRounded />
        </IconButton>
      </TableCell>
    ),
  },
];

function ProductTypeTable({ products }) {
  return (
    <BaseTable
      title="Product Types"
      rows={rows}
      columns={cells}
      defaultOrder={"company"}
      selectedItems={[]}
    ></BaseTable>
  );
}

export default ProductTypeTable;
