import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { addDocument, updateDocument } from "../../../firebase";
import ColourPicker from "../../colour/ColourPicker";
import "./NewColourMaterialForm.css";

export default function NewColourForm(props) {  
  const [name, setName] = useState(
    props.initialValues ? props.initialValues.name : ""
  );
  const [code, setCode] = useState(
    props.initialValues ? props.initialValues.code : ""
  );
  const [hex, setHex] = useState(
    props.initialValues ? props.initialValues.hex : "000000"
  );
  const [r, setR] = useState(props.initialValues ? props.initialValues.R : 0);
  const [g, setG] = useState(props.initialValues ? props.initialValues.G : 0);
  const [b, setB] = useState(props.initialValues ? props.initialValues.B : 0);
  const [opacity, setOpacity] = useState(
    props.initialValues && props.initialValues.opacity
      ? props.initialValues.opacity
      : 100
  );
  const [endMessage, setEndMessage] = useState("Colour Added");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    name: false,
    code: false,
    hex: false,
    r: false,
    g: false,
    b: false,
    opacity: false,
  });
  const [colourPickerDisplay, setColourPickerDisplay] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  var hexRegex = /([0-9]|[a-f]|[A-F])/;
  const validate = {
    name: name.length < 1,
    code: code.length < 1,
    hex: hex.length !== 6 || !hexRegex.test(hex),
    r: r > 255 || r < 0,
    g: g > 255 || g < 0,
    b: b > 255 || b < 0,
    opacity: opacity < 0 || opacity > 100,
  };
  function handleChange(name, value) {
    if (name == "name") {
      setName(value);
      if (value.length < 1) {
        setError({ ...error, name: true });
      } else {
        setError({ ...error, name: false });
      }
    } else if (name == "code") {
      setCode(value);
      if (value.length < 1) {
        setError({ ...error, code: true });
      } else {
        setError({ ...error, code: false });
      }
    } else if (name == "hex") {
      setHex(value.slice(1));
      const rgb = hexToRgb(value);
      if (rgb) {
        setR(rgb.r);
        setG(rgb.g);
        setB(rgb.b);
      }
      if (value.length !== 7 || !hexRegex.test(value)) {
        setError({ ...error, hex: true });
      } else {
        setError({ ...error, hex: false });
      }
    } else if (name == "r") {
      setR(value);
      setHex(rgbToHex(value, g, b));
      if (value > 255 || value < 0) {
        setError({ ...error, r: true });
      } else {
        setError({ ...error, r: false });
      }
    } else if (name == "g") {
      setG(value);
      setHex(rgbToHex(r, value, b));
      if (value > 255 || value < 0) {
        setError({ ...error, g: true });
      } else {
        setError({ ...error, g: false });
      }
    } else if (name == "b") {
      setB(value);
      setHex(rgbToHex(r, g, value));
      if (value > 255 || value < 0) {
        setError({ ...error, b: true });
      } else {
        setError({ ...error, b: false });
      }
    } else if (name == "opacity") {
      setOpacity(value);
      if (value > 100 || value < 0) {
        setError({ ...error, b: true });
      } else {
        setError({ ...error, b: false });
      }
    }
  }

  function checkErrors() {
    return new Promise((resolve, reject) => {
      setLoading(true);
      let allow = true;
      const errorcopy = error;
      for (const key in validate) {
        if (validate[key] == true) {
          switch (key) {
            case "name":
              errorcopy.name = true;
              break;
            case "code":
              errorcopy.code = true;
              break;
            case "hex":
              errorcopy.hex = true;
              break;
            case "r":
              errorcopy.r = true;
              break;
            case "g":
              errorcopy.g = true;
              break;
            case "b":
              errorcopy.b = true;
              break;
            case "opacity":
              errorcopy.opacity = true;
              break;
          }
          allow = false;
        }
      }
      setError(errorcopy);
      resolve(allow);
    });
  }

  async function submitHandler(e) {
    e.preventDefault();
    const validate = await checkErrors();
    setLoading(false);
    if (validate) {
      let data = {
        name: name,
        code: code,
        hex: hex,
        R: parseInt(r),
        G: parseInt(g),
        B: parseInt(b),
        opacity: parseInt(opacity),
        date_added: new Date(),
      };
      if (!props.initialValues) {
        await addDocument(
          "Companies/" + props.company.id + "/colours",
          data
        ).then((result) => {
          console.log(result);
        }).catch(err => console.error(err));
      } else {
        data.id = props.initialValues.id;
        await updateDocument(
          "Companies/" + props.company.id + "/colours/" + data.id,
          data
        ).then((result) => {
          console.log(result);
          props.setInitialValues(null);
          setEndMessage("Colour Edited");
        });
      }
      setFormComplete(true);
    } else {
      console.log("Errors");
    }
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return (
      componentToHex(parseInt(r)) +
      componentToHex(parseInt(g)) +
      componentToHex(parseInt(b))
    );
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  return (
    <div className="add-company-form-container">
      {!formComplete ? (
        <form className="form-elements" onSubmit={submitHandler}>
          <div className="button-title-flex">
            <h5 className="add-company-form-title">Add New Colour</h5>
            <Button
              type="button"
              onClick={() => {
                props.showElement(false);
                props.setInitialValues(null);
              }}
            >
              <CloseRounded></CloseRounded>
            </Button>
          </div>
          <div className="form-input-style">
            <div
              className="short-input-style"
              style={{ paddingRight: "2.23rem" }}
            >
              {/* <ImageUploader initialFile={imageUrl} setFile={setImage} /> */}
              <div className="short-input-group ncf-field">
                <label className="form-label" htmlFor="name">
                  Name:
                </label>
                <input
                  type="name"
                  name="name"
                  className={error.name ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={name}
                />
              </div>
              <div className="short-input-group ncf-field">
                <label className="form-label" htmlFor="code">
                  Code:
                </label>
                <input
                  type="name"
                  name="code"
                  className={error.code ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("code", e.target.value)}
                  value={code}
                />
              </div>
              <div className="short-input-group ncf-hex-field">
                <label className="form-label" htmlFor="hex">
                  Hex:
                </label>
                <input
                  type="name"
                  name="hex"
                  className={error.hex ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("hex", e.target.value)}
                  value={"#" + hex}
                />
              </div>
              <div className="short-input-group ncf-rgb-field">
                <label className="form-label" htmlFor="r">
                  R:
                </label>
                <input
                  type="number"
                  name="r"
                  className={error.r ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("r", e.target.value)}
                  value={r}
                />
              </div>
              <div className="short-input-group ncf-rgb-field">
                <label className="form-label" htmlFor="g">
                  G:
                </label>
                <input
                  type="number"
                  name="g"
                  className={error.g ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("g", e.target.value)}
                  value={g}
                />
              </div>
              <div className="short-input-group ncf-rgb-field">
                <label className="form-label" htmlFor="b">
                  B:
                </label>
                <input
                  type="number"
                  name="b"
                  className={error.b ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("b", e.target.value)}
                  value={b}
                />
              </div>
              <div className="short-input-group ncf-rgb-field">
                <label className="form-label" htmlFor="b">
                  Opacity(%):
                </label>
                <input
                  type="number"
                  name="opacity"
                  className={
                    error.opacity ? "error short-input" : "short-input"
                  }
                  onChange={(e) => handleChange("opacity", e.target.value)}
                  value={opacity}
                />
              </div>
            </div>
            <div className="button-flex">
              <div className="button-div">
                <HexColorPicker
                  style={{ display: !colourPickerDisplay && "none" }}
                  className="ncf-picker"
                  color={"#" + hex}
                  onChange={(color) => {
                    setHex(color.slice(1));
                  }}
                />
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#" + hex,
                    marginRight: "10px",
                    borderRadius: "5px",
                  }}
                  onClick={() => setColourPickerDisplay(!colourPickerDisplay)}
                />
                <button
                  className="form-button-submit"
                  disabled={loading}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div id="material-added-flex">
          <h5 className="add-company-form-title">{endMessage}</h5>
          <Button type="button" onClick={() => props.showElement(false)}>
            Complete<CheckRounded></CheckRounded>
          </Button>
        </div>
      )}
    </div>
  );
}
