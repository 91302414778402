// regexes
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numberPattern = /^\d+$/;

// Validation conditions
export const notEmpty = ((value) => {return value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)});
export const emailFormat = ((value) => {return !!value.match(emailPattern) || value === ""});
export const numberFormat = ((value) => {return !!value.match(numberPattern)});
export const areTheSame = ((value1, value2) => {return value1 === value2});
export const withMinLength = ((value, minLength) => {return value.length >= minLength});
export const withMaxLength = ((value, maxLength) => {return value.length <= maxLength});

// Check error function
export function validateItem (conditions, parameters) {
    let isValid = true;
    conditions.forEach(condition => {
        isValid = isValid && !!condition(parameters);
    });
    return isValid;
}

// given an object containing form details and an object containing form conditions, validate that form
export function checkForm (formInfo, conditions, setError) {
    let formValid = true;
    let tempError = {};
    // iterate through objects and check validity of each
    for (const key of Object.keys(conditions)) {
        let fieldValid = validateItem(conditions[key], formInfo[key]);
        tempError[key] = !fieldValid;
        formValid = formValid && fieldValid;
    }
    setError(tempError);
    return formValid;
}

export function formikValidate(values, submitting, component_key, setStatus, conditions, formik) {
    const result = validate(values, formik, conditions);
    if (result) {
        if(submitting){
            setStatus(false, component_key, values);
        }
      return result;
    } else {
      return {};
    }
}

export function formikSubmit(values, formik, setStatus, component_key, conditions) {
    const result = validate(values, formik, conditions);
    if (result) {
        formik.setErrors(result);
        setStatus(false, component_key, values);
    } else {
        setStatus(true, component_key, values);
    }
}

function validate (values, formik, conditions) {
    let errors = {};
    let toCheck = {};
    for (const key of Object.keys(formik.touched)) {
        toCheck[key] = conditions[key] || [];
    }
    if(checkForm(values, toCheck, (result) => {errors = result;})) {
        return false;
    };
    return errors;
}