import * as React from "react";
import { TableCell, IconButton, Button } from "@mui/material";
import { VisibilityRounded, VisibilityOffRounded } from "@mui/icons-material";
import "./ProductTable.css";
import BaseTable from "./BaseTable";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteDocument, setDocument, updateDocument } from "../../firebase";
import SearchBar from "../filters/SearchBar";
import TableSearch from "../filters/TableSearch";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

const styleForIconButton = (disabled) => {
  return {
    width: "2.3rem",
    height: "2.3rem",
    borderRadius: "2.3rem",
    color: disabled ? "lightgrey" : "#01365B",
  };
};

const styleForButton = {
  width: "auto",
  height: "1.88rem",
  color: "#01365B",
  backgroundColor: "rgba(12, 113, 195, 0.23)",
  border: "1.23px solid rgba(1, 54, 91, 0.44)",
  borderRadius: "23px",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForStatusButton = (status) => {
  let backgroundColor = "darkgrey";
  switch (status) {
    case "live":
      backgroundColor = "#01365B";
      break;
    case "processing":
      backgroundColor = "#0C71C3";
      break;
    case "draft":
      backgroundColor = "#70a6cc";
      break;
    case "error":
      backgroundColor = "red";
      break;
  }
  return {
    width: "auto",
    height: "1.88rem",
    color: "#FFFFFF",
    backgroundColor: backgroundColor,
    borderRadius: "23px",
    fontSize: "12px",
  };
};

function ProductTable(props) {
  const [filteredRows, setFilteredRows] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);

  function viewProduct(product) {
    navigate("/products/view/" + product.id);
  }

  function toggleVisibility(product) {
    updateDocument("Products/" + product.id, { visible: !product.visible });
  }

  const cells = [
    {
      id: "company",
      numeric: false,
      disablePadding: true,
      label: "Company",
      cell: (product) => (
        <TableCell
          key={product.id + "_company"}
          align="center"
          className="table-cell"
        >
          {props.companies.find((company) => company.id === product.company)
            ? props.companies.find((company) => company.id === product.company)
                .trading_name
            : ""}
        </TableCell>
      ),
    },
    {
      id: "details.productname",
      numeric: true,
      disablePadding: false,
      label: "Product",
      cell: (product) => (
        <TableCell
          key={product.id + "_name"}
          align="center"
          className="table-cell"
        >
          {product.details.productname}
        </TableCell>
      ),
    },
    {
      id: "views",
      numeric: true,
      disablePadding: false,
      label: "Views",
      cell: (product) => (
        <TableCell
          key={product.id + "_views"}
          align="center"
          className="table-cell"
        >
          {product.views !== undefined ? product.views : "N/A"}
        </TableCell>
      ),
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      cell: (product) => (
        <TableCell
          key={product.id + "_status"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForStatusButton(product.status)}>
            {product.status !== undefined ? product.status : "Unknown"}
          </Button>
        </TableCell>
      ),
    },
    {
      id: "visible",
      numeric: true,
      disablePadding: false,
      label: "Visible",
      cell: (product) => (
        <TableCell
          key={product.id + "_visible"}
          align="center"
          className="table-cell"
        >
          <IconButton
            disabled={product.status !== "live"}
            style={styleForIconButton(product.status !== "live")}
            onClick={() => toggleVisibility(product)}
          >
            {product.visible ? <VisibilityRounded /> : <VisibilityOffRounded />}
          </IconButton>
        </TableCell>
      ),
    },
    {
      id: "viewButton",
      numeric: true,
      disablePadding: false,
      label: "View Product",
      cell: (product) => (
        <TableCell
          key={product.id + "_view"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButton} onClick={() => viewProduct(product)}>
            View
          </Button>
        </TableCell>
      ),
    },
  ];

  useEffect(() => {
    if (props.company) {
      setFilteredRows(
        props.products.filter((row) => row.company === props.company)
      );
    } else {
      setFilteredRows(props.products);
    }
  }, [props]);

  async function deleteProducts() {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < selectedProducts.length; index++) {
        const product = selectedProducts[index];
        if (archived) {
          let productDoc = props.products.find((prod) => prod.id == product);
          await deleteDocument("/Products/" + product).then(async (result) => {
            productDoc.icon.icon
              .concat(
                [
                  ...productDoc.media.images,
                  productDoc.media.video,
                  productDoc.documents.manual,
                  productDoc.documents.installation,
                  productDoc.documents.returns,
                  productDoc.documents.warranty,
                ],
                productDoc.tile_variation && [
                  ...productDoc.tile_variation.images,
                ],
                productDoc.paver_variation && [
                  ...productDoc.paver_variation.images,
                ]
              )
              .map(async (file) => {
                await deleteFile(file).then((result2) => {
                  console.log(result, result2);
                });
              });
          });
        } else {
          let productDoc = props.products.find((prod) => prod.id == product);
          productDoc.archived = true;
          await setDocument("/Products/" + product, productDoc).then(
            (result) => {
              console.log(result);
            }
          );
        }
      }
      setShowModal(false);
      setSelectedProducts([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  async function unarchiveFunc(productList) {
    if (["actuality_admin", "company_admin"].includes(user.role)) {
      for (let index = 0; index < productList.length; index++) {
        const product = productList[index];
        let productDoc = props.products.find((prod) => prod.id == product);
        productDoc.archived = false;
        await setDocument("/Products/" + product, productDoc).then((result) => {
          console.log(result);
        });
      }
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  return (
    <div>
      {/* TABLE DISPLAYS ARCHIVED PRODUCTS */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          rows={filteredRows.filter(
            (row) =>
              (row.details &&
                row.details.productname
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                row.archived) ||
              (row.inventory &&
                row.inventory.sku
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                row.archived)
          )}
          columns={cells}
          defaultOrder={"company"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS NON-ARCHIVED PRODUCTS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          rows={filteredRows.filter(
            (row) =>
              (row.details &&
                row.details.productname
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                !row.archived) ||
              (row.inventory &&
                row.inventory.sku
                  .toLowerCase()
                  .includes(search.toLowerCase()) &&
                !row.archived)
          )}
          columns={cells}
          defaultOrder={"company"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Product" : "Archive Product"}
        message={
          archived
            ? "Are you sure you would like to delete this product?"
            : "Are you sure you would like to archive this product?"
        }
        confirm={deleteProducts}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Products" : "Show Archived Products"}
        </a>
      </div>
    </div>
  );
}

export default ProductTable;
