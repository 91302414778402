

const SingleAnaltyicCard = ({iconComponent, title, url, value}) => {
    return (
        <div className="analytics-card">
            {url ? <a href={url}>{iconComponent}</a> : iconComponent}

            <h2>{title}</h2>
            <p>{value || 0}</p>

            {/* {url && <a href={url}>View {title}</a>} */}
        </div>
    )
}

export default SingleAnaltyicCard;