// importing React libraries
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "./firebase";

// importing 'local project' components & pages
import UserDashboard from "./pages/user/UserDashboard";
import UserSettings from "./pages/user/UserSettings";
import UserProducts from "./pages/user/UserProducts";
import UserTeam from "./pages/user/UserTeam";
import UserLeads from "./pages/user/UserLeads";
import CompanyOnboarding from "./pages/user/CompanyOnboarding";
import AddProduct from "./pages/user/AddProduct";
import SetupError from "./pages/SetupError";
import ConfirmModal from "./components/modals/NavConfirmModal";

//Importing CSS Style Sheet
import "./App.css";

import { useSelector } from "react-redux";
import AddDeafault3D from "./pages/user/productTypes/AddDefault3D";
import AddWallpaper from "./pages/user/productTypes/AddWallpaper";
import UserSupportPage from "./pages/user/UserSupportPage";
import UserMaterials from "./pages/user/UserMaterials";
import UserColours from "./pages/user/UserColours";
import UserGroups from "./pages/user/UserGroups";
import CreateProduct from "./pages/user/createProduct/CreateProduct";
import PaymentSuccess from "./pages/payment/PaymetSuccess";
import PaymentError from "./pages/payment/PaymentError";
import ErrorBoundary from "./components/ErrorBoundary"
import RootErrorBoundary from "./components/RootErrorBoundary";
import AppSettings from "./pages/user/AppSettings/AppSettings";

function UserApp() {

  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [team, setTeam] = useState([]);
  const [invitedTeam, setInvitedTeam] = useState([]);
  const [productsQuery, setProductQuery] = useState(query(collection(firestore, "Products"), where("company", "==", company.id)));
  const resetProductQuery = () => { setProductQuery(query(collection(firestore, "Products"), where("company", "==", company.id))) };
  const [teamQuery, setTeamQuery] = useState(query(collection(firestore, 'Users'), where("company", "==", company.id)))
  const [invitedTeamQuery, setInvitedTeamQuery] = useState(query(collection(firestore, 'Pending Users'), where("companyId", "==", company.id)));


  //CONFIRMATION MODAL STUFF
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [modalVariable, setModalVariable] = useState(null)

  //Brings up modal to ask user if they are sure they would like to leave the page
  const confirmNavigation = (url, modVar) => {
    setDisplayConfirmModal(true);
    setModalUrl(url);
    if (modVar) {
      setModalVariable(modVar)
    }
  };

  // Get realtime product data
  function getData(dataQuery, setData) {
    return onSnapshot(
      dataQuery,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        console.log(items)
        setData(items);
      },
      (error) => {
        console.error(error)
      }
    );
  }

  useEffect(() => {
    let unsubscribe = getData(productsQuery, setProducts);
    return () => unsubscribe;
  }, [productsQuery]);

  useEffect(() => {
    let unsubscribe = getData(teamQuery, setTeam);
    return () => unsubscribe;
  }, [teamQuery]);

  useEffect(() => {
    let unsubscribe = getData(invitedTeamQuery, setInvitedTeam);
    return () => unsubscribe;
  }, [invitedTeamQuery]);

  return company && company.onboarded ? (
    <RootErrorBoundary>
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Navigate to="/user/dashboard" />} />
          <Route path="/home" element={<Navigate to="/user/dashboard" />} />
          <Route path="/user/dashboard" element={<UserDashboard products={products} setQuery={setProductQuery} resetQuery={resetProductQuery} />} />
          <Route path="/user/products" element={<UserProducts products={products} />} />
          <Route path="/user/products/add-product" element={<AddProduct products={products} type="user" formType="add" navigate={confirmNavigation} />} />
          <Route path="/user/products/edit/:productId" element={<AddProduct products={products} formType='edit' navigate={confirmNavigation} />} />
          <Route path="/user/leads" element={<UserLeads companyId={company.id} />} />
          <Route path="/user/appsettings" element={<AppSettings companyId={company.id} />} />
          <Route path="/user/team" element={<UserTeam navigate={confirmNavigation} teamMembers={team.concat(invitedTeam)} />} />
          <Route path="/user/settings" element={<UserSettings navigate={confirmNavigation} />} />
          <Route path="/user/settings/support" element={<UserSupportPage navigate={confirmNavigation} />} />
          <Route path="/user/settings/materials" element={<UserMaterials navigate={confirmNavigation} company={company} />} />
          <Route path="/user/settings/colours" element={<UserColours navigate={confirmNavigation} company={company} />} />
          <Route path="/user/settings/groups" element={<UserGroups navigate={confirmNavigation} company={company} />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/error" element={<PaymentError />} />
          <Route
            path="/user/settings/:extra"
            element={<UserSettings navigate={confirmNavigation} />}
          />
          <Route path="/*" element={<Navigate to="/error" />} />
          <Route
            path="/user/company-onboarding"
            element={<CompanyOnboarding />}
          />
        </Routes>
        <ConfirmModal display={displayConfirmModal} closeCallback={setDisplayConfirmModal} url={modalUrl} />
      </React.Fragment>
    </RootErrorBoundary>
  ) : user.role === "company_admin" ? (
    <React.Fragment>
      <Routes>
        <Route path="/*" element={<Navigate to="/user/company-onboarding" />} />
        <Route path="/user/company-onboarding" element={<CompanyOnboarding />} />
      </Routes>
      <ConfirmModal display={displayConfirmModal} closeCallback={setDisplayConfirmModal} url={modalUrl} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Routes>
        <Route path="/*" element={<Navigate to="/user/setup-error" />} />
        <Route path="/user/setup-error" element={<SetupError />} />
      </Routes>
      <ConfirmModal display={displayConfirmModal} closeCallback={setDisplayConfirmModal} url={modalUrl} />
    </React.Fragment>
  );
}

export default UserApp;
