import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import { logout as storeLogout } from '../redux/user/actions'
import { clear_company } from '../redux/company/actions';

// logout the user from firebase auth and the redux store
export async function logout(dispatch) {
  let result = {message: "Operation didn't perform"};
  // log the user out using firebase auth
  await signOut(auth).then(() => {
    // remove the user info from the redux store
    dispatch(storeLogout());
    dispatch(clear_company());
    result = {message : "You have been logged out"};
  }).catch(error => {
    result = {message : "An error occured"};
    console.log(error);
  });
  return result
};