import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import FileUploader from "../files/FileUploader";
import {
  withMinLength,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import ListFiles from "../files/ListFiles";
import { getStorage, ref, getBlob } from "firebase/storage";

const acceptedFileSizeMB = 10;
const acceptedFileSize = acceptedFileSizeMB * 1024 * 1024;
function TileVariationForm({ submitting, setStatus, filePaths }) {
  const component_key = "tile_variation";
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (filePaths) {
      const storage = getStorage();
      let files = [];
      filePaths.map((path, index) => {
        const fileRef = ref(storage, path);
        getBlob(fileRef)
          .then((file) => {
            files[index] = file;
            updateFiles("images", files);
          })
          .catch((error) => {
            console.log(error.message);
          });
      });
    }
  }, []);

  // images required for ui / ar
  const conditions = {
    images: [(value) => withMinLength(value, 5)],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: {
      images: [],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function uploadImages(files) {
    let acceptedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (
        ["image/png", "image/jpeg", "application/zip"].includes(file.type) &&
        file.size <= acceptedFileSize
      ) {
        acceptedFiles.push(file);
      } else {
        console.log("file rejected");
      }
    }
    let allFiles = formik.values.images.concat(acceptedFiles);
    formik.setFieldValue("images", allFiles);
    formik.setErrors(
      formikValidate(
        formik.values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      )
    );
    setImages(allFiles);
  }

  function updateFiles(fileType, data) {
    formik.setFieldValue(fileType, data);
    formik.setErrors(
      formikValidate(
        formik.values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      )
    );
    setImages([...data]);
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Tile Variation *</h6>
            <p className="onboarding-subheading"></p>

            <div className="form-elements-flex">
              <div className="left-elements">
                <p className="onboarding-subheading">
                  
                  To display your products correctly please supply at least 5
                  product images. Images should be as detailed as possible (jpg
                  or png).
                </p>
              </div>

              <div className="right-elements">
                <div
                  className={
                    formik.errors.images
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                  <FileUploader setFiles={uploadImages}></FileUploader>
                  <ListFiles
                    files={images}
                    setFiles={(data) => updateFiles("images", data)}
                  ></ListFiles>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TileVariationForm;
