import React, { useState, useEffect } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { Provider, ErrorBoundary as RBErrorBoundary, LEVEL_WARN } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '415a804a95da44e590cbc79c936fa718',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
};

export default class RootErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    return (
      <Provider config={rollbarConfig}>
        <RBErrorBoundary>
          {this.props.children}
        </RBErrorBoundary>
      </Provider>
    )
  }
}

const ErrorView = () => {
  return (
    <div className="p-10">
      <ErrorIcon sx={{ fontSize: '70px', marginBottom: '30px' }}></ErrorIcon>
      <h1>Something went wrong.</h1>
    </div>)
}