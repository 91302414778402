import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import {
  firestore,
} from "../../../firebase";
import { useState } from "react";
import {
  collection,
  onSnapshot,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import Select from "react-select";
import FirebaseImage from "../../media/FirebaseImage";
import { stylesForSelect } from "../../../styles/select";

export default function BasinMaterials({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "basin_materials";
  const conditions = {};
  const [colourOptions, setColourOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);

  const [groups, setGroups] = useState([]);

  const coloursRef = collection(
    firestore,
    "Companies/" + props.company.id + "/colours"
  );

  const materialsRef = collection(
    firestore,
    "Companies/" + props.company.id + "/materials"
  );

  const groupsRef = collection(
    firestore,
    "Companies/" + props.company.id + "/groups"
  );

  const formik = useFormik({
    initialValues: initialValues || {
      basinTop: [],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  // Get realtime data
  function getData(collectionRef, setData) {
    return onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setData(items);
      },
      (error) => {
        // TODO add error handling in here
        console.log(error.message);
      }
    );
  }

  // GETS COLOURS
  useEffect(() => {
    let unsubscribe = getData(coloursRef, setColourOptions);
    return () => unsubscribe();
  }, []);

  // GETS MATERIALS
  useEffect(() => {
    let unsubscribe = getData(materialsRef, setMaterialOptions);
    return () => unsubscribe();
  }, []);

  // GETS GROUPS
  useEffect(() => {
    let unsubscribe = getData(groupsRef, setGroups);
    return () => unsubscribe();
  }, []);

  // GETS GROUP CODES, DOESN"T WORK YET AS ALTHOUGH IT DOES GET THE CODES THEY DON'T APPEAR IN THE COMPONENT UNTIL SOMETHING TRIGGERS A RE-RENDER

  // useEffect(async () => {
  //   const querySnapshot = await getDocs(groupsRef);
  //   let newCodes = [];
  //   querySnapshot.forEach(async (doc) => {
  //     if (doc.data().type == "vanity") {
  //       onSnapshot(
  //         collection(
  //           firestore,
  //           "Companies/" +
  //             props.company.id +
  //             "/groups/" +
  //             doc.data().id +
  //             "/codes"
  //         ),
  //         (querySnapshot) => {
  //           querySnapshot.forEach((doc) => {
  //             // console.log(doc.data())
  //             newCodes.push(doc.data());
  //           });
  //           setCodeOptions(newCodes);
  //         },
  //         (error) => {
  //           // TODO add error handling in here
  //           console.log(error.message);
  //         }
  //       );
  //     }
  //   });
  //   // setCodeOptions(newCodes);
  // }, []);

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <span className="form-mini-title">Material & Colour Options</span>
            <p className="onboarding-subheading">
              Set up your standard colours and materials first, go to{" "}
              <Link to="/user/settings">settings</Link>
            </p>
            <div className="form-elements-flex">
              <span
                style={{
                  width: "26px",
                  paddingRight: "100px",
                  paddingTop: "5px",
                }}
                className="onboarding-subheading"
              >
                Top
              </span>
              <div style={{ flex: "1" }}>
                <Select
                  value={formik.values.basinTop}
                  styles={stylesForSelect(formik.errors.basinTop)}
                  isMulti={true}
                  options={colourOptions.concat(materialOptions).map((item) => {
                    // console.log(item)
                    return {
                      label: item.code,
                      value: item.code,
                      ...item,
                    };
                  })}
                  onChange={(value) => formik.setFieldValue("basinTop", value)}
                />
              </div>
              <div style={{ width: "200px", paddingLeft: "5px" }}>
                {formik.values.basinTop.map((item) => {
                  return item.hex ? (
                    <div
                      key={item.value}
                      style={{
                        width: "20px",
                        height: "20px",
                        display: "inline-block",
                        marginRight: "5px",
                        backgroundColor: "#" + item.hex,
                        verticalAlign: "middle",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <FirebaseImage
                      key={item.imageUrl}
                      styleForImg={{
                        width: "20px",
                        height: "20px",
                        display: "inline-block",
                        marginRight: "5px",
                        borderRadius: "5px",
                      }}
                      path={item.imageUrl}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
