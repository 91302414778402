import React, { useEffect, useState } from 'react';
import { FormikContext, useFormik } from 'formik';
import './styles/ProductFormStyles.css';
import FileUploader from '../files/FileUploader';
import { notEmpty, formikSubmit, formikValidate } from '../../../helpers/form_utils';
import ListFiles from '../files/ListFiles';
import { getStorage, ref, getBlob } from "firebase/storage";


const acceptedFileSizeMB = 20;
const acceptedFileSize = acceptedFileSizeMB * 1024 * 1024;
function ARInfo({ submitting, setStatus, initialValues, isAdmin }) {
    const component_key = 'ar_info';
    const [userFiles3d, setFiles3d] = useState([]);
    const [userImages, setImages] = useState([]);

    const conditions = {
        files3d: [notEmpty,],
        images: [notEmpty,],
    };

    useEffect(() => {
        if (initialValues) {
            const storage = getStorage();
            let files3d = [];
            initialValues.files3d.map((path, index) => {
                const fileRef = ref(storage, path);
                getBlob(fileRef).then(blob => {
                    var file = new File([blob], path.split('/').pop());
                    files3d[index] = file;
                    updateFiles('files3d', files3d, true);
                }).catch((error) => {
                    console.log(error.message);
                });
            })
            let images = [];
            initialValues.images.map((path, index) => {
                const fileRef = ref(storage, path);
                getBlob(fileRef).then(file => {
                    console.log(file);
                    images[index] = file;
                    updateFiles('images', images, true);
                }).catch((error) => {
                    console.log(error.message);
                });
            })
        }
    }, []);

    useEffect(() => {
        if (submitting) {
            formik.handleSubmit();
        }
    }, [submitting]);

    const formik = useFormik({
        initialValues: {
            height: initialValues ? initialValues.height : 0,
            width: initialValues ? initialValues.width : 0,
            length: initialValues ? initialValues.length : 0,
            files3d: [],
            images: [],
            approved: initialValues ? initialValues.approved : false,
        },

        validate: values => {
            return formikValidate(values, submitting, component_key, setStatus, conditions, formik);
        },

        onSubmit: values => {
            formikSubmit(values, formik, setStatus, component_key, conditions);
        },
    });

    function handleSubmit(e) {
        e.preventDefault();
    }

    function upload3dFiles(files) {
        let acceptedFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            if (['fbx', 'obj', 'gltf'].includes(file.name.split('.').pop().toLowerCase()) && (file.size <= acceptedFileSize)) {
                acceptedFiles.push(file);
            } else { console.log('file rejected') }
        }
        let allFiles = formik.values.files3d.concat(acceptedFiles);
        formik.setFieldValue('approved', false);
        formik.setFieldValue('files3d', allFiles);
        setFiles3d(allFiles);
    }

    function uploadImages(files) {
        let acceptedFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            if (['image/png', 'image/jpeg', 'application/zip'].includes(file.type) && (file.size <= acceptedFileSize)) {
                acceptedFiles.push(file);
            } else { console.log('file rejected') }
        }
        let allFiles = formik.values.images.concat(acceptedFiles);
        formik.setFieldValue('images', allFiles);
        setImages(allFiles);
    }

    function updateFiles(fileType, data, loadingFile) {
        formik.setFieldValue(fileType, data);
        if (fileType === 'files3d') {
            if (!loadingFile) {
                formik.setFieldValue('approved', false);
            }
            setFiles3d(data);
        } else if (fileType === 'images') {
            setImages(data);
        }
    }

    return (
        <div className='company-onboarding-container'>
            <form onSubmit={handleSubmit}>
                <div className="form-block">
                    <div className="form-items">
                        <div className="mini-horizontal-flex">

                            <div className="select-title-flex">
                                <span className='form-mini-title'>Height</span>
                                <div className="input-adornment">
                                    <span className="adornment-text">mm</span>
                                    <input type="name" name="height" className="form-element-style-margin" onChange={formik.handleChange} value={formik.values.height} onBlur={formik.handleBlur} /></div></div>
                            <div className="select-title-flex">
                                <span className='form-mini-title'>Width</span>
                                <div className="input-adornment">
                                    <span className="adornment-text">mm</span>
                                    <input type="name" name="width" className="form-element-style-margin" onChange={formik.handleChange} value={formik.values.width} onBlur={formik.handleBlur} /></div></div>
                            <div className="select-title-flex">
                                <span className='form-mini-title'>Length</span>
                                <div className="input-adornment">
                                    <span className="adornment-text">mm</span>
                                    <input type="name" name="length" className="form-element-style-margin" onChange={formik.handleChange} value={formik.values.length} onBlur={formik.handleBlur} /></div></div>

                        </div>
                        <div className="form-underline-flex"></div>
                        <div className="mini-horizontal-flex">
                            <div className="select-title-flex">
                                <span className='form-mini-title'>Product *</span>
                                <p className='important-info-grey'> Add 3D file (FBX, OBJ, GLTF)</p>
                                <div>
                                    <div className={formik.errors.files3d ? 'error mini-horizontal-flex' : 'mini-horizontal-flex'}>
                                        <FileUploader setFiles={upload3dFiles}></FileUploader>
                                        <ListFiles files={userFiles3d} setFiles={(data) => updateFiles('files3d', data)}></ListFiles>
                                    </div>
                                    {isAdmin ?
                                        <div className="mini-horizontal-flex">
                                            <input type="checkbox" name="approved" className="checkbox" onChange={formik.handleChange} checked={formik.values.approved} onBlur={formik.handleBlur} />
                                            <h6 className='midi-heading'> Approve 3D files.</h6> </div>
                                        : ''}
                                </div>
                            </div>
                            <div className='select-title-flex'>
                                <span className='form-mini-title'>Material/ Colour Variant *</span>
                                <p className='important-info-grey'> Add image file(s) (png, jpg, zip)</p>
                                <div className={formik.errors.images ? 'error mini-horizontal-flex' : 'mini-horizontal-flex'}>
                                    <FileUploader setFiles={uploadImages}></FileUploader>
                                    <ListFiles files={userImages} setFiles={(data) => updateFiles('images', data)}></ListFiles>
                                </div>
                            </div>

                        </div>
                        <p className='onboarding-subheading'></p>
                        <p className='important-info-grey'> All AR files will be reviewed on submission before product can be approved.</p>


                    </div>


                </div>
            </form>
        </div>
    )
}

export default ARInfo

