import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
  numberFormat,
} from "../../../helpers/form_utils";
import { getDocument, setDocument } from "../../../firebase";
import { arrayUnion } from "firebase/firestore";
import { useState } from "react";
import Select from "react-select";
import { stylesForSelect } from "../../../styles/select";
import CreatableSelect from "react-select/creatable";

function TileTypeForm({ submitting, setStatus, initialValues, company, editingCompanyID }) {
  const component_key = "tile_type";
  const [options, setOptions] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const unit = company.weightSystem || "kgs";

  const [tileSlipOptions, setTileSlipOptions] = useState([
    { label: "1", value: "1" },
    { label: "2", value: "2" },
  ]);

  const [tileEdgeOptions, setTileEdgeOptions] = useState([
    { label: "Rectified", value: "rectified" },
    { label: "Smooth", value: "smooth" },
  ]);

  const [tileFinishOptions, setTileFinishOptions] = useState([
    { label: "Glossy", value: "glossy" },
    { label: "Smooth", value: "smooth" },
    { label: "Matt", value: "matte" },
  ]);

  const [tileMaterialOptions, setTileMaterialOptions] = useState([
    { label: "Stone", value: "stone" },
    { label: "Marble", value: "marble" },
    { label: "Porcelain", value: "porcelain" },
  ]);

  const [tileSealOptions, setTileSealOptions] = useState([
    { label: "Sealed", value: "sealed" },
    { label: "Unsealed", value: "unsealed" },
  ]);

  const conditions = {
    tileUse: [notEmpty],
    tileSize: [notEmpty],
  };

  useEffect(() => {
    getOptions(["tileSlip", "tileEdge", "tileFinish", "tileMaterial", "tileSeal"]);
  }, [companyId]);

  useEffect(() => {
    if (editingCompanyID && editingCompanyID !== '') {
      setCompanyId(editingCompanyID);
    } else {
      setCompanyId(company.id)
    }
  }, [company, editingCompanyID])

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      tileUse: "",
      tileSize: "",
      tileCount: 0,
      tileWastage: 0,
      m2PerBox: 0,
      tileEdge: "",
      tileShape: "",
      tileVar: 0,
      tileSlip: "",
      tileFinish: "",
      tileSeal: "",
      tileMaterial: "",
      tileWeight: 0,
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  function handleCreateOption(label, type, name) {
    const newOption = {
      label: label,
      value: label.toLowerCase().replace(/\W/g, ""),
    };
    // TODO add a way for users to remove categories?
    setDocument(
      "Companies/" + (companyId !== '' ? companyId : company.id) + "/configurations/" + type,
      {
        [type]: arrayUnion(newOption),
      },
      true
    ).then((result) => {
      console.log(result);
      getOptions([type])
    });

    formik.setFieldValue(name, newOption);
  }

  function getOptions(names) {
    getDocument("Configuration/tile-options").then((result) => {
      if (result.success) {
        setOptions(result.data);
      } else {
        console.log(result.message);
      }
    });

    names.forEach((name) => {
      getDocument(
        "Companies/" + (companyId !== '' ? companyId : company.id) + "/configurations/" + name
      ).then((result) => {
        if (result) {
          if (name === "tileSlip" && result.data.tileSlip) {
            setTileSlipOptions([
              { label: "1", value: "1" },
              { label: "2", value: "2" },
              ...result.data.tileSlip,
            ]);
          }
          if (name === "tileEdge" && result.data.tileEdge) {
            setTileEdgeOptions([
              { label: "Rectified", value: "rectified" },
              { label: "Smooth", value: "smooth" },
              ...result.data.tileEdge
            ])
          }
          if (name === "tileFinish" && result.data.tileFinish) {
            setTileFinishOptions([
              { label: "Glossy", value: "glossy" },
              { label: "Smooth", value: "smooth" },
              { label: "Matt", value: "matte" },
              ...result.data.tileFinish
            ])
          }
          if (name === "tileMaterial" && result.data.tileMaterial) {
            setTileMaterialOptions([
              { label: "Stone", value: "stone" },
              { label: "Marble", value: "marble" },
              { label: "Porcelain", value: "porcelain" },   
              ...result.data.tileMaterial       
            ])
          }
          if (name === "tileSeal" && result.data.tileSeal) {
            setTileSealOptions([
              { label: "Sealed", value: "sealed" },
              { label: "Unsealed", value: "unsealed" },
              ...result.data.tileSeal       
            ])
          }
        }
      });
    });
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Specifications </h6>
            <p className="onboarding-subheading">
              Please enter as much information as possible
            </p>

            <div className="form-elements-flex">
              <div className="left-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Use *</span>
                  <Select
                    isClearable
                    value={
                      options.uses &&
                      options.uses.find(
                        (item) => item.value === formik.values.tileUse
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileUse", value.value)
                      } else {
                        formik.setFieldValue("tileUse", "")
                      }
                    }
                    }
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileUse)}
                    options={options.uses}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tiles Per Box</span>
                  <input
                    type="number"
                    name="tileCount"
                    className={
                      formik.errors.tileCount
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.tileCount}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Wastage %</span>
                  <input
                    type="number"
                    name="tileWastage"
                    className={
                      formik.errors.tileWastage
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.tileWastage}
                    onBlur={formik.handleBlur}
                    min={0}
                    max={100}
                  />
                </div>

                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Edge</span>

                  <CreatableSelect
                    isClearable
                    value={
                      tileEdgeOptions &&
                      tileEdgeOptions.find(
                        (item) => item.value === formik.values.tileEdge
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileEdge", value.value)
                      } else {
                        formik.setFieldValue("tileEdge", "")
                      }
                    }}
                    onCreateOption={(value) => {
                      handleCreateOption(value, "tileEdge", "tileEdge");
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileEdge)}
                    options={tileEdgeOptions}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Variation</span>
                  <input
                    type="number"
                    name="tileVar"
                    className={
                      formik.errors.tileVar
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.tileVar}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Finish</span>
                  <CreatableSelect
                    isClearable
                    value={
                      tileFinishOptions &&
                      tileFinishOptions.find(
                        (item) => item.value === formik.values.tileFinish
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileFinish", value.value)
                      } else {
                        formik.setFieldValue("tileFinish", "")
                      }
                    }}
                    onCreateOption={(value) => {
                      handleCreateOption(value, "tileFinish", "tileFinish");
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileFinish)}
                    options={tileFinishOptions}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Seal</span>
                  <CreatableSelect
                    isClearable
                    value={
                      tileSealOptions &&
                      tileSealOptions.find(
                        (item) => item.value === formik.values.tileSeal
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileSeal", value.value)
                      } else {
                        formik.setFieldValue("tileSeal", "")
                      }
                    }}
                    onCreateOption={(value) => {
                      handleCreateOption(value, "tileSeal", "tileSeal");
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileSeal)}
                    options={tileSealOptions}
                  />
                </div>
              </div>

              <div className="right-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Size *</span>
                  <Select
                    isClearable
                    value={
                      options.sizes &&
                      options.sizes.find(
                        (item) => item.value === formik.values.tileSize
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileSize", value.value)
                      } else {
                        formik.setFieldValue("tileSize", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileSize)}
                    options={options.sizes}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Square Meters Per Box</span>
                  <input
                    type="number"
                    name="m2PerBox"
                    className={
                      formik.errors.m2PerBox
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.m2PerBox}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tiles Shape</span>
                  <Select
                    isClearable
                    value={
                      options.shapes &&
                      options.shapes.find(
                        (item) => item.value === formik.values.tileShape
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileShape", value.value)
                      } else {
                        formik.setFieldValue("tileShape", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileShape)}
                    options={options.shapes}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Slip</span>
                  <CreatableSelect
                    isClearable
                    value={
                      options.slips &&
                      options.slips.find(
                        (item) => item.value === formik.values.tileSlip
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileSlip", value.value)
                      } else {
                        formik.setFieldValue("tileSlip", "")
                      }
                    }}
                    onCreateOption={(value) => {
                      handleCreateOption(value, "tileSlip", "tileSlip");
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileSlip)}
                    options={tileSlipOptions}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">Tile Material</span>
                  <CreatableSelect
                    isClearable
                    value={
                      options.materials &&
                      options.materials.find(
                        (item) => item.value === formik.values.tileMaterial
                      )
                    }
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("tileMaterial", value.value)
                      } else {
                        formik.setFieldValue("tileMaterial", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    styles={stylesForSelect(formik.errors.tileMaterial)}
                    onCreateOption={(value) => {
                      handleCreateOption(value, "tileMaterial", "tileMaterial");
                    }}
                    options={tileMaterialOptions}
                  />
                </div>
                <div className="select-title-flex">
                  <span className="form-mini-title">
                    Weight Per Box ({unit})
                  </span>
                  <input
                    type="number"
                    name="tileWeight"
                    className={
                      formik.errors.tileWeight
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.tileWeight}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TileTypeForm;
