import React, { useEffect, useState } from "react";
import FilterBar from "../../components/filters/FilterBar";
import UserSidebar from "../../components/sidebar/UserSidebar";
import CompanyStepper from "../../components/steppers/CompanyStepper";
import TopNav from "../../components/topnav/TopNav";
import {
  CloseRounded,
  SendRounded,
  FaceRetouchingNaturalRounded,
  MailRounded,
  TextureRounded,
  GroupsRounded,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./styles/UserSettings.css";
import PageTitle from "../../components/pageTitle/PageTitle";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorLensRounded } from "@mui/icons-material";
import { getDocument } from "../../firebase";

function UserSettings(props) {
  const [showEditCompany, setShowEditCompany] = useState(false);
  const user = useSelector((state) => state.user);
  const company = useSelector((state) => state.company);
  const navigate = useNavigate();
  const params = useParams();
  const [companyData, setCompanyData] = useState([])
  const [companyInfo, setCompanyInfo] = useState({})

  useEffect(() => {
    if (user.role == "company_sales") {
      navigate("/user/settings/support");
    }
    if (params.extra) {
      setShowEditCompany(true);
    }
    getDocument("Companies/"+company.id).then(result => {
      setCompanyData(result.data)
    })
    getDocument("Companies/"+company.id+"/info/public").then(result => {
      if (result.success) {
        setCompanyInfo(result.data)
      }
    })
  }, [showEditCompany]);

  return (
    <PageTemplate navigate={showEditCompany ? props.navigate : null}>
      <div className="quick-buttons">
        <PageTitle
          navigate={showEditCompany ? props.navigate : null}
          text="Settings "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/settings", text: "Settings" },
          ]}
        />
      </div>

      {!showEditCompany ? (
        <>
          <h6 className="product-table-heading">What would you like to do?</h6>
          <p className="onboarding-subheading">
            Select one of the options below to continue.
          </p>
          <div style={{display: 'grid', gridTemplateColumns: "1fr 1fr", gap: '10px'}}>
            <button
              className="button-box"
              onClick={() => setShowEditCompany(true)}
            >
              <div className="vertical-flex">
                <FaceRetouchingNaturalRounded className="large-icon" />
                <h6 className="section-heading"> Edit Company Profile </h6>
              </div>
            </button>
            { ((!companyInfo) || (companyInfo.enabledProductTypes && (companyInfo.enabledProductTypes.vanity || companyInfo.enabledProductTypes.basin))) &&
              <button
                onClick={() => navigate("/user/settings/materials")}
                className="button-box"
              >
                <div className="vertical-flex">
                  <TextureRounded className="large-icon" />
                  <h6 className="section-heading">Manage Materials</h6>
                </div>
              </button>
            }

              { (!companyInfo || (companyInfo.enabledProductTypes && companyInfo.enabledProductTypes.paint)) && 
                <button
                  onClick={() => navigate("/user/settings/colours")}
                  className="button-box"
                >
                  <div className="vertical-flex">
                    <ColorLensRounded className="large-icon" />
                    <h6 className="section-heading">Manage Colours</h6>
                  </div>
                </button>
              }
              
              { ((!companyInfo) || (companyInfo.enabledProductTypes && (companyInfo.enabledProductTypes.basin || companyInfo.enabledProductTypes.vanity))) &&
                <button
                  onClick={() => navigate("/user/settings/groups")}
                  className="button-box"
                >
                  <div className="vertical-flex">
                    <div className="large-icon" style={{ display: "flex" }}>
                      <div className="left-elements">
                        <TextureRounded className="small-icon" />
                        <ColorLensRounded className="small-icon" />
                      </div>
                      <div className="right-elements">
                        <ColorLensRounded className="small-icon" />
                        <TextureRounded className="small-icon" />
                      </div>
                    </div>
                    <h6 className="section-heading">Manage Groups</h6>
                  </div>
                </button>
              }
          </div>
        </>
      ) : (
        <div>
          <div className="button-title-flex">
            <h5 className="product-table-heading"> </h5>
            <IconButton type="button" onClick={() => {
              
              setShowEditCompany(false)
            }}>
              <CloseRounded></CloseRounded>
            </IconButton>
          </div>
          <CompanyStepper company={companyData} />
        </div>
      )}
    </PageTemplate>
  );
}

export default UserSettings;

// <CompanyStepper/>
