import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import { addDocument, firestore } from "../../firebase";
import Colours from "../../components/table/ColoursTable";
import NewColourForm from "../../components/forms/coloursMaterials/NewColourForm";
import GroupsTable from "../../components/table/groupsTable";
import NewGroupForm from "../../components/forms/coloursMaterials/NewGroupForm";

export default function UserGroups(props) {
  const [showAddCode, setShowAddCode] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [colours, setColours] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [product, setProduct] = useState("vanity");
  const [selectedGroup, setSelectedGroup] = useState([])
  const [initialValues, setInitialValues] = useState(null)

  const groupsRef = collection(
    firestore,
    "Companies/" + props.company.id + "/groups"
  );
  const coloursRef = collection(
    firestore,
    "Companies/" + props.company.id + "/colours"
  );
  const materialsRef = collection(
    firestore,
    "Companies/" + props.company.id + "/materials"
  );

  // Get realtime data
  function getData(collectionRef, setData) {
    return onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setData(items);
      },
      (error) => {
        console.error(error)
      }
    );
  }

  useEffect(() => {
    let unsubscribe = getData(groupsRef, setGroups);
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    let unsubscribe = getData(coloursRef, setColours);
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    let unsubscribe = getData(materialsRef, setMaterials);
    return () => unsubscribe();
  }, []);

  async function addGroup() {
    const data = {
      type: product,
    };
    await addDocument("Companies/" + props.company.id + "/groups", data).then(
      (result) => {
        console.log(result);
      }
    );
  }

  function showForm(group) {
    setSelectedGroup(group)
    setShowAddCode(true)
  }

  return (
    <PageTemplate
      navigate={showAddCode || showEditGroup ? props.navigate : null}
    >
      <div className="quick-buttons">
        <PageTitle
          navigate={showAddCode || showEditGroup ? props.navigate : null}
          text="Groups "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/settings", text: "Settings | " },
            { url: "/user/settings/groups", text: "Groups" },
          ]}
        />
        <div style={{ display: "flex" }}>
          <select
            className="short-input"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            style={{ width: "auto", marginRight: "10px" }}
          >
            <option value="vanity" label="Vanity" />
            <option value="shower" label="Shower" />
            <option value="mirror cabinet" label="Mirror Cabinet" />
          </select>
          <button
            className="add-company-button"
            onClick={() => addGroup()}
            style={{ width: "150px", marginTop:"0.5575rem" }}
          >
            Add New Group
          </button>
        </div>
      </div>
      {showAddCode && (
        <NewGroupForm
          company={props.company}
          showElement={setShowAddCode}
          colours={colours}
          materials={materials}
          group={selectedGroup}
          setInitialValues={setInitialValues}
          initialValues={initialValues}
        />
      )}
      {groups.map((group) => {
        return <GroupsTable key={"group_table_"+group.id} company={props.company} group={group} showForm={showForm} colours={colours} materials={materials} setValues={setInitialValues} />;
      })}
    </PageTemplate>
  );
}
