import React, { useState } from "react";
import "./CompanyOnboardingForm.css";
import "./CompanyBrandingForm.css";

function TaxAndContactForm({ formik }) {

  return (
    <div className="company-onboarding-container">
      <div className="onboarding-form">
        <div className="left-items">
          <label className="form-label" htmlFor="nzbn">
            NZBN Number:
          </label>
          <input
            // placeholder="NZBN Number"
            type="name"
            name="nzbn"
            className="input-style"
            onChange={formik.handleChange}
            value={formik.values.nzbn}
            onBlur={formik.handleBlur}
          />

          <label className="form-label" htmlFor="gst">
            GST Number:
          </label>
          <input
            // placeholder="GST Number"
            type="name"
            name="gst"
            className="input-style"
            onChange={formik.handleChange}
            value={formik.values.gst}
            onBlur={formik.handleBlur}
          />

          <label className="form-label" htmlFor="orderPrefix">
            Order Prefix:
          </label>
          <input
            // placeholder="Order Prefix"
            type="name"
            name="orderPrefix"
            className="input-style"
            onChange={formik.handleChange}
            value={formik.values.orderPrefix}
            onBlur={formik.handleBlur}
          />

         
        </div>

        <div className="right-items">
        <label className="form-label" htmlFor="leadsEmail">
            Leads Email:
          </label>
          <input
            // placeholder="Product Leads Email"
            type="name"
            name="leadsEmail"
            className={
              formik.errors.leadsEmail ? "error input-style" : "input-style"
            }
            onChange={formik.handleChange}
            value={formik.values.leadsEmail}
            onBlur={formik.handleBlur}
          />

          <div className="small-input-flex">
            <div style={{marginRight:"10px"}}>
              <label className="form-label" htmlFor="supportEmail">
                Support Email:
              </label>
              <input
                // placeholder="Support E-mail"
                type="name"
                name="supportEmail"
                className={
                  formik.errors.supportEmail
                    ? "error small-input-style-left"
                    : "small-input-style-left"
                }
                onChange={formik.handleChange}
                value={formik.values.supportEmail}
                onBlur={formik.handleBlur}
              />
            </div>

            <div>
              <label className="form-label" htmlFor="supportPhone">
                Support Phone:
              </label>
              <input
                // placeholder="Support Phone"
                type="name"
                name="supportPhone"
                className={
                  formik.errors.supportPhone
                    ? "error small-input-style"
                    : "small-input-style"
                }
                onChange={formik.handleChange}
                value={formik.values.supportPhone}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default TaxAndContactForm;
