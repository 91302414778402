import React, { useEffect, useState } from "react";
import PriceProductForm from "../../components/forms/products/PriceProductForm";
import DetailsProductForm from "../../components/forms/products/DetailsProductForm";
import TileTypeForm from "../../components/forms/products/TileTypeForm";
import UserSidebar from "../../components/sidebar/UserSidebar";
import TopNav from "../../components/topnav/TopNav";
//import './styles/ViewProduct.css';
import MediaProductForm from "../../components/forms/products/MediaProductForm";
import TileVariationForm from "../../components/forms/products/TileVariationForm";
import DocumentsProductForm from "../../components/forms/products/DocumentsProductForm";
import ColourProductForm from "../../components/forms/products/ColourProductForm";
import InventoryProductForm from "../../components/forms/products/InventoryProductForm";
import FeaturesProductForm from "../../components/forms/products/FeaturesProductForm";
import MenusProductForm from "../../components/forms/products/MenusProductForm";
import StatusProductForm from "../../components/forms/products/StatusProductForm";
import CostingsProductForm from "../../components/forms/products/CostingsProductForm";
import SamplesProductForm from "../../components/forms/products/SamplesProductForm";
import ARInfo from "../../components/forms/products/ARInfo";
import ARInfoWallpaper from "../../components/forms/products/ARInfoWallpaper";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";

function ViewProduct(props) {
  const [submitting, setSubmit] = useState(false);
  const [allFormValues, setFormValues] = useState({});
  const [status, setStatus] = useState({ allSubmitted: false, valid: true });
  const [productType, setType] = useState("tile");
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );
  let formValues = {};
  let formValid = true;
  let componentsSubmitted = {
    ar_info: productType !== "3D",
    ar_wallpaper: productType !== "wallpaper",
    colours: false,
    costings: false,
    details: false,
    documents: false,
    features: false,
    inventory: false,
    media: false,
    menus: false,
    price: false,
    samples: false,
    status: false,
    tile_type: productType !== "tile",
    tile_variation: productType !== "tile",
  };

  function handleSubmit() {
    console.log(allFormValues);
  }

  function submitComponent(success, key, values) {
    componentsSubmitted[key] = true;
    if (success) {
      formValues[key] = values;
    } else {
      formValid = false;
    }
    checkAllSubmitted();
  }

  function checkAllSubmitted() {
    if (Object.values(componentsSubmitted).every((item) => item === true)) {
      setFormValues(formValues);
      setStatus({ allSubmitted: true, valid: formValid });
    }
  }

  useEffect(() => {
    if (submitting) {
      if (status.allSubmitted) {
        if (status.valid) {
          handleSubmit();
        } else {
          console.error("Submit cancelled due to errors")
        }
        // TODO move this to only take effect if form is invalid (once submit handling is completed with redirect)
        setSubmit(false);
        setStatus({ allSubmitted: false, valid: true });
      }
    }
  }, [status, submitting]);

  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text="View Product "
          confirmNav={true}
          links={[
            { url: "/", text: "Home | " },
            { url: "/products", text: "Products | " },
            { url: "/products/view-product", text: "View Product" },
          ]}
        />
      </div>
      <div className="large-form-container">
        <div className="save-button-flex">
          <div className="form-header-flex">
            <div className="select-title-flex">
              <span className="form-mini-title">Product Type</span>
              <select
                name="productType"
                style={{ display: "block" }}
                type="name"
                className="hero-select-style"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="tile" label="Tile" />
                <option value="3D" label="Default 3D" />
                <option value="wallpaper" label="Default Flat (Wallpaper)" />
              </select>
            </div>
          </div>
          <div className="button-flex">
            <button
              type="submit"
              className="add-company-button-right"
              onClick={() => setSubmit(true)}
            >
              
              Publish
            </button>
            <button
              type="submit"
              className="add-company-button"
              onClick={() => setSubmit(true)}
            >
              
              Save Draft
            </button>
          </div>
        </div>

        <div className="form-horizontal-flex">
          <div className="form-left-flex">
            {productType === "tile" && (
              <TileTypeForm
                submitting={submitting}
                setSubmit={setSubmit}
                setStatus={submitComponent}
              />
            )}
            <DetailsProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <MediaProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            {productType === "tile" && (
              <TileVariationForm
                submitting={submitting}
                setSubmit={setSubmit}
                setStatus={submitComponent}
              />
            )}
            {productType === "3D" && (
              <ARInfo
                submitting={submitting}
                setSubmit={setSubmit}
                setStatus={submitComponent}
              />
            )}
            {productType === "wallpaper" && (
              <ARInfoWallpaper
                submitting={submitting}
                setSubmit={setSubmit}
                setStatus={submitComponent}
              />
            )}
            <DocumentsProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <PriceProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <SamplesProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <CostingsProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
          </div>

          <div className="form-right-flex">
            <StatusProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <MenusProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <FeaturesProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <InventoryProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
            <ColourProductForm
              submitting={submitting}
              setSubmit={setSubmit}
              setStatus={submitComponent}
            />
          </div>
        </div>

        <div className="button-bottom-flex">
          <div className="form-header-flex"></div>
          <div className="button-flex">
            <button
              type="submit"
              className="add-company-button-right"
              onClick={() => setSubmit(true)}
            >
              
              Publish
            </button>
            <button
              type="submit"
              className="add-company-button"
              onClick={() => setSubmit(true)}
            >
              
              Save Draft
            </button>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default ViewProduct;
