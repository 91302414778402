import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "../companies/AddCompanyForm.css";
import { CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  notEmpty,
  emailFormat,
  numberFormat,
  validateItem,
  checkForm,
} from "../../../helpers/form_utils";
import ImageUploader from "../files/ImageUploader";

function AddTeamMemberForm({
  onAddTeamMember,
  showElement,
  member,
  type = "user",
}) {
  // variable to ensure state updates only occur while component is mounted
  let componentMounted = true;

  // setup state variables
  const initialValues = member || {
    profile: false,
    first_name: "",
    last_name: "",
    role: "",
    email: "",
    phone: "",
  };
  const [addCompany, setAddCompany] = useState(true); // whether the form is visible or not
  const [details, setDetails] = useState(initialValues); // user entered data
  const [error, setError] = useState({
    profile: false,
    first_name: false,
    last_name: false,
    role: false,
    email: false,
    phone: false,
  }); // record of any invalid data
  const [loading, setLoading] = useState(false); // whether the function is loading
  const [firebaseError, setFirebaseError] = useState(""); // any errors from firebase
  const [imageUrl, setImageUrl] = useState("");

  // conditions each data value must meet to be valid
  const conditions = {
    first_name: [notEmpty],
    last_name: [],
    role: [notEmpty],
    email: [notEmpty, emailFormat],
    phone: [numberFormat],
  };

  // function to save value and check validity whenever form data is changed
  function handleChange(fieldId, value) {
    if (componentMounted) {
      let isValid = validateItem(conditions[fieldId], value); // validate data
      setError({ ...error, [fieldId]: !isValid }); // update error object
      setDetails({ ...details, [fieldId]: value }); // update details object
    }
  }

  // function to process and submit data when form is submitted
  function submitHandler(e) {
    e.preventDefault();
    if (componentMounted) {
      setLoading(true);
    }
    // check if form data is valid
    let formValid = checkForm(details, conditions, setError);
    if (formValid) {
      // add new company with given details
      onAddTeamMember(details).then((result) => {
        if (componentMounted) {
          if (result.success) {
            setLoading(false);
            setAddCompany(false); // hide form
          } else {
            setFirebaseError(result.message);
            setLoading(false);
          }
        }
      });
    } else {
      if (componentMounted) {
        setLoading(false);
      }
    }
  }

  // function to run when component is unmounted
  function unmount() {
    componentMounted = false;
  }

  // run cleanup function when component unmounts
  useEffect(() => {
    if (details.profile) {
      const storage = getStorage();
      getDownloadURL(ref(storage, details.profile))
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          setImageUrl(url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    }
    return () => unmount();
  }, []);

  function setImage(file) {
    setDetails({ ...details, profile: file });
  }

  return (
    <div className="add-company-form-container">
      {addCompany ? (
        <form className="form-elements" onSubmit={submitHandler}>
          <div className="button-title-flex">
            <h5 className="add-company-form-title">
              {member ? "Edit Team Member" : "Add New Team Member"}
            </h5>
            <Button type="button" onClick={() => showElement(false)}>
              <CloseRounded></CloseRounded>
            </Button>
          </div>
          <div className="form-input-style">
            <div className="short-input-style">
              <ImageUploader initialFile={imageUrl} setFile={setImage} showBackground={false}/>
              <div className="short-input-group">
                <label className="form-label" htmlFor="firstname">
                  First Name:
                </label>
                <input
                  type="name"
                  name="name"
                  className={
                    error.first_name ? "error short-input" : "short-input"
                  }
                  onChange={(e) => handleChange("first_name", e.target.value)}
                  value={details.first_name}
                />
              </div>
              <div className="short-input-group">
                <label className="form-label" htmlFor="lastname">
                  Last Name:
                </label>
                <input
                  type="name"
                  name="name"
                  className={
                    error.last_name ? "error short-input" : "short-input"
                  }
                  onChange={(e) => handleChange("last_name", e.target.value)}
                  value={details.last_name}
                />
              </div>
            </div>
            <div className="short-input-style">
              <div className="short-input-group">
                <label className="form-label" htmlFor="role">
                  Role:
                </label>
                {/* TODO change select css */}
                <select
                  name="role"
                  value={details.role}
                  onChange={(e) => handleChange("role", e.target.value)}
                  style={{ display: "block" }}
                  type="name"
                  className={error.role ? "error short-input" : "short-input"}
                >
                  <option
                    className="placeholder-text"
                    value=""
                    label="Select"
                  />
                  {type == "user" ? (
                    <>
                      <option value="company_admin" label="Admin" />
                      <option value="company_editor" label="Editor" />
                      <option value="company_sales" label="Sales" />
                    </>
                  ) : (
                    <>
                      <option value="actuality_admin" label="Actuality Admin" />
                      <option value="actuality_support" label="Actuality Support" />
                      <option value="actuality_sales" label="Actuality Sales" />
                      <option value="company_admin" label="Company Admin" />
                      <option value="company_editor" label="Company Editor" />
                      <option value="company_sales" label="Company Sales" />
                    </>
                  )}
                </select>
              </div>
              <div className="short-input-group">
                <label className="form-label" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  className={error.email ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("email", e.target.value)}
                  value={details.email}
                />
              </div>
              <div className="short-input-group">
                <label className="form-label" htmlFor="contactnumber">
                  Mobile:
                </label>
                <input
                  type="tel"
                  name="number"
                  className={error.phone ? "error short-input" : "short-input"}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  value={details.phone}
                />
              </div>
            </div>
            <h5>{firebaseError}</h5>
            <div className="button-flex">
              <div className="button-div">
                <button
                  className="form-button-submit"
                  disabled={loading}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="company-page-else">
          <h5 className="great-message">
            {!member
              ? "Great! User invited :)"
              : "Great! User updated successfully :)"}
          </h5>
          <Button onClick={() => showElement(false)}>
            <CloseRounded></CloseRounded>
          </Button>
        </div>
      )}
    </div>
  );
}

export default AddTeamMemberForm;
