import React, { useState, useEffect } from "react";
import LineChart from "../../components/charts/LineChart";
import FilterBar from "../../components/filters/FilterBar";
import FeaturedInfo from "../../components/information/FeaturedInfo";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav from "../../components/topnav/TopNav";
import LargeWidget from "../../components/widgets/LargeWidget";
import SmallWidget from "../../components/widgets/SmallWidget";
import ProductRejectionTable from "../../components/table/ProductRejectionTable";
import UserProductTable from "../../components/table/UserProductTable";
import MiniCounterCard from "../../components/cards/MiniCounterCard";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";

// Importing CSS
import "./styles/Home.css";
import OnboardingTable from "../../components/table/OnboardingTable";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";

function Home({ companies, products, setQuery, resetQuery }) {
  useEffect(() => {
    setQuery(query(collection(firestore, "Products"), where("status", "==", 'processing')));
    return resetQuery;
  }, []);

  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );
  return (
    <PageTemplate>
      <div className="quick-buttons">
        <PageTitle text="Dashboard " links={[{ url: "/", text: "Home" }]} />
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      <OnboardingTable companies={companies} />
      <ProductRejectionTable products={products} />
    </PageTemplate>
  );
}

export default Home;
