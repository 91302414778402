import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Add, AddToPhotosRounded } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// TODO add drag and drop functionality
// TODO add remove file functionality? (check if logo file is required)
// TODO consider whether we want to put some kind of constraint on the size of files being uploaded (may depend on particular file purpose)
// TODO add check for file size
// TODO add check for file dimensions
function FileUploader({ setFiles }) {
  const styleForButton = {
    width: "4rem",
    height: "4rem",
    borderRadius: "2.3rem",
  };

  const [inputKey, setInputKey] = useState(false);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected files
  const handleChange = (event) => {
    setFiles(event.target.files);
    inputKeyReset()
  };

  const inputKeyReset = () => {
    let randomString = Math.random().toString(36);
    setInputKey(randomString)
  }

  return (
    <>
      <IconButton style={styleForButton} onClick={handleClick}>
        <CloudUploadIcon />
      </IconButton>
      <input
        key={inputKey || ""}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple={true}
      />
    </>
  );
}

export default FileUploader;
