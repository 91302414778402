import React, { useEffect, useState } from "react";
import FilterBar from "../../components/filters/FilterBar";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNav from "../../components/topnav/TopNav";
import "./styles/Companies.css";
import { Link, Outlet, useParams } from "react-router-dom";
import ProductTable from "../../components/table/ProductTable";
import { propTypes } from "react-bootstrap/esm/Image";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";

function SingleCompany(props) {
  let params = useParams();
  const [company, setCompany] = useState("");

  useEffect(() => {
    if (props.companies.find((company) => company.id === params.companyid)) {
      setCompany(
        props.companies.find((company) => company.id === params.companyid)
      );
    }
  }, [props.companies]);
  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text={company.trading_name + " "}
          links={[
            { url: "/", text: "Home | " },
            { url: "/companies", text: "Companies | " },
            { url: "/company/" + company.id, text: "Company" },
          ]}
        />
        {/* TODO remove this button and fix routing */}
        <Link to={"/products/add-product"}>
          <button className="add-company-button"> Add Product</button>
        </Link>
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      <ProductTable
        company={params.companyid}
        products={props.products}
        companies={props.companies}
      />
      <div className="home-widgets"></div>
    </PageTemplate>
  );
}
export default SingleCompany;
