import React from "react";
import LoginForm from "../../components/forms/login/LoginForm";
import { auth, getCompanyDetails, getUserDetails } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSelector, useDispatch } from "react-redux";
import { set_user } from "../../redux/user/actions";
import { set_company } from "../../redux/company/actions";

//Importing CSS
import "./Login.css";

function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // login user
  async function Login(details) {
    try {
      // sign in user with firebase auth
      const response = await signInWithEmailAndPassword(
        auth,
        details.email,
        details.password
      );
      // retrieve user details from firestore
      const user = await getUserDetails(response.user.uid);

      if (!user.success) {
        console.log(user.message)
        window.alert(user.message);
        return;
      } // TODO better error handling here

      let company = { name: "placeholder", id: "placeholder", onboarded: true };

      if (user.data.role != "actuality_admin") {
        // TODO remove reference to companies array
        company.id = user.data.company || user.data.companies[0];
      }
      
      // TODO add error handling here if user details document is missing
      dispatch(set_user(user.data));
      dispatch(set_company(company));
      navigate("/");
      return false;
    } catch (error) {
      // handle errors
      console.log(error.message)
      window.alert(error.message);
      // setError(error.message);
      return true;
    }
  }

  const functions = getFunctions();
  const registerUser = httpsCallable(functions, "registerUser");
  async function Register(details) {
    try {
      const result = await registerUser(details);
      console.log(result);
      if (result.data.success) {
        // TODO add email verification
        return await Login(details);
      } else {
        window.alert(result.data.message);
        return true;
      }
    } catch (error) {
      window.alert(error);
      return true;
    }
  }

  return user ? (
    <Navigate to="/home" />
  ) : (
    <div className="login-body">
      <LoginForm userLogin={Login} userRegister={Register} />
    </div>
  );
}

export default Login;
