import React, { useState } from "react";
import FilterBar from "../../components/filters/FilterBar";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import Sidebar from "../../components/sidebar/Sidebar";
import ProductTypeTable from "../../components/table/ProductTypeTable";
import TopNav from "../../components/topnav/TopNav";

import "./styles/Settings.css";

import "./styles/Settings.css";

function Settings(props) {
  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
            <PageTitle
            navigate={props.navigate}
              text="Settings "
              links={[
                { url: "/", text: "Home | " },
                { url: "/settings", text: "Settings" },
              ]}
            />
          </div>
          {/* <div className="filter-bar-container">
            <FilterBar />
            <div className="underline-flex"></div>
          </div> */}
          <ProductTypeTable />
          <div className="home-widgets"></div>
    </PageTemplate>
  );
}

export default Settings;
