import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageTemplate from "../../../components/pageTemplate/pageTemplate";
import PageTitle from "../../../components/pageTitle/PageTitle";
import './AppSettings.css'
import CreateProductAccordion from "../createProduct/CreateProductAccordion";
import FeaturedProductsSearchResults from "./FeaturedProductsSearchResults";
import { filterProductsNotInFeatured, filterProductsThatMatchSearchQueryAndNotInFeatured, getAllProducts, getFeaturedProductIDs, searchProducts, setFeaturedProductsForCompany } from "./AppSettingsFeatures";
import AddIcon from '@mui/icons-material/Add';
import { AddCircle, AddCircleOutline, ArrowBack, Delete, DeleteForever, RemoveCircleOutline, RemoveCircleOutlined, RemoveCircleOutlineIcon } from "@mui/icons-material";

function AppSettings(props) {
  const company = useSelector((state) => state.company);

  // Arr of all company products
  const [products, setProducts] = useState([]);

  // Arr of search results
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Arr of users featured products
  const [featuredProducts, setFeaturedProducts] = useState([]);

  // We use this to mark already featured products so they can't be featured again
  const [featuredProductIDs, setFeaturedProductIDs] = useState([]);

  // Bound to search query text field
  const [searchQuery, setSearchQuery] = useState("")

  // Disables changes while update in progres
  const [updateInProgres, setUpdateInProgress] = useState(false)

  const [addMode, setAddMode] = useState(false)

  useEffect(() => {
    if (!company) return;

    /**
     * Get all the products for this company
     */
    getAllProducts(company.id).then(result => {
      if (result.isEmpty) {
        return setProducts([])
      }

      setProducts(result.products)

      getFeaturedProductIDs(company.id).then(featuredProductsResult => {
  
        const _featuredProducts = result.products.filter(product => featuredProductsResult.includes(product.id))
        setFeaturedProducts(_featuredProducts)
        setFeaturedProductIDs(featuredProductsResult)

      }).catch(err => {
        console.error(err)
        setFeaturedProductIDs([])
      })
    }).catch(err => {
      toast.error("Failed to load your products.")
    })

    
  }, [])

  /**
   * Listen for query updates & perform search
   */
  useEffect(() => {
    // If query empty - get first n products where n = 5
    if (searchQuery.length === 0) {
      const _products = filterProductsNotInFeatured(products, featuredProductIDs, 5)
      return setFilteredProducts(_products);
    }

    // Perform search if query.len > 0
    setFilteredProducts(searchProducts(searchQuery, products, featuredProductIDs, 5))
  }, [searchQuery, featuredProductIDs])

  /**
   * Add products to the featured products list
   * @param {} fp 
   * @returns 
   */
  const addProductToFeatured = (fp) => {
    setUpdateInProgress(true);

    if (featuredProducts.length >= 8) {
      return toast.error("Youve reached your maximum featured products")
    }

    const newFeaturedProductIDs = [...featuredProductIDs, fp.id]

    toast.promise(setFeaturedProductsForCompany(company.id, newFeaturedProductIDs), {
      pending: "Saving...",
      error: "Failed - You might not have permission to perform this action",
      success: "Added " + fp.details.productname + " to your featured products"
    }).then(_ => {
      setFeaturedProducts([...featuredProducts, fp])
      setFeaturedProductIDs(newFeaturedProductIDs)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setUpdateInProgress(false)
    })
  }

  const removeFeaturedProduct = (fp) => {
    const _featuredProducts = featuredProducts.filter(prod => prod.id !== fp.id)
    const newFeaturedProductIDs = featuredProductIDs.filter(fpId => fpId !== fp.id)
    setUpdateInProgress(true)

    toast.promise(setFeaturedProductsForCompany(company.id, newFeaturedProductIDs), {
      pending: "Saving...",
      error: "Failed - You might not have permission to perform this action",
      success: "Removed " + fp.details.productname + " from your featured products"
    }).then(_ => {
      setFeaturedProducts(_featuredProducts)
      setFeaturedProductIDs(newFeaturedProductIDs)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setUpdateInProgress(false)
    })
  }

  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text="App Customisation "
          links={[
          ]}
        />
      </div>

      <p className="as-page-about">Customise the expeirence your customers receive in the QuickViewAR app & website</p>

      <div>
        <CreateProductAccordion title={"Featured Products"} defaultExpanded={true}>
          {!addMode ?
            <div style={{ marginBottom: '20px' }}>
              <h2 className="as-section-heading-lg">Your featured products </h2>
              <p className="as-text-sm">You can select up to 5 products for us to feature in app.</p>
              <FeaturedProductsSearchResults
                results={featuredProducts}
                noInputMessage={"You aren't featuring any products currently"}
                noResultsMessage={"You aren't currently featuring any products"}
                queryString={".."}
                onResultPressedHandler={featuredProduct => removeFeaturedProduct(featuredProduct)}
                editsDisabled={updateInProgres}
                promptText="Tap to remove from featured products"
                requireConfirmation={true}
                animationsDisabled={true}
                actionButton={<span>Remove from featured <RemoveCircleOutline/></span>}
                confirmActionButton={<span style={{ textDecoration: 'underline' }}>Confirm delete?</span>}
              />

              <div className="as-spacer"></div>

              <span onClick={() => setAddMode(true)} className="as-add-button">Add Featured Products</span>
            </div>
            :
            <div className="z">
              <div style={{ paddingTop: '0' }}>
                <h2 className="as-section-heading-lg">Add featured products </h2>
                <p className="as-text-sm">You can select up to 5 products for us to feature in app.</p>

                <p className="as-input-label as-pt-15" >Product Search:</p>
                <input type="text" name="" id="" placeholder="Tile name" className="as-input-text" value={searchQuery} onChange={(evt) => { setSearchQuery(evt.target.value) }} />

                <FeaturedProductsSearchResults
                  results={filteredProducts}
                  noInputMessage={"Enter a search query to begin"}
                  noResultsMessage={"We couldn't find any results for that query"}
                  queryString={searchQuery}
                  onResultPressedHandler={featuredProduct => addProductToFeatured(featuredProduct)}
                  excludedIDs={featuredProductIDs}
                  editsDisabled={updateInProgres}
                  promptText="Tap to add to your featured products"
                  animationsDisabled={true}
                  actionButton={<span>Add to featured <AddCircleOutline/></span>}
                />

                <div className="as-spacer"></div>
                <span onClick={() => setAddMode(false)} className="as-add-button"><ArrowBack/></span>
                <div className="as-spacer"></div>

              </div>


            </div>}
        </CreateProductAccordion>
      </div>
    </PageTemplate>
  );
}


export default AppSettings;
