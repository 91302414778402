import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  FilterListRounded,
  VisibilityRounded,
  EditRounded,
  Today,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./TeamTable.css";
import Image from "../../images/Sample_User_Icon_grey.png";
import BaseTable from "./BaseTable";
import FirebaseImage from "../media/FirebaseImage";
import TableSearch from "../filters/TableSearch";
import { deleteDocument, setDocument } from "../../firebase";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

const styleForButton = {
  width: "auto",
  height: "1.88rem",
  color: "#01365B",
  backgroundColor: "rgba(12, 113, 195, 0.23)",
  border: "1.23px solid rgba(1, 54, 91, 0.44)",
  borderRadius: "23px",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForButtonPending = {
  width: "auto",
  height: "1.88rem",
  color: "#FF9900",
  backgroundColor: "rgba(255, 153, 0, 0.23)",
  borderRadius: "23px",
  border: "1.23px solid rgba(255, 153, 0, 0.55)",
  fontSize: "12px",
  padding: " 0 1.23rem",
};

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "100%",
  objectFit: "contain",
  backgroundColor: "lightgrey",
};

export default function MaterialsTable({
  materials,
  editMaterial,
  setValues,
  company,
  archived,
}) {
  const [search, setSearch] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedMaterials, setSelectedMaterials] = React.useState([]);
  const user = useSelector((state) => state.user);

  const cells = [
    {
      id: "image",
      numeric: false,
      disablePadding: true,
      label: "",
      cell: (member) => (
        <TableCell
          key={member.id + "_image"}
          align="center"
          className="table-cell"
        >
          <FirebaseImage
            path={member.imageUrl}
            styleForImg={styleForImg}
            defaultImage={Image}
          />
        </TableCell>
      ),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      cell: (member) => (
        <TableCell
          key={member.id + "_name"}
          align="center"
          className="table-cell"
        >
          {member.name || ""}
        </TableCell>
      ),
    },
    {
      id: "code",
      numeric: false,
      disablePadding: true,
      label: "Code",
      cell: (member) => (
        <TableCell
          key={member.id + "_code"}
          align="center"
          className="table-cell"
        >
          {member.code || ""}
        </TableCell>
      ),
    },
    {
      id: "date_added",
      numeric: true,
      disablePadding: false,
      label: "Date Added",
      cell: (member) => (
        <TableCell
          key={member.id + "_date"}
          align="center"
          className="table-cell"
        >
          {member.date_added && member.date_added.seconds ? (
            <Tooltip
              title={new Date(
                member.date_added.seconds * 1000
              ).toLocaleString()}
            >
              <Today />
            </Tooltip>
          ) : (
            ""
          )}
        </TableCell>
      ),
    },
    {
      id: "products",
      numeric: true,
      disablePadding: false,
      label: "# Products",
      cell: (member) => (
        <TableCell
          key={member.id + "_products"}
          align="center"
          className="table-cell"
        >
          0
        </TableCell>
      ),
    },

    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (member) => (
        <TableCell
          key={member.email + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            onClick={() => {
              setValues(member);
              editMaterial(true);
            }}
            style={styleForIconButton}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
  ];

  async function deleteMaterial() {
    if(["company_admin", "actuality_admin"].includes(user.role)){
      for (let index = 0; index < selectedMaterials.length; index++) {
        const material = selectedMaterials[index];
        if (archived) {
          await deleteDocument(
            "Companies/" + company.id + "/materials/" + material
          ).then(async (result) => {
            let materialsDoc = materials.find((mat) => mat.id == material);
            await deleteFile(materialsDoc.imageUrl).then(result2 => {
              console.log(result, result2);
            })
            
          });
        } else {
          let materialsDoc = materials.find((mat) => mat.id == material);
          materialsDoc.archived = true;
          await setDocument(
            "Companies/" + company.id + "/materials/" + material,
            materialsDoc
          ).then((result) => {
            console.log(result);
          });
        }
      }
      setShowModal(false);
      setSelectedMaterials([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  async function unarchiveFunc(materialList) {
    if(["company_admin", "actuality_admin"].includes(user.role)){
      for (let index = 0; index < materialList.length; index++) {
        const material = materialList[index];
        let materialsDoc = materials.find((mat) => mat.id == material);
        materialsDoc.archived = false;
        await setDocument(
          "Companies/" + company.id + "/materials/" + material,
          materialsDoc
        ).then((result) => {
          console.log(result);
        });
      }
      setSelectedMaterials([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  return (
    <div>
      {/* TABLE DISPLAYS NON-ARCHIVED MATERIALS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Materials"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Material"}
            />
          }
          rows={materials.filter(
            (row) =>
              row.name.toLowerCase().includes(search.toLowerCase()) &&
              !row.archived
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedMaterials(value);
          }}
          archived={archived}
          selectedItems={selectedMaterials}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS ARCHIVED MATERIALS */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Materials"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Material"}
            />
          }
          rows={materials.filter(
            (row) =>
              row.name.toLowerCase().includes(search.toLowerCase()) &&
              row.archived
          )}
          columns={cells}
          defaultOrder={"name"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedMaterials(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedMaterials}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Material" : "Archive Material"}
        message={
          archived
            ? "Are you sure you would like to delete this material?"
            : "Are you sure you would like to archive this material?"
        }
        confirm={deleteMaterial}
      />
    </div>
  );
}
