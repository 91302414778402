import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import FilterBar from "../../../components/filters/FilterBar";
import PriceProductForm from "../../../components/forms/products/PriceProductForm";
import DetailsProductForm from "../../../components/forms/products/DetailsProductForm";
import TileTypeForm from "../../../components/forms/products/TileTypeForm";
import Sidebar from "../../../components/sidebar/Sidebar";
import UserSidebar from "../../../components/sidebar/UserSidebar";
import TopNav from "../../../components/topnav/TopNav";
import ".././styles/AddProduct.css";
import MediaProductForm from "../../../components/forms/products/MediaProductForm";
import TileVariationForm from "../../../components/forms/products/TileVariationForm";
import DocumentsProductForm from "../../../components/forms/products/DocumentsProductForm";
import ColourProductForm from "../../../components/forms/products/ColourProductForm";
import InventoryProductForm from "../../../components/forms/products/InventoryProductForm";
import FeaturesProductForm from "../../../components/forms/products/FeaturesProductForm";
import MenusProductForm from "../../../components/forms/products/MenusProductForm";
import StatusProductForm from "../../../components/forms/products/StatusProductForm";
import CostingsProductForm from "../../../components/forms/products/CostingsProductForm";
import SamplesProductForm from "../../../components/forms/products/SamplesProductForm";
import ARInfo from "../../../components/forms/products/ARInfo";
import ARInfoWallpaper from "../../../components/forms/products/ARInfoWallpaper";
import PageTitle from "../../../components/pageTitle/PageTitle";
import PageTemplate from "../../../components/pageTemplate/pageTemplate";
import { useNavigate } from "react-router-dom";

function AddWallpaper(props) {
  const navigate = useNavigate()
  
  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          text="Add Product "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/products", text: "Products | " },
            { url: "/user/products/add-product", text: "Add Product | " },
            {
              url: "/user/products/add-product/wallpaper",
              text: "Add Wallpaper",
            },
          ]}
        />
        <Link to={"/user/products/add-product"}>
          <button className="add-product-button-faded"> Add Product </button>
        </Link>
      </div>
      <div className="filter-bar-container">
        <div className="underline-flex"></div>
      </div>

      <div className="large-form-container">
        <div className="save-button-flex">
          <div className="select-title-flex">
            <span className="form-mini-title">Product Type</span>
            <select
              name="productType"
              style={{ display: "block" }}
              type="name"
              className="hero-select-style"
            >
              <option className="placeholder-text" value="" label="Select" />
              <option value="tile" label="Tile" />
              <option value="3D" label="Default 3D" />
              <option value="wallpaper" label="Default Flat (Wallpaper)" />
            </select>
          </div>
          <button className="add-company-button"> Save </button>
        </div>
        <div className="form-horizontal-flex">
          <div className="form-left-flex">
            <DetailsProductForm />
            <MediaProductForm />
            <ARInfoWallpaper />
            <DocumentsProductForm />
            <PriceProductForm />
            <SamplesProductForm />
            <CostingsProductForm />
          </div>

          <div className="form-right-flex">
            <StatusProductForm />
            <MenusProductForm />
            <FeaturesProductForm />
            <InventoryProductForm />
            <ColourProductForm />
          </div>
        </div>
        <div className="button-bottom-flex">
          <button className="add-company-button"> Save </button>
        </div>
      </div>
    </PageTemplate>
  );
}

export default AddWallpaper;

/*<div className="underline-flex"></div>*/
