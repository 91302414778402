export const set_user = (user) => {
    return {
        type: 'SET_USER',
        payload: user
    };
}

export const logout = () => {
    return {
        type: 'LOGOUT',
    };
}