
import { Inventory, Loyalty, MarkunreadMailbox, TrendingUp } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import MyLineChart from "../../charts/LineChart";
import SingleAnaltyicCard from "./SingleAnalyticCard";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase";

const DEFAULT_ANALYTICS_DATA = Array(7).fill({name: "", views: 0})

const DashboardAnalytics = () => {
  const company = useSelector((state) => state.company);

  const [viewAnalyticsData, setViewAnalyticsData] = useState(DEFAULT_ANALYTICS_DATA);
  const [unreadLeads, setUnreadLeads] = useState(0);
  const [totalProductViews, setTotalProductViews] = useState(0);
  const [wishlists, setWishlists ] = useState(0);

  useEffect(() => {
    const labels = getDayLabels()
    var data = []

    // Analytics data has been set for this company, and contains 7 days worth of data
    if (company.analytics && company.analytics.data && company.analytics.data.length === 7) {
      const analyticsData = company.analytics;
      setTotalProductViews(company.analytics.totalProductViewCounter || 0)
      setWishlists(company.analytics.totalWishlistsCounter || 0)

      // data: is an array of n=7 integer values corresponding to the last 7 days of 
      // product views where data[n-1] is today and data[0] is 7 days ago.
      // Likewise, labels is a list of n=7 strings, where labels[n-1] is todays day (monday e.g)

      // For each day of analytics data, push it and a label onto data.
      analyticsData.data.forEach((day, i) => {
        data.push({
          name: labels[i],
          views: day
        })
      });
    } else {
      // No analytics data has been found for this user, just push 0 views
      // Analtyics data will exist for the last 7 days once there has been
      // one product view,
      labels.forEach(day => {
        data.push({
          name: day,
          views: 0
        })
      });
    }

    setViewAnalyticsData(data);

    // Query number incomplete/unread leads
    const leadsRef = collection(firestore, "Leads");
    const q = query(leadsRef, where("company_id", "==", company.id), where("marked_complete", "==", false));

    // snapshot.size === number of unread leads
    getDocs(q).then((snapshot) => {
      setUnreadLeads(snapshot.size)
    }).catch(error => {
      // Had an issue querying leads, showing 
      // 0 is appropriate in this situation
      console.error(error)
      setUnreadLeads(0);
    })
  }, [company]);

  return (
    <div className="dashboard-vertical-flex">
      <div>
        <MyLineChart
          data={viewAnalyticsData}
          title="Product Views"
          dataKey="views"
          grid
        />
      </div>
      <div className="analytics-container">
        <SingleAnaltyicCard title="Products" iconComponent={<Inventory />} value={company.num_products} url="/user/products"/>
        <SingleAnaltyicCard title="Unread Leads" iconComponent={<MarkunreadMailbox />} value={unreadLeads} url="/user/leads"/>
        <SingleAnaltyicCard title="Saved To Projects" iconComponent={<Loyalty />} value={wishlists} />
        <SingleAnaltyicCard title="Views" iconComponent={<TrendingUp />} value={totalProductViews} />
      </div>
    </div>
  )
}

/**
 * Get a list of n=7 strings, where [n-1] is todays day (e.g monday), counting down to where [0] is the day one week ago.
 */
const getDayLabels = () => {
  // Days of week as defined in JS standard
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var labels = ["", "", "", "", "", "", ""]

  var dayOfWeek = new Date().getDay();

  for (let index = 0; index < days.length; index++) {
    labels[labels.length - index - 1] = days[dayOfWeek];

    dayOfWeek = dayOfWeek - 1

    // If we get to past sunday(0), go to saturday(6)
    if (dayOfWeek < 0) {
      dayOfWeek = 6;
    }
  }

  return labels
}

export default DashboardAnalytics;