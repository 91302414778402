import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY || "AIzaSyD3cJsVJ6cOnG-79lL2x_yxfO9Nz93AFuQ",
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN || "actualityweb-22b30.firebaseapp.com",
  projectId: process.env.REACT_APP_FB_PROJECT_ID || "actualityweb-22b30",
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET || "actualityweb-22b30.appspot.com",
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID || "989343811321",
  appId: process.env.REACT_APP_FB_APP_ID || "1:989343811321:web:9c6a57f72ad912374e0428",
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID || "G-D84GSWHXDW",
};

const firebase = initializeApp(firebaseConfig);

const auth = getAuth();
const firestore = getFirestore();

/**
 * gets the details of a given user from firestore
 * @param {string} userId
 * @returns {{success: boolean, message: string, data: object}} an object recording the outcome and data
 */
export async function getUserDetails(userId) {
  try {
    const userRef = doc(firestore, "Users/" + userId);
    const snapshot = await getDoc(userRef);
    return {
      message: "User Details retrieved",
      success: true,
      data: snapshot.data(),
    };
  } catch (error) {
    return { message: error.message, success: false };
  }
}

/**
 * gets the details of a given company from firestore
 * @param {string} companyId the id of the company
 * @returns {{success: boolean, message: string, data: object}} an object recording the outcome and data
 */
export async function getCompanyDetails(companyId) {

  try {
    const companyRef = doc(firestore, "Companies/" + companyId);
    const snapshot = await getDoc(companyRef);
    return {
      message: "Company Details retrieved",
      success: true,
      data: snapshot.data(),
    };
  } catch (error) {
    return { message: error.message, success: false };
  }
}

/**
 * gets the data from a given document on firestore
 * @param {string} docPath the path to the document being retrieved
 * @returns {{success: boolean, message: string, data: object}} an object recording the outcome and data
 */
export async function getDocument(docPath) {
  console.log(firebaseConfig)
  console.log(process.env)
  
  return new Promise(async (resolve, reject) => {
    try {      
      const docRef = doc(firestore, docPath);
      const snapshot = await getDoc(docRef)
      if (snapshot.exists()) {
        resolve ({
          message: "Document retrieved",
          success: true,
          data: snapshot.data(),
        });
      } else {
        console.log(docPath)
        resolve({
          message: "No data retrieved. Document may not exist.",
          success: false,
        });
      }
    } catch (error) {
      console.error(error)
      reject({ message: error.message, success: false });
    }
  })
}

/**
 * deletes a document on firestore
 * @param {string} docPath the path to the document being retrieved
 * @returns {{success: boolean, message: string}} an object recording the outcome
 */
export async function deleteDocument(docPath) {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestore, docPath);
      const snapshot = await deleteDoc(docRef);
      resolve ({ message: "Document retrieved", success: true });
    } catch (error) {
     reject({ message: error.message, success: false });
    }
  })
}

/**
 * creates a document in the specified collection with a randomised id and returns the id
 * @param {string} collectionName the location for the document to be created
 * @param {object} data the data to store in the document
 * @returns {{success: boolean, message: string, docId: string}} an object recording the outcome and document id
 */
export async function addDocument(collectionName, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const colRef = collection(firestore, collectionName);
      const docRef = await addDoc(colRef, data);
      updateDoc(docRef, { id: docRef.id });
      resolve({ message: "Document added.", success: true, docId: docRef.id });
    } catch (error) {
      reject({ message: error.message, success: false });
    }
  })
}

/**
 * sets a document on firestore to the given data
 * @param {string} docPath the location of the document
 * @param {object} data the data to be set
 * @returns {{success: boolean, message: string}} an object recording the outcome
 */
export async function setDocument(docPath, data, merge = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestore, docPath);
      await setDoc(docRef, data, {merge: merge});
      resolve({ message: "Document set.", success: true });
    } catch (error) {
      reject({ message: error.message, success: false });
    }
  })
}

/**
 * updates a document to match the given fields on firestore
 * @param {string} docPath the location of the document
 * @param {object} data the data to be updated
 * @returns {{success: boolean, message: string}} an object recording the outcome
 */
export async function updateDocument(docPath, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestore, docPath);
      await updateDoc(docRef, data);
      resolve ({ message: "Document updated.", success: true });
    } catch (error) {
      reject ({ message: error.message, success: false });
    }
  })
}

/**
 * Checks to see whether a user exists in Firebase Auth
 * @param {string} email the email of the user being checked
 * @returns {{success: boolean, message: string, userExists: boolean}} an object recording the outcome and whether the user exists
 */
export async function checkUserExists(email) {
  try {
    const userList = await fetchSignInMethodsForEmail(auth, email);
    return {success: true, userExists: (userList.length > 0)};
  } catch (error) {
    return {success: false, message: error.message};
  }
}

export async function sendResetPasswordEmail(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return { message: "email sent", success: true };
  } catch (error) {
    return error;
  }
}

export async function handleResetPassword(actionCode, newPassword) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  return new Promise((resolve, reject) => {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;

        // Save the new password.
        confirmPasswordReset(auth, actionCode, newPassword)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.
            // TODO: Display a link back to the app, or sign-in the user directly
            resolve({
              result: "success",
              message: "Password successfully reset",
            });
          })
          .catch((error) => {
            resolve({
              result: "fail",
              message:
                "Password reset failed, code may have expired or password is too weak",
            });

            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
          });
      })
      .catch((error) => {
        console.log(error);
        resolve({ result: "fail", message: "Invalid or expired action code" });

        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  });
  // return("AAAA")
}

export { auth, firestore, firebase };
