import React, { useEffect, useState } from "react";
import FilterBar from "../../components/filters/FilterBar";
import LiveCompaniesTable from "../../components/table/LiveCompaniesTable";
import "./styles/Companies.css";
import {
  addDocument,
  setDocument,
  getDocument,
  checkUserExists,
} from "../../firebase";
import AddCompanyForm from "../../components/forms/companies/AddCompanyForm";
import PageTitle from "../../components/pageTitle/PageTitle";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import CompanyStepper from "../../components/steppers/CompanyStepper";
import { useParams } from "react-router-dom";

export default function EditCompany(props) {
  const [company, setCompany] = useState(false);

  const params = useParams();

  useEffect(async () => {
    await getDocument("Companies/" + params.companyId).then((result) => {
      setCompany(result.data);
    });
  }, []);

  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          text="Edit Company "
          confirmNav={true}
          links={[
            { url: "/", text: "Home | " },
            { url: "/companies", text: "Companies | " },
            { url: window.location.pathname, text: "Edit Company" },
          ]}
        />
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"> </div>
      </div> */}
      {company && <CompanyStepper company={company} />}
    </PageTemplate>
  );
}
