// Importing React Libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Importing Local Routes
import App from "./App";
import Login from "./pages/login/Login";
// Importing CSS Style Sheet
import "./index.css";
import Error from "./pages/Error";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/emailLanding/ResetPassword";
import EmailLanding from "./pages/emailLanding/EmailLanding";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
        <Route path="/emailLanding/:params" element={<EmailLanding />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
