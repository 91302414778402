import React, { useState } from "react";
import FilterBar from "../../components/filters/FilterBar";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import UserSidebar from "../../components/sidebar/UserSidebar";
import CompanyStepper from "../../components/steppers/CompanyStepper";
import LeadsTable from "../../components/table/LeadsTable";
import TopNav from "../../components/topnav/TopNav";

function UserLeads(props) {
  const [sidebarClass, setSidebarClass] = useState(
    window.innerWidth > 1023 ? "" : "sidebar-minimize"
  );
  return (
    <PageTemplate navigate={props.navigate}>
      <div className="quick-buttons">
        <PageTitle
          navigate={props.navigate}
          text="Leads Inbox "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/leads", text: "leads" },
          ]}
        />
      </div>
      {/* <div className="filter-bar-container">
        <FilterBar />
        <div className="underline-flex"></div>
      </div> */}
      <LeadsTable companyId={props.companyId}/>
      <div className="home-widgets"></div>
    </PageTemplate>
  );
}

export default UserLeads;
