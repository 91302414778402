const stylesForSelect = (error) => {
    return {
        control: (styles) => ({
            ...styles,
            width: "100%",
            marginBottom: "0px",
            borderStyle: "none",
            borderRadius: "5px",
            padding: "0rem",
            fontSize: "16px",
            backgroundColor: error ? "#ffddd6" : "#f1f1f1",
            color: "rgba(1, 54, 91, 0.55)",
            border: "1px solid #D4d4d4",
        }),
        option: (styles) => ({
            ...styles,
            width: "100%",
            fontSize: "16px",
            backgroundColor: "#f1f1f1",
            "&:hover": { backgroundColor: "lightgrey" },
            color: "rgba(1, 54, 91, 0.55)",
            border: "1px solid #f0f0f0",
            cursor: 'pointer'
        }),
    };
};

export { stylesForSelect }