import React, { useState, useEffect } from "react";
import CompanyProfileCard from "../../components/cards/CompanyProfileCard";
import LineChart from "../../components/charts/LineChart";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useSelector } from 'react-redux';

import PageTitle from "../../components/pageTitle/PageTitle";
import DashboardAnalytics from "../../components/dashboard/analytics/DashboardAnalytics";

function UserDashboard({ products, setQuery, resetQuery }) {
  const company = useSelector((state) => state.company);
  const user = useSelector(state => state.user);

  useEffect(() => {
    setQuery(query(collection(firestore, "Products"), where("status", "==", 'processing'), where("company", "==", company.id)));
    return resetQuery;
  }, []);

  return (
    <PageTemplate>
      <div className="quick-buttons">
        <PageTitle
          text={"Welcome back, " + user.first_name + " "}
          links={[]}
        />
      </div>
      <div className="dashboard-grid">
        <div className="dashboard-grid-left">
          <CompanyProfileCard />
        </div>
        <div className="dashboard-grid-right">
          <DashboardAnalytics/>
        </div>
      </div>
      {/* <div className="home-lower-body large-screen-only">
        <ProductRejectionTable products={products} />
      </div> */}
    </PageTemplate>
  );
}

export default UserDashboard;
