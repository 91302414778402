import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import NewMaterialForm from "../../components/forms/coloursMaterials/NewMaterialForm";
import PageTemplate from "../../components/pageTemplate/pageTemplate";
import PageTitle from "../../components/pageTitle/PageTitle";
import MaterialsTable from "../../components/table/MaterialsTable";
import { firestore } from "../../firebase";

export default function UserMaterials(props) {
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [showEditMaterial, setShowEditMaterial] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [archived, setArchived] = useState(false)
  const materialsRef = collection(
    firestore,
    "Companies/" + props.company.id + "/materials"
  );


  // Get realtime data
  function getData(collectionRef, setData) {
    return onSnapshot(
      collectionRef,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setData(items);
      },
      (error) => {
        console.error(error)
      }
    );
  }

  useEffect(() => {
    let unsubscribe = getData(materialsRef, setMaterials);
    return () => unsubscribe();
  }, []);

  return (
    <PageTemplate
      navigate={showAddMaterial || showEditMaterial ? props.navigate : null}
    >
      <div className="quick-buttons">
        <PageTitle
          navigate={showAddMaterial || showEditMaterial ? props.navigate : null}
          text="Materials "
          links={[
            { url: "/user/dashboard", text: "Home | " },
            { url: "/user/settings", text: "Settings | " },
            { url: "/user/settings/materials", text: "Materials" },
          ]}
        />
        <button
          className="add-company-button"
          onClick={() => setShowAddMaterial(true)}
        >
          Add New Material
        </button>
      </div>
      {showAddMaterial && (
        <NewMaterialForm
          setInitialValues={setInitialValues}
          initialValues={initialValues}
          company={props.company}
          showElement={setShowAddMaterial}
        />
      )}
      <MaterialsTable
        editMaterial={setShowAddMaterial}
        setValues={setInitialValues}
        materials={materials}
        company={props.company}
        archived={archived}
      />
      <div>
        <a style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => setArchived(!archived)}>{archived ? "Hide Archived Materials" : "Show Archived Materials"}</a>
      </div>
    </PageTemplate>
  );
}
