import React from "react";

export default function TimeZoneList({
  selectedTimezone,
  setSelectedTimeZone,
}) {
  return (
    <select
      name="timeZone"
      className="input-style"
      value={selectedTimezone}
      onChange={setSelectedTimeZone}
    >
      <option value="-12 International Date Line West">
        (GMT-12:00) International Date Line West
      </option>
      <option value="-11 Midway Island, Samoa">
        (GMT-11:00) Midway Island, Samoa
      </option>
      <option value="-10 Hawaii">(GMT-10:00) Hawaii</option>
      <option value="-9 Alaska">(GMT-09:00) Alaska</option>
      <option value="-8 Pacific Time (US & Canada)">
        (GMT-08:00) Pacific Time (US & Canada)
      </option>
      <option value="-8 Tijuana, Baja California">
        (GMT-08:00) Tijuana, Baja California
      </option>
      <option value="-7 Arizona">(GMT-07:00) Arizona</option>
      <option value="-7 Chihuahua, La Paz, Mazatlan">
        (GMT-07:00) Chihuahua, La Paz, Mazatlan
      </option>
      <option value="-7 Mountain Time (US & Canada)">
        (GMT-07:00) Mountain Time (US & Canada)
      </option>
      <option value="-6 Central America">(GMT-06:00) Central America</option>
      <option value="-6 Central Time (US & Canada)">
        (GMT-06:00) Central Time (US & Canada)
      </option>
      <option value="-6 Guadalajara, Mexico City, Monterrey">
        (GMT-06:00) Guadalajara, Mexico City, Monterrey
      </option>
      <option value="-6 Saskatchewan">(GMT-06:00) Saskatchewan</option>
      <option value="-5 Bogota, Lima, Quito, Rio Branco">
        (GMT-05:00) Bogota, Lima, Quito, Rio Branco
      </option>
      <option value="-5 Eastern Time (US & Canada)">
        (GMT-05:00) Eastern Time (US & Canada)
      </option>
      <option value="-5 Indiana (East)">(GMT-05:00) Indiana (East)</option>
      <option value="-4 Atlantic Time (Canada)">
        (GMT-04:00) Atlantic Time (Canada)
      </option>
      <option value="-4 Caracas, La Paz">(GMT-04:00) Caracas, La Paz</option>
      <option value="-4 Manaus">(GMT-04:00) Manaus</option>
      <option value="-4 Santiago">(GMT-04:00) Santiago</option>
      <option value="-3.5 Newfoundland">(GMT-03:30) Newfoundland</option>
      <option value="-3 Brasilia">(GMT-03:00) Brasilia</option>
      <option value="-3 Buenos Aires, Georgetown">
        (GMT-03:00) Buenos Aires, Georgetown
      </option>
      <option value="-3 Greenland">(GMT-03:00) Greenland</option>
      <option value="-3 Montevideo">(GMT-03:00) Montevideo</option>
      <option value="-2 Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
      <option value="-1 Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
      <option value="-1 Azores">(GMT-01:00) Azores</option>
      <option value="0 Casablanca, Monrovia, Reykjavik">
        (GMT+00:00) Casablanca, Monrovia, Reykjavik
      </option>
      <option value="0 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London">
        (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London
      </option>
      <option value="1 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">
        (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
      </option>
      <option value="1 Belgrade, Bratislava, Budapest, Ljubljana, Prague">
        (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
      </option>
      <option value="1 Brussels, Copenhagen, Madrid, Paris">
        (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
      </option>
      <option value="1 Sarajevo, Skopje, Warsaw, Zagreb">
        (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
      </option>
      <option value="1 West Central Africa">
        (GMT+01:00) West Central Africa
      </option>
      <option value="2 Amman">(GMT+02:00) Amman</option>
      <option value="2 Athens, Bucharest, Istanbul">
        (GMT+02:00) Athens, Bucharest, Istanbul
      </option>
      <option value="2 Beirut">(GMT+02:00) Beirut</option>
      <option value="2 Cairo">(GMT+02:00) Cairo</option>
      <option value="2 Harare, Pretoria">(GMT+02:00) Harare, Pretoria</option>
      <option value="2 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">
        (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
      </option>
      <option value="2 Jerusalem">(GMT+02:00) Jerusalem</option>
      <option value="2 Minsk">(GMT+02:00) Minsk</option>
      <option value="2 Windhoek">(GMT+02:00) Windhoek</option>
      <option value="3 Kuwait, Riyadh, Baghdad">
        (GMT+03:00) Kuwait, Riyadh, Baghdad
      </option>
      <option value="3 Moscow, St. Petersburg, Volgograd">
        (GMT+03:00) Moscow, St. Petersburg, Volgograd
      </option>
      <option value="3 Nairobi">(GMT+03:00) Nairobi</option>
      <option value="3 Tbilisi">(GMT+03:00) Tbilisi</option>
      <option value="3.5 Tehran">(GMT+03:30) Tehran</option>
      <option value="4 Abu Dhabi, Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
      <option value="4 Baku">(GMT+04:00) Baku</option>
      <option value="4 Yerevan">(GMT+04:00) Yerevan</option>
      <option value="4.5 Kabul">(GMT+04:30) Kabul</option>
      <option value="5 Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
      <option value="5 Islamabad, Karachi, Tashkent">
        (GMT+05:00) Islamabad, Karachi, Tashkent
      </option>
      <option value="5.5 Sri Jayawardenapura">
        (GMT+05:30) Sri Jayawardenapura
      </option>
      <option value="5.5 Chennai, Kolkata, Mumbai, New Delhi">
        (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
      </option>
      <option value="5.75 Kathmandu">(GMT+05:45) Kathmandu</option>
      <option value="6 Almaty, Novosibirsk">
        (GMT+06:00) Almaty, Novosibirsk
      </option>
      <option value="6 Astana, Dhaka">(GMT+06:00) Astana, Dhaka</option>
      <option value="6.5 Yangon (Rangoon)">(GMT+06:30) Yangon (Rangoon)</option>
      <option value="7 Bangkok, Hanoi, Jakarta">
        (GMT+07:00) Bangkok, Hanoi, Jakarta
      </option>
      <option value="7 Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
      <option value="8 Beijing, Chongqing, Hong Kong, Urumqi">
        (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
      </option>
      <option value="8 Kuala Lumpur, Singapore">
        (GMT+08:00) Kuala Lumpur, Singapore
      </option>
      <option value="8 Irkutsk, Ulaan Bataar">
        (GMT+08:00) Irkutsk, Ulaan Bataar
      </option>
      <option value="8 Perth">(GMT+08:00) Perth</option>
      <option value="8 Taipei">(GMT+08:00) Taipei</option>
      <option value="9 Osaka, Sapporo, Tokyo">
        (GMT+09:00) Osaka, Sapporo, Tokyo
      </option>
      <option value="9 Seoul">(GMT+09:00) Seoul</option>
      <option value="9 Yakutsk">(GMT+09:00) Yakutsk</option>
      <option value="9.5 Adelaide">(GMT+09:30) Adelaide</option>
      <option value="9.5 Darwin">(GMT+09:30) Darwin</option>
      <option value="10 Brisbane">(GMT+10:00) Brisbane</option>
      <option value="10 Canberra, Melbourne, Sydney">
        (GMT+10:00) Canberra, Melbourne, Sydney
      </option>
      <option value="10 Hobart">(GMT+10:00) Hobart</option>
      <option value="10 Guam, Port Moresby">
        (GMT+10:00) Guam, Port Moresby
      </option>
      <option value="10 Vladivostok">(GMT+10:00) Vladivostok</option>
      <option value="11 Magadan, Solomon Is., New Caledonia">
        (GMT+11:00) Magadan, Solomon Is., New Caledonia
      </option>
      <option value="12 Auckland, Wellington">
        (GMT+12:00) Auckland, Wellington
      </option>
      <option value="12 Fiji, Kamchatka, Marshall Is.">
        (GMT+12:00) Fiji, Kamchatka, Marshall Is.
      </option>
      <option value="13 Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
    </select>
  );
}
