import React from "react";
import "./hamburgerMenu.css";
import { useSelector } from "react-redux";
import { div, useNavigate } from "react-router-dom";
import {
  LineStyleRounded,
  StoreRounded,
  ShoppingCartRounded,
  SettingsApplicationsRounded,
  GroupRounded,
  SellRounded,
  TimelineRounded,
  ExitToAppRounded,
  AppSettingsAlt,
} from "@mui/icons-material";

function HamburgerMenu(props) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  function clickHandler(url) {
    if (props.navigate) {
      props.navigate(url);
    } else {
      navigate(url);
    }
  }

  return (
    <div
      style={{ display: props.display ? "block" : "none", width: props.width }}
      className="hamburger-menu small-screen-only"
    >
      {user ? (
        user.role !== "actuality_admin" ? (
          <div>
            <div
              className="links"
              onClick={() => clickHandler("/user/dashboard")}
            >
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <LineStyleRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Dashboard</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/user/products")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <ShoppingCartRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Products</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/user/leads")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <SellRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Leads</div>
                </div>
              </div>
            </div>
            {/* <div onClick={() => clickHandler("/user/appsettings")} className="links">
              <li className="sidebar-item">
                <AppSettingsAltIcon className="sidebar-icon"></AppSettingsAltIcon>
                App
              </li>
            </div> */}
            <div className="links" onClick={() => clickHandler("/user/appsettings")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <AppSettingsAlt sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">App</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/user/team")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <GroupRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Team</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/user/settings")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <SettingsApplicationsRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Settings</div>
                </div>
              </div>
            </div>
            <div onClick={props.logoutButton} className="hm-item logout-button">
              <div className="hm-item-content">
                <div className="hm-item-icon">
                  <ExitToAppRounded sx={{ fontSize: 20 }} />
                </div>
                <div className="hm-item-text">Logout</div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="links" onClick={() => clickHandler("/home")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <LineStyleRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Dashboard</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/companies")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <StoreRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Companies</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/products")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <ShoppingCartRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Products</div>
                </div>
              </div>
            </div>
            <div className="links" onClick={() => clickHandler("/analytics")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <TimelineRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Analytics</div>
                </div>
              </div>
            </div>
            
            <div className="links" onClick={() => clickHandler("/settings")}>
              <div className="hm-item">
                <div className="hm-item-content">
                  <div className="hm-item-icon">
                    <SettingsApplicationsRounded sx={{ fontSize: 20 }} />
                  </div>
                  <div className="hm-item-text">Settings</div>
                </div>
              </div>
            </div>
            <div onClick={props.logoutButton} className="hm-item logout-button">
              <div className="hm-item-content">
                <div className="hm-item-icon">
                  <ExitToAppRounded sx={{ fontSize: 20 }} />
                </div>
                <div className="hm-item-text">Logout</div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default HamburgerMenu;
