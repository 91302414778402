import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import { useState } from "react";
import Select from "react-select";
import { stylesForSelect } from "../../../styles/select";

export default function BasinRelatedProducts({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "basin_related_products";
  const conditions = {};

  const [selectedType, setSelectedType] = useState("tile");
  const [basinRelated, setBasinRelated] = useState([
    { name: "vanity", value: [] },
    { name: "waste clicker", value: [] },
    { name: "tap", value: [] },
  ]);

  const formik = useFormik({
    initialValues: initialValues || {
      basinRelated: [
        { name: "vanity", value: [] },
        { name: "waste clicker", value: [] },
        { name: "tap", value: [] },
      ],
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <span className="form-mini-title">
              Related Products{" "}
              <span
                style={{ fontWeight: "500" }}
                className="onboarding-subheading"
              >
                (Individual or Groups)
              </span>
            </span>
            <div className="form-items">
              {formik.values.basinRelated.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ justifyContent: "flex-start" }}
                    className="mini-horizontal-flex"
                  >
                    <div
                      className="onboarding-subheading"
                      style={{ width: "10.23rem", marginLeft: "1.23rem" }}
                    >
                      {item.name}
                    </div>
                    <div style={{ flex: "1", paddingLeft: "10px" }}>
                      <Select
                        isClearable
                        styles={stylesForSelect(formik.errors.basinRelated)}
                        onChange={(value) => {
                          formik.values.basinRelated.find(
                            (x) => x.name === item.name
                          ).value = value;
                          setBasinRelated(formik.values.basinRelated.slice());
                        }}
                        options={props.products
                          .filter((product) => product.productType == item.name)
                          .map((product) => {
                            return {
                              label: product.details.productname,
                              value: product.id,
                            };
                          })}
                        value={
                          formik.values.basinRelated.find(
                            (x) => x.name === item.name
                          ).value
                        }
                        isMulti={true}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                style={{ justifyContent: "flex-start" }}
                className="mini-horizontal-flex"
              >
                <div>
                  <button
                    onClick={() => {
                      if (
                        formik.values.basinRelated.filter(
                          (item) => item.name == selectedType
                        ).length < 1
                      ) {
                        formik.values.basinRelated.push({
                          name: selectedType,
                          value: [],
                        });
                        const vanRel = formik.values.basinRelated.slice();
                        setBasinRelated(vanRel);
                      } else {
                        console.log("Product type already exists");
                      }
                    }}
                    type="submit"
                    className="add-company-button-right"
                  >
                    Add Type
                  </button>
                </div>
                <div style={{ flex: "1", paddingLeft: "10px" }}>
                  <select
                    name="productTypes"
                    style={{ display: "block" }}
                    type="name"
                    className="form-select-style"
                    onChange={(e) => setSelectedType(e.target.value)}
                    value={selectedType}
                  >
                    <option value="tile" label="Tile" />
                    <option value="3D" label="Default 3D" />
                    <option
                      value="wallpaper"
                      label="Default Flat (Wallpaper)"
                    />
                    <option value="paint" label="Paint" />
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
