import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import { formikSubmit, formikValidate } from "../../../helpers/form_utils";
import { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getDocument, setDocument, updateDocument } from "../../../firebase";
import { arrayUnion } from "firebase/firestore";
import { stylesForSelect } from "../../../styles/select";

export default function PaverSpecs({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "paver_specs";
  const conditions = {};

  const [paverUseOptions, setPaverUseOptions] = useState([
    { label: "Pools", value: "pools" },
    { label: "Paths", value: "paths" },
  ]);
  const [paverFinishOptions, setPaverFinishOptions] = useState([
    { label: "Smooth", value: "smooth" },
  ]);
  const [paverSealOptions, setPaverSealOptions] = useState([
    { label: "Honed", value: "honed" },
    { label: "Sealed", value: "sealed" },
  ]);
  const [paverMaterialOptions, setPaverMaterialOptions] = useState([
    { label: "Concrete", value: "concrete" },
    { label: "Porcelaine", value: "porcelaine" },
    { label: "Bluestone", value: "bluestone" },
    { label: "Black Basalt", value: "blackBasalt" },
  ]);

  const [options, setOptions] = useState([]);
  const formik = useFormik({
    initialValues: initialValues || {
      paverUse: "",
      paverSize: "",
      paverThickness: 0,
      squareMeters: 0,
      paverEdge: "",
      paverShape: "",
      paverVariations: 0,
      paverSlip: "",
      paverFinish: "",
      paverMaterial: "",
      paverSeal: "",
      weight: 0,
      wastage: 0,
      waterAbsorption: 0,
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  function handleCreateOption(label, type, name) {
    const newOption = {
      label: label,
      value: label.toLowerCase().replace(/\W/g, ""),
    };
    // TODO add a way for users to remove categories?
    setDocument(
      "Companies/" + props.company.id + "/configurations/" + type,
      {
        [type]: arrayUnion(newOption),
      },
      true
    ).then((result) => {
      console.log(result);
      getOptions([type]);
    });
    formik.setFieldValue(name, newOption);
  }

  function getOptions(names) {
    getDocument("Configuration/paver-options").then((result) => {
      if (result.success) {
        setOptions(result.data);
      } else {
        console.log(result.message);
      }
    });

    names.forEach((name) => {
      getDocument(
        "Companies/" + props.company.id + "/configurations/" + name
      ).then((result) => {
        if (result) {
          if (name === "paverUse" && result.data.paverUse) {
            setPaverUseOptions([
              { label: "Pools", value: "pools" },
              { label: "Paths", value: "paths" },
              ...result.data.paverUse,
            ]);
          } else if (name === "paverFinish" && result.data.paverFinish) {
            setPaverFinishOptions([
              { label: "Smooth", value: "smooth" },
              ...result.data.paverFinish,
            ]);
          } else if (name === "paverSeal" && result.data.paverFinish) {
            setPaverSealOptions([
              { label: "Honed", value: "honed" },
              { label: "Sealed", value: "sealed" },
              ...result.data.paverSeal,
            ]);
          } else if (name === "paverMaterial" && result.data.paverMaterial) {
            setPaverMaterialOptions([
              { label: "Concrete", value: "concrete" },
              { label: "Porcelaine", value: "porcelaine" },
              { label: "Bluestone", value: "bluestone" },
              { label: "Black Basalt", value: "blackBasalt" },
              ...result.data.paverMaterial,
            ]);
          }
        }
      });
    });
  }

  useEffect(() => {
    getOptions(["paverUse", "paverFinish", "paverSeal", "paverMaterial"]);

    var inputTypeNumbers = document.querySelectorAll("input[type=number]");

    for (var a = 0; a < inputTypeNumbers.length; a++) {
      inputTypeNumbers[a].onwheel = function (event) {
        event.target.blur();
      };
    }
  }, []);


  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Specifications </h6>
            <div className="form-elements-flex">
              <div className="left-elements">
                <div className="form-column-flex">
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Use</span>
                    <CreatableSelect
                      isClearable
                      value={paverUseOptions.find(
                        (item) => item.value == formik.values.paverUse
                      )}
                      onChange={(value) =>
                        formik.setFieldValue("paverUse", value.value)
                      }
                      onCreateOption={(value) => {
                        handleCreateOption(value, "paverUse", "paverUse");
                      }}
                      styles={stylesForSelect(formik.errors.paverUse)}
                      options={paverUseOptions}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Thickness</span>
                    <Select
                      isClearable
                      value={
                        options.thicknesses &&
                        options.thicknesses.find(
                          (item) => item.value == formik.values.paverThickness
                        )
                      }
                      onChange={(value) =>
                        formik.setFieldValue("paverThickness", value.value)
                      }
                      styles={stylesForSelect(formik.errors.paverThickness)}
                      options={options.thicknesses}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Edge</span>
                    <Select
                      isClearable
                      value={
                        options.edges &&
                        options.edges.find(
                          (item) => item.value == formik.values.paverEdge
                        )
                      }
                      onChange={(value) =>
                        formik.setFieldValue("paverEdge", value.value)
                      }
                      styles={stylesForSelect(formik.errors.paverEdge)}
                      options={options.edges}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Variations</span>
                    <input
                      type="number"
                      name="paverVariations"
                      className={
                        formik.errors.paverVariations
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.paverVariations}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Finish</span>
                    <CreatableSelect
                      isClearable
                      value={paverFinishOptions.find(
                        (item) => item.value === formik.values.paverFinish
                      )}
                      onChange={(value) =>
                        formik.setFieldValue("paverFinish", value.value)
                      }
                      onCreateOption={(value) => {
                        handleCreateOption(value, "paverFinish", "paverFinish");
                      }}
                      styles={stylesForSelect(formik.errors.paverFinish)}
                      options={paverFinishOptions}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Seal</span>
                    <CreatableSelect
                      isClearable
                      value={paverSealOptions.find(
                        (item) => item.value == formik.values.paverSeal
                      )}
                      onChange={(value) =>
                        formik.setFieldValue("paverSeal", value.value)
                      }
                      onCreateOption={(value) => {
                        handleCreateOption(value, "paverSeal", "paverSeal");
                      }}
                      styles={stylesForSelect(formik.errors.paverSeal)}
                      options={paverSealOptions}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Wastage %</span>
                    <input
                      type="number"
                      min={0}
                      max={100}
                      name="wastage"
                      className={
                        formik.errors.wastage
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.wastage}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="right-elements">
                <div className="form-column-flex">
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Size</span>
                    <Select
                      isClearable
                      value={
                        options.sizes &&
                        options.sizes.find(
                          (item) => item.value == formik.values.paverSize
                        )
                      }
                      onChange={(value) =>
                        formik.setFieldValue("paverSize", value.value)
                      }
                      styles={stylesForSelect(formik.errors.paverSize)}
                      options={options.sizes}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">
                      Square Metres Per Box
                    </span>
                    <input
                      type="number"
                      name="squareMeters"
                      className={
                        formik.errors.squareMeters
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.squareMeters}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Shape</span>
                    <Select
                      isClearable
                      value={
                        options.shapes &&
                        options.shapes.find(
                          (item) => item.value == formik.values.paverShape
                        )
                      }
                      onChange={(value) =>
                        formik.setFieldValue("paverShape", value.value)
                      }
                      styles={stylesForSelect(formik.errors.paverShape)}
                      options={options.shapes}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Slip</span>
                    <input
                      name="paverSlip"
                      className={
                        formik.errors.paverSlip
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.paverSlip}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Paver Material</span>
                    <CreatableSelect
                      isClearable
                      value={paverMaterialOptions.find(
                        (item) => item.value == formik.values.paverMaterial
                      )}
                      onChange={(value) =>
                        formik.setFieldValue("paverMaterial", value.value)
                      }
                      onCreateOption={(value) => {
                        handleCreateOption(
                          value,
                          "paverMaterial",
                          "paverMaterial"
                        );
                      }}
                      styles={stylesForSelect(formik.errors.paverMaterial)}
                      options={paverMaterialOptions}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Weight Per Box</span>
                    <input
                      type="number"
                      name="weight"
                      className={
                        formik.errors.weight
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.weight}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="form-column-flex">
                    <span className="form-mini-title">Water Absorption %</span>
                    <input
                      type="number"
                      min={0}
                      max={100}
                      name="waterAbsorption"
                      className={
                        formik.errors.waterAbsorption
                          ? "error form-element-style"
                          : "form-element-style"
                      }
                      style={{ marginBottom: "0rem" }}
                      onChange={formik.handleChange}
                      value={formik.values.waterAbsorption}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
