import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import './styles/ProductFormStyles.css';
import FileUploader from '../files/FileUploader';
import { notEmpty, formikSubmit, formikValidate} from '../../../helpers/form_utils';
import ListFiles from '../files/ListFiles';
import { getStorage, ref, getBlob } from "firebase/storage";


const acceptedFileSizeMB = 20;
const acceptedFileSize = acceptedFileSizeMB * 1024 * 1024;
function ARInfoWallpaper({submitting, setStatus, initialValues}) {
    const component_key = 'ar_wallpaper';
    const [images, setImages] = useState([]);

    useEffect(() => {
        if(initialValues) {
            const storage = getStorage();
            let files = [];
            initialValues.images.map((path, index) => {
                const fileRef = ref(storage, path);
                getBlob(fileRef).then(file => {
                    files[index] = file;
                    updateFiles('images', files);
                }).catch((error) => {
                    console.log(error.message);
                });
            })
        }
    }, []);
    
    const conditions = {
        images: [notEmpty,],
    };

    useEffect(() => {
        if (submitting) {
            formik.handleSubmit();
        }
    }, [submitting]);

    const formik = useFormik({
        initialValues: initialValues || {
            height: initialValues ? initialValues.height : 0,
            width: initialValues ? initialValues.width : 0,
            length: initialValues ? initialValues.length :  0,
            repeat: initialValues ? initialValues.repeat : 0,
            images: [],
        },

        validate : values => {
            return formikValidate(values, submitting, component_key, setStatus, conditions, formik);
        },

        onSubmit: values => {
            formikSubmit(values, formik, setStatus, component_key, conditions);
        },
    });

    function handleSubmit(e) {
        e.preventDefault();
    }

    function uploadImages(files) {
        let acceptedFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            if (['image/png', 'image/jpeg', 'application/zip'].includes(file.type) && (file.size <= acceptedFileSize)) {
                acceptedFiles.push(file);
            } else {console.log('file rejected')}
        }
        let allFiles = formik.values.images.concat(acceptedFiles);
        formik.values.images = allFiles;
        setImages(allFiles);
    }

    function updateFiles(fileType, data) {
        formik.values[fileType] = data;
        setImages([...data]);
    }

    return (
        <div className='company-onboarding-container'>
            <form onSubmit={handleSubmit}> 
            <div className="form-block">
                    <div className="form-items"> 
                        
                       <div className="mini-horizontal-flex">

                       <div className="select-title-flex">
                            <span className='form-mini-title'>Height</span>
                            <div className="input-adornment">
                            <span className="adornment-text">mm</span>
                            <input type="number" name="height" className="form-element-style-margin"  onChange={formik.handleChange} value={formik.values.height} onBlur={formik.handleBlur}/></div></div>
                            <div className="select-title-flex">
                            <span className='form-mini-title'>Width</span>
                            <div className="input-adornment">
                            <span className="adornment-text">mm</span>
                            <input type="number" name="width" className="form-element-style-margin"  onChange={formik.handleChange} value={formik.values.width} onBlur={formik.handleBlur}/></div></div>
                            <div className="select-title-flex">
                            <span className='form-mini-title'>Length</span>
                            <div className="input-adornment">
                            <span className="adornment-text">mm</span>
                            <input type="number" name="length" className="form-element-style"  onChange={formik.handleChange} value={formik.values.length} onBlur={formik.handleBlur}/></div></div>
                            
                       </div>
                       <div className="form-underline-flex"></div>
                        <div className="mini-horizontal-flex">
                            <div className="select-title-flex">
                            <span className='form-mini-title'>Material *</span>
                        <p className='important-info-grey'> Images should be as detailed as possible (jpg, png).</p>       
                            <FileUploader setFiles={uploadImages}></FileUploader>
                            <ListFiles files={images} setFiles={(data) => updateFiles('images', data)}></ListFiles>
                            </div>

                            <div className="select-title-flex">
                            <span className='form-mini-title'>Image Repeat</span>
                            <div className="input-adornment">
                            <span className="adornment-text">mm</span>
                            <input type="number" name="repeat" className="form-element-style"  onChange={formik.handleChange} value={formik.values.repeat} onBlur={formik.handleBlur}/></div></div>
                        </div>
                        <p className='onboarding-subheading'></p>
                       
                            
                        </div>

                    
                </div>
            </form> 
        </div>
    )
}

export default ARInfoWallpaper

