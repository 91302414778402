import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import { getDocument } from "../../../firebase";
import { useState } from "react";

function PriceProductForm({ submitting, setStatus, initialValues, company }) {
  const component_key = "price";
  const [groups, setGroups] = useState([]);
  const unit = company.currency || "NZD";

  const conditions = {};

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    initialValues: initialValues || {
      costPrice: 0.00,
      displayPrice: "",
      salePrice: 0.00,
      soldPer: "",
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  function getOptions() {
    getDocument("Configuration/product-options").then((result) => {
      if (result.success) {
        setGroups(result.data.groups);
      } else {
        console.log(result.message);
      }
    });
  }

  function handlePriceChange(event) {
    //No DP, add one
    if (event.target.value.indexOf('.') == -1) {
      event.target.value = parseFloat(event.target.value).toFixed(2);
    }

    // DP not 2dp
    if ((event.target.value.length - event.target.value.indexOf('.')) > 2) {
      event.target.value = parseFloat(event.target.value).toFixed(2);
    }

    formik.handleChange(event)
  }

  function handlePriceChange(event) {
    formik.handleChange(event)
  }
  
  function handleSubmit(e) {
    e.preventDefault();
  }

  function currencyBlur(value, field) {
    if(!value.toString().includes(".")){
      formik.setFieldValue(field, value + ".00")
    }
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Pricing </h6>
            <p className="onboarding-subheading"> </p>

            <div className="form-column-flex">
              <div className="form-elements-flex">
                <div className="left-elements">
                  <div className="select-title-flex">
                    <span className="form-mini-title">Price</span>
                    <div className="input-adornment">
                      <span className="adornment-text">{unit}$</span>
                      <input
                        type="number"
                        name="costPrice"
                        className="form-element-style-margin"
                        onChange={handlePriceChange}
                        value={formik.values.costPrice}
                        onBlur={(value) => currencyBlur(value.target.value, "costPrice")}
                        step="0.01"
                      />
                    </div>
                  </div>

                  <div className="select-title-flex">
                    <span className="form-mini-title">Display Price Per</span>
                    <select
                      name="displayPrice"
                      value={formik.values.displayPrice}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ display: "block" }}
                      type="number"
                      className="form-select-style-margin"
                    >
                      <option
                        className="placeholder-text"
                        value=""
                        label="Select "
                      />
                      {groups.map((group) => {
                        return (
                          <option
                            label={group.label}
                            value={group.value}
                            key={group.value}
                          />
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="right-elements">
                  <div className="select-title-flex">
                    <span className="form-mini-title">
                      Compare Price (Sale)
                    </span>
                    <div className="input-adornment">
                      <span className="adornment-text">{unit}$</span>
                      <input
                        type="number"
                        name="salePrice"
                        className="form-select-style-margin"
                        onChange={formik.handleChange}
                        value={formik.values.salePrice}
                        onBlur={(value) => currencyBlur(value.target.value, "salePrice")}
                        step="0.01"
                      />
                    </div>
                  </div>

                  <div className="select-title-flex">
                    <span className="form-mini-title">
                      Sold Per (unit, box, m<sup>2</sup>, etc)
                    </span>
                    <select
                      name="soldPer"
                      value={formik.values.soldPer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{ display: "block" }}
                      type="name"
                      className="form-select-style"
                    >
                      <option
                        className="placeholder-text"
                        value=""
                        label="Select"
                      />
                      {groups.map((group) => {
                        return (
                          <option
                            label={group.label}
                            value={group.value}
                            key={group.value}
                          />
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PriceProductForm;
