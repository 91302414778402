import React from "react";
import SearchBar from "./SearchBar";

export default function TableSearch(props) {
    return(
        <div style={{ display: "flex", marginLeft: "1.23rem" }}>
          <span className="large-screen-only">{props.title}</span>
          <div className={"table-search-container large-screen-only"}>
            <SearchBar
              text={"Search"}
              onChange={(e) => {
                props.setSearch(e.target.value);
              }}
              value={props.search}
            />
          </div>
          <div className={"table-search-container small-screen-only"}>
            <SearchBar
              text={props.smallText}
              onChange={(e) => {
                props.setSearch(e.target.value);
              }}
              value={props.search}
            />
          </div>
        </div>
    )
}