import React, { useEffect } from "react";
import { useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

/**
 * Retrieves an image from firebase storage and displays it
 *
 * @param {{path: string, styleForImg: object, defaultImage: string}} param0 path: the firebase storage path to the image; styleForImg: css styles for the image displayed; defaultImage: the image to be displayed in case of error
 * @returns a component with the image found at the given path
 */
function FirebaseImage({ path, styleForImg, defaultImage }) {
  const [url, setUrl] = useState(defaultImage);
  const [loading, setLoading] = useState(false);
  const storage = getStorage();

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        if (path) {
          if (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'staging') {
            getDownloadURL(ref(storage, path)).then(url => {
              setUrl(url)
              setLoading(false)
            }).catch(err => console.log(err))
          } else {
            const dlUrl = "https://ik.imagekit.io/w07tnkgepgz/go/" + path
            setUrl(dlUrl)
            setLoading(false)
          }
  
        } else {
            setUrl(defaultImage);
            setLoading(false);
        }

        return () => { isMounted = false; }

      }, [path]);

    return <>{loading ? "Loading..." : <img src={url} alt="image" style={styleForImg}/>}</>
}

export default FirebaseImage;
