import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import './styles/ProductFormStyles.css';
import { notEmpty, formikSubmit, formikValidate} from '../../../helpers/form_utils';


function StatusProductForm({submitting, setStatus, initialValues}) {
    const component_key = 'status';
    
    const conditions = {
        draft : [notEmpty,],
    };

    useEffect(() => {
        if (submitting) {
            formik.handleSubmit();
        }
    }, [submitting]);

    const formik = useFormik({
        initialValues: initialValues || {
            draft:'',
        },

        validate : values => {
            return formikValidate(values, submitting, component_key, setStatus, conditions, formik);
        },

        onSubmit: values => {
            formikSubmit(values, formik, setStatus, component_key, conditions);
        },
    });

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <div className='company-onboarding-container'>
            <form onSubmit={handleSubmit}> 
            <div className="form-block">
                    <div className="form-items"> 

                        <h6  className='section-heading'> Status </h6> 
                        <p className='onboarding-subheading'></p>

                        <div className="form-elements-flex">
                            <div className="left-elements">

                            <div className="select-title-flex">
                            <span className='form-mini-title'>Ready to Launch? </span>
                            <select name="draft" value={formik.values.draft} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ display: 'block' }} type="name" className={formik.errors.draft ? "error form-select-style" : "form-select-style"}>
                                <option className='placeholder-text' value="" label="Select" />
                                <option value="draft" label="Draft" />
                                <option value="ready" label="Ready" />
                                <option value="live" label="Live" />
                            </select> </div> 
                            </div>
                       </div>
                       <p className='important-info-grey'>Once your product is ready, our team will review and approve for publsihing to an app or advise 3D file corrections needed.</p>
                        </div>

                    
                </div>
            </form> 
        </div>
    )
}

export default StatusProductForm
