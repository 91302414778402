import * as React from "react";
import "./TeamTable.css";
import Image from "../../images/logo/mobile.svg";
import BaseTable from "./BaseTable";
import { IconButton, TableCell } from "@mui/material";
import { Link, Outlet, useNavigate } from "react-router-dom";
import TableSearch from "../filters/TableSearch.jsx";
import { useState, useEffect } from "react";
import { deleteDocument, setDocument } from "../../firebase";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";
import { EditRounded } from "@mui/icons-material";

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "5px",
  objectFit: "contain",
  marginLeft: "1.23rem",
};

const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

function LiveCompaniesTable({ companies }) {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);
  const nav = useNavigate();

  const cells = [
    {
      id: "logo",
      numeric: false,
      disablePadding: true,
      label: "Logo",
      cell: (company) => (
        <TableCell
          className="image-cell"
          key={company.id + "_logo"}
          component="th"
          id={company.id}
          scope="row"
          padding="none"
          align="left"
        >
          <img src={company.logo || Image} alt="Logo" style={styleForImg} />
        </TableCell>
      ),
    },

    {
      id: "trading_name",
      numeric: false,
      disablePadding: true,
      label: "Trading Name",
      cell: (company) => (
        <TableCell
          key={company.id + "_tName"}
          align="center"
          className="table-cell"
        >
          <Link className="links" to={"/company/" + company.id}>
            {company.trading_name !== undefined ? company.trading_name : "N/A"}
          </Link>
        </TableCell>
      ),
    },
    {
      id: "num_products",
      numeric: true,
      disablePadding: false,
      label: "Products",
      cell: (company) => (
        <TableCell
          key={company.id + "_products"}
          align="center"
          className="table-cell"
        >
          {company.num_products !== undefined ? company.num_products : "N/A"}
        </TableCell>
      ),
    },

    {
      id: "num_apps",
      numeric: true,
      disablePadding: false,
      label: "Apps",
      cell: (company) => (
        <TableCell
          key={company.id + "_apps"}
          align="center"
          className="table-cell"
        >
          {company.num_apps !== undefined ? company.num_apps : "N/A"}
        </TableCell>
      ),
    },
    {
      id: "analytics.totalProductViewCounter",
      numeric: true,
      disablePadding: false,
      label: "Views",
      cell: (company) => (
        <TableCell
          key={company.id + "_views"}
          align="center"
          className="table-cell"
        >
          {company.analytics !== undefined ? company.analytics.totalProductViewCounter : "N/A"}
        </TableCell>
      ),
    },

    {
      id: "num_users",
      numeric: true,
      disablePadding: false,
      label: "Users",
      cell: (company) => (
        <TableCell
          key={company.id + "_users"}
          align="center"
          className="table-cell"
        >
          <Link className="links" to={"/company_users/" + company.id}>
            {company.num_users !== undefined ? company.num_users : "N/A"}
          </Link>
        </TableCell>
      ),
    },

    // {
    //   id: "num_sales",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "Sales",
    //   cell: (company) => (
    //     <TableCell
    //       key={company.id + "_sales"}
    //       align="center"
    //       className="table-cell"
    //     >
    //       {company.num_sales !== undefined ? company.num_sales : "N/A"}
    //     </TableCell>
    //   ),
    // },

    {
      id: "num_leads",
      numeric: true,
      disablePadding: false,
      label: "Leads",
      cell: (company) => (
        <TableCell
          key={company.id + "_leads"}
          align="center"
          className="table-cell"
        >
          {company.analytics && company.analytics.totalLeadsGenerated ? company.analytics.totalLeadsGenerated : 0}
        </TableCell>
      ),
    },

    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (company) => (
        <TableCell
          key={company.id + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            style={styleForIconButton}
            onClick={() => nav("/companies/edit/" + company.id)}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
  ];

  async function deleteCompany() {
    if (["company_admin", "actuality_admin"].includes(user.role)) {
      for (let index = 0; index < selectedCompanies.length; index++) {
        const company = selectedCompanies[index];
        if (archived) {
          await deleteDocument("/Companies/" + company).then(async (result) => {
            let companyDoc = companies.find((comp) => comp.id == company);
            await deleteFile(companyDoc.logo).then((result2) => {
              console.log(result, result2);
            });
          });
        } else {
          let companyDoc = companies.find((comp) => comp.id == company);
          companyDoc.archived = true;
          await setDocument("/Companies/" + company, companyDoc).then(
            (result) => {
              console.log(result);
            }
          );
        }
      }
      setShowModal(false);
      setSelectedCompanies([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  async function unarchiveFunc(companyList) {
    if (["company_admin", "actuality_admin"].includes(user.role)) {
      for (let index = 0; index < companyList.length; index++) {
        const company = companyList[index];
        let companyDoc = companies.find((comp) => comp.id == company);
        companyDoc.archived = false;
        await setDocument("/Companies/" + company, companyDoc).then(
          (result) => {
            console.log(result);
          }
        );
      }
      setSelectedCompanies([]);
    } else {
      window.alert("Only Admins can delete or archive items");
    }
  }

  return (
    <div>
      {/* TABLE DISPLAYS ARCHIVED COMPANIES */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Companies Onboarded"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Companies"}
            />
          }
          rows={companies.filter(
            (row) =>
              row.trading_name.toLowerCase().includes(search.toLowerCase()) &&
              row.archived
          )}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedCompanies(value);
          }}
          columns={cells}
          defaultOrder={"trading_name"}
          selectedItems={selectedCompanies}
          archived={archived}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS NON-ARCHIVED COMPANIES */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Companies Onboarded"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Companies"}
            />
          }
          rows={companies.filter(
            (row) =>
              row.trading_name.toLowerCase().includes(search.toLowerCase()) &&
              !row.archived
          )}
          columns={cells}
          defaultOrder={"trading_name"}
          archived={archived}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedCompanies(value);
          }}
          selectedItems={selectedCompanies}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Company" : "Archive Company"}
        message={
          archived
            ? "Are you sure you would like to delete this company?"
            : "Are you sure you would like to archive this company?"
        }
        confirm={deleteCompany}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Companies" : "Show Archived Companies"}
        </a>
      </div>
    </div>
  );
}

export default LiveCompaniesTable;
