import { useEffect, useState } from "react"
import "./styles/ProductFormStyles.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const QRCodeForm = ({ productId }) => {
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [shortlinkUrl, setShortlinkUrl] = useState(null);

    useEffect(() => {
        if (!productId) return;
        setQrCodeUrl("https://link.quickview.nz/qr/" + productId)
        setShortlinkUrl("https://link.quickview.nz/product/" + productId);

        if (process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'staging') {
            setQrCodeUrl("https://link.quickview.nz/qr/" + productId  + "?staging=true");
            setShortlinkUrl("https://link.quickview.nz/product/" + productId + "?staging=true");    
        }
    }, [productId])

    return (
        <div className="company-onboarding-container">
            <div className="form-block">
                <div className="form-items">

                    <h6 className="section-heading">Product QR Code</h6>

                    {productId ? <>
                        <p className="onboarding-subheading" style={{ paddingTop: '5px' }}>This QR code can be used to link directly to your product across our network</p>

                        {qrCodeUrl && <QRCode url={qrCodeUrl} />}
                        <a style={{ fontSize: '17px', marginTop: '15px' }} href={qrCodeUrl} target="_blank" download="1">Download</a>
                        <a style={{ fontSize: '12px', marginTop: '8px' }} href={shortlinkUrl} target="_blank">{shortlinkUrl}</a>
                    </> : <ProductDoesntExist />}
                </div>
            </div>
        </div>
    )
}

const QRCode = ({ url }) => {
    const [qrLoaded, setQrLoaded] = useState(false)

    return (
        <>
            { !qrLoaded && <CircularProgress style={{ marginTop: '10px', marginBottom: '10px' }}/> }
            <img onLoad={() => setQrLoaded(true)} src={url} style={{ maxWidth: '120px', borderRadius: '10px' }}/>
        </>
    )
}

const ProductDoesntExist = () => {
    return (
        <>
            <h6 className="section-heading" style={{paddingTop: '10px', fontSize: '14px'}}>When this product has been created you'll be able to download a QR code from here </h6>
        </>
    )
}

const buttonStyle = {
    marginTop: "1.23rem",
    marginRight: "4.23rem",
    width: "5.5rem",
    height: "2.23rem",
    fontSize: "10px",
    borderRadius: "44px",
    backgroundColor: "#01365B",
    color: "white",
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontWeight: "500",
    border: "none",
};


export default QRCodeForm