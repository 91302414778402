import React from "react";
import CompanyStepper from "../../components/steppers/CompanyStepper";
import TopNav from "../../components/topnav/TopNav";
import { useSelector } from "react-redux";
import OnboardingComplete from "../../components/steppers/OnboardingComplete";

function CompanyOnboarding() {
  const company = useSelector((state) => state.company);

  return (
    <div className="main-container">
      <TopNav />
      <div className="home-page">
        <div className="app-body">
          <div className="quick-buttons">
            <h1 className="display-title"> Company Onboarding </h1>
          </div>
          <div className="filter-bar-container">
            <div className="underline-flex"></div>
          </div>
          {company.onboarded ? <OnboardingComplete /> : <CompanyStepper />}
        </div>
      </div>
    </div>
  );
}

export default CompanyOnboarding;
