import React, { useEffect } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";
import { getDocument } from "../../../firebase";
import { useState } from "react";
import Select from "react-select";
import DocumentUploader from "../files/DocumentUploader";
import { stylesForSelect } from "../../../styles/select";

export default function VanitySpecs({
  initialValues,
  submitting,
  setStatus,
  ...props
}) {
  const component_key = "vanity_specs";
  const conditions = {};
  const [options, setOptions] = useState([]);
  const formik = useFormik({
    initialValues: initialValues || {
      vanityMounted: "wallHung",
      vanityHeightMin: 0,
      vanityHeightMax: 0,
      vanityLength: 0,
      vanityWidth: 0,
      vanityHeight: 0,
      vanitySpacing: 0,
      vanityFbx: "",
      vanityUsdz: "",
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  const updateFile = (fileKey, path) => {
    formik.values[fileKey] = path;
    console.log(formik.values)
    console.log(fileKey)
    console.log(path)
  }

  useEffect(() => {
    getOptions()
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  function getOptions() {
    getDocument("Configuration/vanity-options").then((result) => {
      if (result.success) {
        setOptions(result.data);
      } else {
        console.log(result.message);
      }
    });
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Product Specifications </h6>
            <p className="onboarding-subheading">
              Please enter as much information as possible
            </p>

            {/* MOUNTINGS */}
            <div className="form-elements-flex">
              <div className="left-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Mounted</span>

                  <Select
                    isClearable
                    value={
                      options.mounted &&
                      options.mounted.find(
                        (item) => item.value == formik.values.vanityMounted
                      )
                    }
                    onChange={(value) =>
                      formik.setFieldValue("vanityMounted", value.value)
                    }
                    styles={stylesForSelect(formik.errors.vanityMounted)}
                    options={options.mounted}
                  />
                </div>
              </div>
              <div className="right-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">
                    Mounting: Top Height (mm)
                  </span>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1", display: "flex" }}>
                      <span
                        style={{ paddingTop: "5px", paddingRight: "5px" }}
                        className="onboarding-subheading"
                      >
                        Min
                      </span>
                      <input
                        type="number"
                        name="vanityHeightMin"
                        className={
                          formik.errors.vanityHeightMin
                            ? "error form-element-style"
                            : "form-element-style"
                        }
                        onChange={formik.handleChange}
                        value={formik.values.vanityHeightMin}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div style={{ flex: "1", display: "flex" }}>
                      <span
                        style={{ paddingTop: "5px", paddingRight: "5px" }}
                        className="onboarding-subheading"
                      >
                        Max
                      </span>
                      <input
                        type="number"
                        name="vanityHeightMax"
                        className={
                          formik.errors.vanityHeightMax
                            ? "error form-element-style"
                            : "form-element-style"
                        }
                        onChange={formik.handleChange}
                        value={formik.values.vanityHeightMax}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* PHYSICAL SPECS */}
            <span className="form-mini-title">Dimensions (mm)</span>
            <div className="form-elements-flex">
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Length</span>

                <input
                  type="number"
                  name="vanityLength"
                  className={
                    formik.errors.vanityLength
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.vanityLength}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Width</span>

                <input
                  type="number"
                  name="vanityWidth"
                  className={
                    formik.errors.vanityWidth
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.vanityWidth}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div
                style={{ paddingRight: "5px" }}
                className="select-title-flex"
              >
                <span className="onboarding-subheading">Height</span>

                <input
                  type="number"
                  name="vanityHeight"
                  className={
                    formik.errors.vanityHeight
                      ? "error form-element-style"
                      : "form-element-style"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.vanityHeight}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div style={{ paddingLeft: "5px" }} className="select-title-flex">
                <span
                  style={{ marginBottom: "0rem" }}
                  className="form-mini-title"
                >
                  Spacing (mm)
                </span>

                <div style={{ display: "flex" }}>
                  <span
                    style={{ paddingRight: "5px" }}
                    className="onboarding-subheading"
                  >
                    Min. Gap to walls
                  </span>
                  <input
                    type="number"
                    name="vanitySpacing"
                    className={
                      formik.errors.vanitySpacing
                        ? "error form-element-style"
                        : "form-element-style"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.vanitySpacing}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>

            {/* PRODUCT 3D MODELS */}
            <span className="form-mini-title">Product 3D</span>
            <div className="form-elements-flex">
              <div className="right-elements">
                <div className="onboarding-subheading">
                  Add 3D FBX file (mobile app)
                </div>
                <div
                  className={
                    formik.errors.images
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                  <DocumentUploader
                    fileID={"vanityFbx"} 
                    fileName={"Vanity 3D Model"} 
                    productID={props.productId} 
                    onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}
                    accepts={"application/octet-stream"}
                    path={'/3D/'}
                    extension={".fbx"}
                  ></DocumentUploader>
                </div>
              </div>
              <div className="left-elements">
                <div className="onboarding-subheading">
                  Add 3D USDZ file (WebAr)
                </div>
                <div
                  className={
                    formik.errors.images
                      ? "error mini-horizontal-flex"
                      : "mini-horizontal-flex"
                  }
                >
                <DocumentUploader
                  fileID={"vanityUsdz"} 
                  fileName={"Vanity USDZ"} 
                  productID={props.productId} 
                  onFileUpdate={(fileKey, path) => updateFile(fileKey, path)}
                  accepts={"model/vnd.usdz+zip"}
                  extension={".usdz"}
                  path={'/3D/'}
                  ></DocumentUploader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
