import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import './styles/ProductFormStyles.css';
import { notEmpty, formikSubmit, formikValidate} from '../../../helpers/form_utils';



function CostingsProductForm({submitting, setStatus, initialValues}) {
    const component_key = 'costings';
    
    const conditions = {
    };

    useEffect(() => {
        if (submitting) {
            formik.handleSubmit();
        }
    }, [submitting]);

    const formik = useFormik({
        initialValues: initialValues || {
            costItem:'',
            commission:'',
            chargeTax: false,
            salesVisible: false,
        },

        validate : values => {
            return formikValidate(values, submitting, component_key, setStatus, conditions, formik);
        },

        onSubmit: values => {
            formikSubmit(values, formik, setStatus, component_key, conditions);
        },
    });

    function handleSubmit(e) {
        e.preventDefault();
    }

    return (
        <div className='company-onboarding-container'>
            <form onSubmit={handleSubmit}> 
            <div className="form-block">
                    <div className="form-items"> 
                    <h6  className='section-heading'> Costings </h6> 
                        <p className='onboarding-subheading'> </p>

                    <div className="form-column-flex">
                    <div className="form-elements-flex">
                            <div className="left-elements">

                            <div className="select-title-flex">
                            <span className='form-mini-title'>Cost Per Item</span>

                            <div className="input-adornment">
                            <span className="adornment-text">NZ$</span>
                            <input placeholder='45.00' type="name" name="costItem" className="form-element-style-margin" onChange={formik.handleChange} value={formik.values.costItem} onBlur={formik.handleBlur}/></div></div>
                            
                            <p className='important-info-grey'> Customers won't see this </p>
                            <div className="form-elements-flex">
                                
                            <input  type="checkbox" name="chargeTax" className="checkbox"  onChange={formik.handleChange} value={formik.values.chargeTax} onBlur={formik.handleBlur}/> 
                            <h6 className='mini-heading'> Charge tax on this product?</h6></div>
                            
                            </div>
                            

                            <div className="right-elements">
                            <div className="mini-horizontal-flex">
                            <div className="mini-left-elements">
                            <div className="select-title-flex">
                            <span className='form-mini-title'>Commision</span>
                            <div className="input-adornment">
                           <span className="adornment-text">%</span>
                            <input placeholder='5.00' type="name" min="0" max="100" step="1" name="commission" className="mini-element-comission" onChange={formik.handleChange} value={formik.values.commission} onBlur={formik.handleBlur}/></div></div></div>

                            {/* TODO make margin and profit depend on commission and cost */}
                            <div className="mini-right-elements">
                            <div className="select-title-flex">
                            <span className='form-mini-title'>Margin</span>
                            <div className="mini-display-box"> <p className='mini-box-text'> 83.9% </p></div>
                            </div>

                            <div className="select-title-flex">
                            <span className='form-mini-title'>Profit</span>
                            <div className="mini-display-box"> 
                            <p className='mini-box-text'> NZ$33.56 </p></div>
                            </div>
                            </div>
                            </div>
                            <div className="form-elements-flex">
                            <input  type="checkbox" name="salesVisible" className="checkbox"  onChange={formik.handleChange} value={formik.values.salesVisible} onBlur={formik.handleBlur}/> 
                            <div className="mini-heading-flex">
                            <h6 className='mini-heading'> Visible to Sales Agents?</h6>
                            <span className='mini-body'> Pay a commission of $6.44 / unit sold.</span></div></div>
                            </div>

                    </div>
                    
                    </div>
                    </div>
                </div>
            </form> 
        </div>
    )
}

export default CostingsProductForm

