import React, { useEffect, useState } from 'react';
import '../companies/AddCompanyForm.css';
import { CloseRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { notEmpty, emailFormat, numberFormat, validateItem, checkForm} from '../../../helpers/form_utils';
import ImageUploader from '../files/ImageUploader';
import { send } from 'emailjs-com';




function ContactActuality(showElement) {

    const [toSend, setToSend] = useState(true); // whether the form is visible or not

    const [loading, setLoading] = useState(false); // whether the function is loading

    const [error, setError] = useState({fName: false, email: false, subject: false, message: false}); // record of any invalid data

    // conditions each data value must meet to be valid
    const conditions = {
        fName: [notEmpty,],
        email: [notEmpty, emailFormat,],
        subject: [notEmpty,],
        message: [notEmpty],
    }

    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_vn6kphy', // EmailJs Service ID
            'template_zyrvus3', // EmailJS Template ID
            toSend,
            'uT3LZpj_JypJcoayT' // EmailJS User ID
          )
            .then((response) => {
              console.log('SUCCESS!', response.status, response.text);
            })
            .catch((err) => {
              console.log('FAILED...', err);
            });
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

    return (
        <div className='add-company-form-container'> 
            {
                toSend ?  <form className='form-elements' onSubmit={onSubmit}>
                    <div className="button-title-flex">
                <h5 className='add-company-form-title'> Need help?</h5>
                <Button type="button" onClick={() => showElement(false)}><CloseRounded></CloseRounded></Button>
                </div>
                <div className="form-input-style">
                    <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> Full Name: </label>
                        <input type="name" name="name" className={error.fName ? "error short-input" : "short-input"} onChange={e => handleChange('fName', e.target.value)} value={toSend.fName} /></div>
                    <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> Email: </label>
                        <input type="name" name="name" className={error.email ? "error short-input" : "short-input"} onChange={e => handleChange('email', e.target.value)}  value={toSend.email} /></div>
                    <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> Subject: </label>
                        <input type="name" name="name" className={error.subject ? "error short-input" : "short-input"} onChange={e => handleChange('subject', e.target.value)}  value={toSend.subject} /></div>
                    <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> Message: </label>
                        <input type="name" name="name" className={error.message ? "error short-input" : "short-input"} onChange={e => handleChange('message', e.target.value)}  value={toSend.message} /></div>
                    <div className="button-flex">
                        <div className="button-div">
                <button className='form-button-submit' disabled={loading} type="submit"> Submit </button></div>
                </div>
            </div>
        </form> : <div className="company-page-else"> <h5 className='great-message'> Great! Your message has been sent :) </h5>  
        <Button onClick={() => showElement(false)}><CloseRounded></CloseRounded></Button>
        </div> 
            }
        </div>
    )
}

export default ContactActuality





/* <p className="onboarding-subheading">
          
            All details entered must be true and correct.
          </p>
          
          
          
           <div className="short-input-style">
                        <ImageUploader initialFile={details.image} setFile={setImage}/>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="firstname"> First Name: </label>
                        <input type="name" name="name" className={error.fName ? "error short-input" : "short-input"} onChange={e => handleChange('fName', e.target.value)} value={details.fName} /></div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="lastname"> Last Name: </label>
                        <input type="name" name="name" className={error.lName ? "error short-input" : "short-input"} onChange={e => handleChange('lName', e.target.value)} value={details.lName} /></div>
                    </div>
                    <div className="short-input-style">
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="role"> Role: </label>
                        {/* TODO change select css }
                        <select name="type" value={details.role} onChange={e => handleChange('type', e.target.value)} style={{ display: 'block' }} type="name" className={error.role ? "error short-input" : "short-input"}>
                            <option className='placeholder-text' value="" label="Select" />
                            <option value="owner" label="Owner" />
                            <option value="admin" label="Admin" />
                            <option value="sales" label="Sales" />
                        </select>
                        </div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="email"> Email: </label>
                        <input type="email" name="email" className={error.email ? "error short-input" : "short-input"} onChange={e => handleChange('email', e.target.value)} value={details.email} /></div>
                        <div className="short-input-group">
                        <label className='form-label' htmlFor="contactnumber"> Mobile: </label>
                        <input type="tel" name="number" className={error.phone ? "error short-input" : "short-input"} onChange={e => handleChange('phone', e.target.value)} value={details.phone} /></div>
                    </div> 
                    
                    
                    
                    
                     // variable to ensure state updates only occur while component is mounted
    let componentMounted = true;

    // setup state variables
    const initialValues = (support || { fName: "", email: "", subject: "", message: ""});
    const [sendMsg, setSendMsg] = useState(true); // whether the form is visible or not
    const [details, setDetails] = useState(initialValues); // user entered data
    const [error, setError] = useState({fName: false, email: false, subject: false, message: false}); // record of any invalid data
    const [loading, setLoading] = useState(false); // whether the function is loading

    // conditions each data value must meet to be valid
    const conditions = {
        fName: [notEmpty,],
        email: [notEmpty, emailFormat,],
        subject: [notEmpty,],
        message: [notEmpty],
    }

    // function to save value and check validity whenever form data is changed
    function handleChange(fieldId, value) {
        if (componentMounted) {
            let isValid = validateItem(conditions[fieldId], value); // validate data
            setError({...error, [fieldId]: !isValid}); // update error object
            setDetails({...details, [fieldId]: value}); // update details object
        }
    }

    // function to process and submit data when form is submitted
    function submitHandler(e) {
        e.preventDefault();
        if (componentMounted) {
            setLoading(true);
        }
        // check if form data is valid
        let formValid = checkForm(details, conditions, setError);
        if (formValid) {
            // add new company with given details
            onSendMsg(details).then(() => {
                if(componentMounted) {
                    setLoading(false);
                    setSendMsg(false); // hide form
                }
            });
        } else {
            if(componentMounted) {
                setLoading(false);
            }
        }
    }

    // function to run when component is unmounted
    function unmount() {
        componentMounted = false;
    }
    
    // run cleanup function when component unmounts
    useEffect(() => {
        return () => unmount();
    }, []);*/