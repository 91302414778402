import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  notEmpty,
  emailFormat,
  areTheSame,
  withMinLength,
  validateItem,
  checkForm,
} from "../../../helpers/form_utils";

//Importing Images
import LoginLogo from "../../../images/logo/black.svg";
import background from "../../../images/outdoor.jpeg";

//Importing CSS
import "./LoginForm.css";

function LoginForm({ userLogin, userRegister }) {
  // setup state variables
  const [showRegister, setShowRegister] = useState(window.location.href.includes("#create-account")); // whether the register form is showing
  const [details, setDetails] = useState({
    email: "",
    password: "",
    confPassword: "",
    fName: "",
    lName: "",
  }); // user entered data
  const [error, setError] = useState({
    email: false,
    password: false,
    confPassword: false,
    fName: false,
    lName: false,
  }); // record of any invalid data
  const [loading, setLoading] = useState(false); // whether the fuction is loading

  // conditions each data value must meet to be valid
  const conditions = {
    email: [notEmpty, emailFormat],
    password: [
      notEmpty,
      (value) => {
        return withMinLength(value, 6);
      },
    ],
    confPassword: [
      notEmpty,
      (value) => {
        return areTheSame(value, details.password);
      },
      (value) => {
        return withMinLength(value, 6);
      },
    ],
    fName: [notEmpty],
    lName: [notEmpty],
  };


  // function to save value and check validity whenever form data is changed
  function handleChange(fieldId, value) {
    let isValid = validateItem(conditions[fieldId], value); // validate data
    setError({ ...error, [fieldId]: !isValid }); // update error object
    setDetails({ ...details, [fieldId]: value }); // update details object
  }

  // function to handle the user selecting login
  const loginHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    // check if form data is valid
    let formValid = checkForm(
      details,
      { email: conditions.email, password: conditions.password },
      setError
    );
    console.log("login clicked");
    if (formValid) {
      console.log("logging in...");
      // log in user
      userLogin(details).then((error) => {
        if (error) {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      console.log("form not valid");
    }
  };

  // function to handle the user selecting register
  const registerHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    // check if form data is valid
    let formValid = checkForm(details, conditions, setError);
    if (formValid) {
      console.log("registering user...");
      // register user
      userRegister(details).then((error) => {
        console.log("done");
        if (error) {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  // function to toggle between login form and register form
  function registerToggle(registerHidden) {
    setShowRegister(registerHidden);
    // clear password values
    details.password = "";
    details.confPassword = "";
    error.password = false;
    error.confPassword = false;
  }

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      {showRegister ? (
        <div className="login-form">
          <div className="form-container">
            <img src={LoginLogo} alt="" className="white-full-login-logo" />
            <form className="form-elements" onSubmit={registerHandler}>
              <fieldset disabled={loading}>
                <h1 className="form-title"> Register</h1>
                <div className="form-input-style">
                  <div className="short-input-style">
                    <div className="short-input-group">
                      <label className="form-label" htmlFor="fullname">
                        
                        First Name:
                      </label>
                      <input
                        type="name"
                        name="name"
                        className={
                          error.fName ? "error short-input" : "short-input"
                        }
                        onChange={(e) => handleChange("fName", e.target.value)}
                        value={details.fName}
                      />
                    </div>
                    <div className="short-input-group">
                      <label className="form-label" htmlFor="fullname">
                        
                        Last Name:
                      </label>
                      <input
                        type="name"
                        name="name"
                        className={
                          error.lName
                            ? "error short-input-ln"
                            : "short-input-ln"
                        }
                        onChange={(e) => handleChange("lName", e.target.value)}
                        value={details.lName}
                      />
                    </div>
                  </div>
                  <label className="form-label" htmlFor="password">
                    
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={
                      error.email
                        ? "error login-form-input"
                        : "login-form-input"
                    }
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={details.email}
                  />
                  <label className="form-label" htmlFor="password">
                    
                    Password:
                  </label>
                  <input
                    type="password"
                    name="password"
                    className={
                      error.password
                        ? "error login-form-input"
                        : "login-form-input"
                    }
                    onChange={(e) =>
                      handleChange("password", e.target.value, true)
                    }
                    value={details.password}
                  />
                  <label className="form-label" htmlFor="password">
                    
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    name="password"
                    className={
                      error.confPassword
                        ? "error login-form-input"
                        : "login-form-input"
                    }
                    onChange={(e) =>
                      handleChange("confPassword", e.target.value)
                    }
                    value={details.confPassword}
                  />
                </div>
                <div className="button-group">
                  <Link to={"/login"}>
                    <button
                      className="link-button"
                      type="button"
                      onClick={() => registerToggle(false)}
                    >
                      
                      Already Registered?
                    </button>
                  </Link>
                  <button className="form-button-create-account" type="submit">
                    
                    Create Account
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      ) : (
        <div className="login-form">
          <div className="form-container">
            <img src={LoginLogo} alt="" className="white-full-login-logo" />
            <form className="form-elements" onSubmit={loginHandler}>
              <fieldset disabled={loading}>
                <div className="title-button-flex">
                  {/* TODO either make it so that the #link does something or remove it altogether */}
                  <h1 className="form-title"> Login</h1>
                  <Link
                    className="create-account-button"
                    to={"/login#create-account"}
                  >
                    <button
                      className="link-button"
                      type="button"
                      onClick={() => registerToggle(true)}
                    >
                      
                      Create Account
                    </button>
                  </Link>
                </div>
                <div className="form-input-style">
                  <label className="form-label" htmlFor="password">
                    
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={
                      error.email
                        ? "login-form-input error"
                        : "login-form-input"
                    }
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={details.email}
                  />
                  <label className="form-label" htmlFor="password">
                    
                    Password:
                  </label>
                  <input
                    type="password"
                    name="password"
                    className={
                      error.password
                        ? "error login-form-input"
                        : "login-form-input"
                    }
                    onChange={(e) =>
                      handleChange("password", e.target.value, false)
                    }
                    value={details.password}
                  />
                </div>
                <div className="button-group">
                  <Link to="/forgot-password">
                    <button className="link-button" type="button">
                      
                      Forgot Password?
                    </button>
                  </Link>
                  <button className="form-button" type="submit">
                    
                    Login
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
