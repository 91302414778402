import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import {
  LineStyleRounded,
  StoreRounded,
  ShoppingCartRounded,
  SettingsApplicationsRounded,
  GroupRounded,
  SellRounded,
} from "@mui/icons-material";
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

function UserSidebar(props) {
  const navigate = useNavigate()
  window.addEventListener("resize", () => {
    if (window.innerWidth < 1024) {
      props.setSidebar("sidebar-minimize");
    } else if (window.innerWidth >= 1024) {
      props.setSidebar("");
    }
  });
  function clickHandler (url) {
    if(props.navigate){
      props.navigate(url)
    } else {
      navigate(url)
    }
  }
  return (
    <div className={"sidebar " + props.sidebarClass}>
      <div className="sidebar-wrapper">
        <div className="sidebar-menu">
          <ul className="sidebar-list">
            <div onClick={() => clickHandler("/user/dashboard")} className="links">
              <li className="sidebar-item">
                <LineStyleRounded className="sidebar-icon"></LineStyleRounded>
                Dashboard <div className="sidebar-underline"> </div>
              </li>
            </div>
            <div onClick={() => clickHandler("/user/products")} className="links">
              <li className="sidebar-item">
                <ShoppingCartRounded className="sidebar-icon"></ShoppingCartRounded>
                Products
              </li>
            </div>
            <div onClick={() => clickHandler("/user/leads")} className="links">
              <li className="sidebar-item">
                <SellRounded className="sidebar-icon"></SellRounded>
                Leads
              </li>
            </div>
            <div onClick={() => clickHandler("/user/appsettings")} className="links">
              <li className="sidebar-item">
                <AppSettingsAltIcon className="sidebar-icon"></AppSettingsAltIcon>
                App
              </li>
            </div>
            <div onClick={() => clickHandler("/user/team")} className="links">
              <li className="sidebar-item">
                <GroupRounded className="sidebar-icon"></GroupRounded>
                Team
              </li>
            </div>
            <div onClick={() => clickHandler("/user/settings")} className="links">
              <li className="sidebar-item">
                <SettingsApplicationsRounded className="sidebar-icon"></SettingsApplicationsRounded>
                Settings
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UserSidebar;
