import React from "react";
import {Typography, Button, Stepper, Step, StepLabel} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import './CompanyStepper.css';
import Logo from '../../images/logo/mobile.svg';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepIcon-active": { color: "#01365B", fontSize: "1.55rem", marginTop: "0", },
    "& .MuiStepIcon-completed": { color: "#0C71C38C", fontSize: "1.23rem", marginTop: "0"},
    "& .Mui-disabled .MuiStepIcon-root": { color: "#01365B70", fontSize: "1.23rem", marginTop: "0" },
    backgroundColor: "rgba(12, 113, 195, 0.0)",
  },

  button: {
	  marginTop: '1.23rem',
    marginRight: '1.23rem',
    marginBottom: '1.23rem',
	  width: "8.8rem",
    height: '3.3rem',
    fontSize: '16px',
    borderRadius: '44px',
    backgroundColor: "#01365B",
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#01365BE0',
      color: '#F3FAFF',
  }
  },

  formInput: {
	  marginBottom: '0.55rem' ,
    height: '2.3rem',
    width: '100%',
    borderStyle: 'none' ,
    backgroundColor:' #F3FAFF' ,
    borderRadius: '44px' ,
    fontSize: '14px',
	  paddingLeft: '1.23rem',
  }
}));

const OnboardingComplete = () => {
  const company = useSelector(state => state.company);
  const classes = useStyles();
  const navigate = useNavigate();

  function completeForm() {
    navigate('/');
  }

  const c = useStyles();

  return (
    <div className="product-stepper-container">
      <Stepper className={c.root} alternativeLabel activeStep={3}>
        {["", "", "",].map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
        <Typography variant="h3" align="center">
          <div className="thanks-flex">
          <img src={company.logo || Logo} alt="" className="business-logo"/> 
          Thank You! Your profile has been saved.
          <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={completeForm}
                >
                Enter site
              </Button> </div>
        </Typography>
    </div>
  );
};

export default OnboardingComplete;
