import { AnimatePresence, motion } from "framer-motion"
import './AppSettings.css'
import { useState } from "react";
import { AddCircleOutline, AddOutlined, DeleteForeverRounded, WarningAmber } from "@mui/icons-material";

const FeaturedProductsSearchResults = ({ promptText, results, noInputMessage, noResultsMessage, queryString, confirmActionButton,  onResultPressedHandler, actionButton, excludedIDs = [], editsDisabled = false, requireConfirmation = false, animationsDisabled = false }) => {
    const handlePress = (product, inExclusion = false) => {
        if (editsDisabled || inExclusion) return;
        onResultPressedHandler(product)
    }

    return (
        <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>
            {queryString !== "" && results !== undefined && results.length == 0 && <p className="as-status-message"><WarningAmber /> {noResultsMessage || "No results found"}</p>}
            {queryString === "" && results !== undefined && results.length == 0 && <p className="as-status-message">{noInputMessage || "Enter a query to begin"}</p>}

            {results != undefined && results.length > 0 &&
                <AnimatePresence>
                    {results.map((fp, key) => {
                        const inExclusion = excludedIDs.includes(fp.id)
                        const disableAnimations = inExclusion || editsDisabled || animationsDisabled

                        return <ProductCard
                            disableAnimations={disableAnimations}
                            editsDisabled={editsDisabled}
                            inExclusion={inExclusion}
                            fp={fp}
                            promptText={promptText}
                            handlePress={product => handlePress(product, inExclusion)}
                            key={fp.id}
                            confirmRequired={requireConfirmation}
                            actionButton={actionButton}
                            confirmActionButton={confirmActionButton}
                        />
                    })}
                </AnimatePresence>
            }
        </div>
    )
}

const ProductCard = ({ disableAnimations, editsDisabled, inExclusion, fp, promptText, handlePress, actionButton, confirmActionButton, confirmRequired = false }) => {
    const [inConfirmation, setInConfirmation] = useState(false);

    const animationInitial = {
        x: 300,
        opacity: 0
    }

    const animate = {
        x: 0,
        opacity: 1
    }

    const animationExit = {
        opacity: 0,
        y: 0
    }

    const animationWhileHover = {
        scale: 1.03
    }

    const animationWhiletap = {
        scale: 0.95
    }

    /**
     * Toggle animations based on whether or not they're disabled
     */
    const _whileHover = disableAnimations ? {} : animationWhileHover
    const _whileTap = disableAnimations ? {} : animationWhiletap

    const _handlePress = (product) => {
        if (confirmRequired && !inConfirmation) {
            return setInConfirmation(true)
        } else if (confirmRequired && inConfirmation) {
            setInConfirmation(false);
            handlePress(product)
        } else {
            handlePress(product)
        }
    }

    return (            
        <motion.div className={`as-search-result tooltip ${(editsDisabled || inExclusion) && 'as-edit-disabled'}`} initial={animationInitial} animate={animate} exit={animationExit} whileHover={_whileHover} whileTap={_whileTap}>
            <div style={{ backgroundImage: "url('https://ik.imagekit.io/w07tnkgepgz/go/products/" + fp.id + "/icon/file-0.jpg')"}} className="as-product-image">
            {inExclusion &&
                <span style={{ color: "white", fontSize: '12px', wordWrap: 'break-word', backgroundColor: "#00b74f", padding: '2px 10px', fontWeight: 'bold', borderRadius: '10px' }}>In Featured</span>
            }
            </div>
            <div style={{ paddingLeft: '10px', marginTop: '10px', paddingRight: '15px', paddingBottom: '10px' }}>
                <p style={{ fontSize: '17px', fontWeight: 'bold', color: "#00365A", wordWrap: 'break-word', marginBottom: 0, padding: 0, paddingTop: '5px', lineHeight: '20px', paddingBottom: '8px' }}>{fp.details.productname}</p>
                
                {!inConfirmation && <>
                    {inExclusion ? 
                        <div onClick={() => _handlePress(fp)} className="as-rounded-button">Featured Product</div>
                        :
                        <div onClick={() => _handlePress(fp)} className="as-rounded-button">{actionButton}</div>
                    }</>
                }

                { inConfirmation && <div onClick={() => _handlePress(fp)} className="as-rounded-button" style={{ color: 'red'}}>{confirmActionButton}</div>}
        
            </div>
        </motion.div>
    )
}

export default FeaturedProductsSearchResults;