import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

function ListFiles({ files, setFiles }) {

  function removeFile(index) {
    let tempFiles = files;
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  function displayFile(file) {
    if (file.type.startsWith("image/")) {
      var src = URL.createObjectURL(file);
      return (
        <img src={src} className="image imported-image" alt={"file"}></img>
      );
    } else {
      return file.name;
    }
  }

  // TODO give the user a way to preview files and double check what has been uploaded
  // important for editing products to check what files are already there?
  return (
    <div>
      {files
        ? files.map((file, index) => {
            return (
              <div key={index} className="form-mini-title">
                <a href={file ? URL.createObjectURL(file) : ""} download>
                  {file ? displayFile(file) : <p>Loading...</p>}
                </a>
                <IconButton onClick={() => removeFile(index)}>
                  <Close />
                </IconButton>
              </div>
            );
          })
        : ""}
    </div>
  );
}

export default ListFiles;
