import React, { useRef, useState, useEffect } from "react";
import emailjs from "emailjs-com";
import {
  notEmpty,
  emailFormat,
  numberFormat,
  validateItem,
  checkForm,
} from "../../../helpers/form_utils";
import { CloseRounded } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

export const SupportForm = (showElement, member, onSendMsg) => {
  const form = useRef();

  function refreshPage() {
    window.location.reload(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vn6kphy",
        "template_zyrvus3",
        form.current,
        "uT3LZpj_JypJcoayT"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.reload(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [toSend, setToSend] = useState(true); // whether the form is visible or not
  const [loading, setLoading] = useState(false); // whether the function is loading
  const [error, setError] = useState({
    fName: false,
    email: false,
    subject: false,
    message: false,
  }); // record of any invalid data

  // conditions each data value must meet to be valid
  const conditions = {
    fName: [notEmpty],
    email: [notEmpty, emailFormat],
    subject: [notEmpty],
    userMsg: [notEmpty],
  };

  return (
    <div className="add-company-form-container">
      {toSend ? (
        <form className="form-elements" ref={form} onSubmit={sendEmail}>
          <div className="button-title-flex">
            <h5 className="add-company-form-title"> Need help?</h5>
            <Button type="button" onClick={() => showElement(false)}>
              <CloseRounded></CloseRounded>
            </Button>
          </div>
          <div className="form-input-style">
            <div className="short-input-group">
              <label className="form-label" htmlFor="firstname">
                
                Full Name:
              </label>
              <input
                type="name"
                name="fName"
                className={error.fName ? "error short-input" : "short-input"}
                value={toSend.fName}
              />
            </div>
            <div className="short-input-group">
              <label className="form-label" htmlFor="firstname">
                
                Email:
              </label>
              <input
                type="name"
                name="email"
                className={error.email ? "error short-input" : "short-input"}
                value={toSend.email}
              />
            </div>
            <div className="short-input-group">
              <label className="form-label" htmlFor="firstname">
                
                Subject:
              </label>
              <input
                type="name"
                name="subject"
                className={error.subject ? "error short-input" : "short-input"}
                value={toSend.subject}
              />
            </div>
            <div className="short-input-group">
              <label className="form-label" htmlFor="firstname">
                
                Message:
              </label>
              <textarea
                name="userMsg"
                className={error.userMsg ? "error short-input" : "short-input"}
                value={toSend.userMsg}
              />
            </div>
            <div className="button-flex">
              <div className="button-div">
                <button
                  className="form-button-submit"
                  disabled={loading}
                  type="submit"
                  value="send"
                >
                  
                  Send Email
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="company-page-else">
          
          <h5 className="great-message">
            
            Great! Your message has been sent :)
          </h5>
          <Button onClick={() => showElement(false)}>
            <CloseRounded></CloseRounded>
          </Button>
        </div>
      )}
    </div>
  );
};

// API Send Grid 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
