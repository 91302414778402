import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import {
  DeleteOutlineRounded,
  FilterListRounded,
  VisibilityRounded,
  EditRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import "./UserProductTable.css";
import Image from "../../images/products/tile1.jpg";
import BaseTable from "./BaseTable";
import FirebaseImage from "../media/FirebaseImage";
import { useNavigate } from "react-router-dom";
import TableSearch from "../filters/TableSearch";
import DeleteConfirmModal from "../modals/DeleteConfirmModal";
import { deleteDocument, setDocument } from "../../firebase";
import { useSelector } from "react-redux";
import { deleteFile } from "../../helpers/product_utils";

// TODO update this table to show useful data / remove and put something else on the dashboard
const styleForIconButton = {
  width: "2.3rem",
  height: "2.3rem",
  borderRadius: "2.3rem",
  color: "#01365B",
};

const styleForButton = {
  width: "auto",
  height: "1.88rem",
  color: "#FF3D00",
  backgroundColor: "rgba(255, 61, 0, 0.1)",
  border: "1.23px solid rgba(255, 61, 0, 0.8)",
  borderRadius: "23px",
  fontSize: "12px",
};

const styleForImg = {
  width: "4.23rem",
  height: "4.23rem",
  borderRadius: "5px",
};

function ProductRejectionTable({ products }) {
  const [search, setSearch] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [archived, setArchived] = React.useState(false);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  function editProduct(product) {
    navigate("/user/products/edit/" + product.id);
  }

  const cells = [
    {
      id: "image",
      numeric: false,
      disablePadding: true,
      label: "Image",
      cell: (product) => (
        <TableCell
          key={product.id + "_image"}
          align="center"
          className="table-cell"
        >
          <FirebaseImage
            path={product.media.images[0]}
            styleForImg={styleForImg}
            defaultImage={Image}
          />
        </TableCell>
      ),
    },

    {
      id: "details.productname",
      numeric: false,
      disablePadding: true,
      label: "Product",
      cell: (product) => (
        <TableCell
          key={product.id + "_name"}
          align="center"
          className="table-cell"
        >
          {product.details.productname}
        </TableCell>
      ),
    },
    {
      id: "edit",
      numeric: true,
      disablePadding: false,
      label: "Edit",
      cell: (product) => (
        <TableCell
          key={product.id + "_edit"}
          align="center"
          className="table-cell"
        >
          <IconButton
            style={styleForIconButton}
            onClick={() => editProduct(product)}
          >
            <EditRounded />
          </IconButton>
        </TableCell>
      ),
    },
    {
      id: "fix",
      numeric: true,
      disablePadding: false,
      label: "Fix",
      cell: (product) => (
        <TableCell
          key={product.id + "_fix"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButton}>{product.fix || "Unknown"}</Button>
        </TableCell>
      ),
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      cell: (product) => (
        <TableCell
          key={product.id + "_status"}
          align="center"
          className="table-cell"
        >
          <Button style={styleForButton}>{product.status || "Unknown"}</Button>
        </TableCell>
      ),
    },
  ];

  async function deleteProducts() {
    if(["actuality_admin", "company_admin"].includes(user.role)){
      for (let index = 0; index < selectedProducts.length; index++) {
        const product = selectedProducts[index];
        if (archived) {
          let productDoc = products.find((prod) => prod.id == product);
          await deleteDocument("/Products/" + product).then(async (result) => {
            productDoc.icon.icon
            .concat(
              [
                ...productDoc.media.images,
                productDoc.media.video,
                productDoc.documents.manual,
                productDoc.documents.installation,
                productDoc.documents.returns,
                productDoc.documents.warranty,
              ],
              productDoc.tile_variation && [
                ...productDoc.tile_variation.images,
              ],
              productDoc.paver_variation && [
                ...productDoc.paver_variation.images,
              ]
            )
              .map(async (file) => {
                await deleteFile(file).then((result2) => {
                  console.log(result, result2);
                });
              });
          });
        } else {
          let productDoc = products.find((prod) => prod.id == product);
          productDoc.archived = true;
          await setDocument("/Products/" + product, productDoc).then((result) => {
            console.log(result);
          });
        }
      }
      setShowModal(false);
      setSelectedProducts([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  async function unarchiveFunc(productList) {
    if(["actuality_admin", "company_admin"].includes(user.role)){
      for (let index = 0; index < productList.length; index++) {
        const product = productList[index];
        let productDoc = products.find((prod) => prod.id == product);
        productDoc.archived = false;
        await setDocument("/Products/" + product, productDoc).then((result) => {
          console.log(result);
        });
      }
      setSelectedProducts([])
    } else {
      window.alert("Only Admins can delete or archive items")
    }
  }

  return (
    <div className="table-restrict">
      {/* TABLE DISPLAYS ARCHIVED PRODUCTS */}
      <div style={{ display: !archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Archived Pending Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          rows={products.filter((row) => {
            if (
              row.details.productname &&
              row.details.productname
                .toLowerCase()
                .includes(search.toLowerCase()) &&
              row.archived
            ) {
              return true;
            }

            if (
              row.inventory && row.inventory.sku &&
              row.inventory.sku.toLowerCase().includes(search.toLowerCase()) &&
              row.archived
            ) {
              return true;
            }

            return false;
          })}
          columns={cells}
          defaultOrder={"company"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          unarchiveFunc={(value) => unarchiveFunc(value)}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      {/* TABLE DISPLAYS NON-ARCHIVED PRODUCTS */}
      <div style={{ display: archived ? "none" : "block" }}>
        <BaseTable
          title={
            <TableSearch
              title={"Pending Products"}
              search={search}
              setSearch={setSearch}
              smallText={"Search Products"}
            />
          }
          rows={products.filter((row) => {
            if (
              row.details.productname &&
              row.details.productname
                .toLowerCase()
                .includes(search.toLowerCase()) &&
              !row.archived
            ) {
              return true;
            }

            if (
              row.inventory && row.inventory.sku &&
              row.inventory.sku.toLowerCase().includes(search.toLowerCase()) &&
              !row.archived
            ) {
              return true;
            }

            return false;
          })}
          columns={cells}
          defaultOrder={"company"}
          deleteFunc={(value) => {
            setShowModal(true);
            setSelectedProducts(value);
          }}
          archived={archived}
          selectedItems={selectedProducts}
        ></BaseTable>
      </div>
      <DeleteConfirmModal
        closeCallback={(value) => setShowModal(value)}
        display={showModal}
        title={archived ? "Delete Product" : "Archive Product"}
        message={
          archived
            ? "Are you sure you would like to delete this product?"
            : "Are you sure you would like to archive this product?"
        }
        confirm={deleteProducts}
      />
      <div>
        <a
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => setArchived(!archived)}
        >
          {archived ? "Hide Archived Products" : "Show Archived Products"}
        </a>
      </div>
    </div>
  );
}

export default ProductRejectionTable;
