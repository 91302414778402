import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./styles/ProductFormStyles.css";
import {
  notEmpty,
  formikSubmit,
  formikValidate,
} from "../../../helpers/form_utils";

function InventoryProductForm({ submitting, setStatus, initialValues }) {
  const component_key = "inventory";

  const conditions = {
    sku: [notEmpty],
  };

  useEffect(() => {
    if (submitting) {
      formik.handleSubmit();
    }
  }, [submitting]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      sku: "",
      quantity: 0,
      incoming: 0,
    },

    validate: (values) => {
      return formikValidate(
        values,
        submitting,
        component_key,
        setStatus,
        conditions,
        formik
      );
    },

    onSubmit: (values) => {
      formikSubmit(values, formik, setStatus, component_key, conditions);
    },
  });

  useEffect(() => {
    if (initialValues) {
      formik.setFieldValue("sku", initialValues.sku);
      formik.setFieldValue("quantity", initialValues.quantity);
      formik.setFieldValue("incoming", initialValues.incoming);
    }
  }, [initialValues]);

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <div className="company-onboarding-container">
      <form onSubmit={handleSubmit}>
        <div className="form-block">
          <div className="form-items">
            <h6 className="section-heading"> Inventory </h6>
            <p className="onboarding-subheading"></p>

            <div className="select-title-flex">
              <span className="form-mini-title">SKU *</span>
              <input
                placeholder=""
                type="name"
                name="sku"
                className={
                  formik.errors.sku
                    ? "error form-element-style"
                    : "form-element-style"
                }
                onChange={formik.handleChange}
                value={formik.values.sku}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="form-elements-flex">
              <div className="left-elements">
                <div className="select-title-flex">
                  <span className="form-mini-title">Quantity Available</span>
                  <input
                    placeholder="0"
                    type="number"
                    name="quantity"
                    className="form-element-style-margin"
                    onChange={formik.handleChange}
                    value={formik.values.quantity}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="right-elements">
                <span className="form-mini-title">Incoming</span>
                <input
                  placeholder="0"
                  type="number"
                  name="incoming"
                  className="form-element-style"
                  onChange={formik.handleChange}
                  value={formik.values.incoming}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InventoryProductForm;
